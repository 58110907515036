.e-icon-collapsible::before {
  content: "\e748";
}

.e-icon-back::before {
  content: "\e773";
  font-size: 12px;
}

/* stylelint-disable property-no-vendor-prefix */
.e-listview {
  -webkit-overflow-scrolling: touch;
  border: 1px solid;
  border-radius: 4px;
  display: block;
  overflow: auto;
  position: relative;
  width: 100%;
}
.e-listview:not(.e-list-template) .e-list-item {
  height: 36px;
  line-height: 18px;
  position: relative;
  padding: 7px 9px 7px 12px;
}
.e-listview .e-list-item {
  border-bottom: 1px solid;
  cursor: pointer;
}
.e-listview .e-list-item.e-disabled, .e-listview .e-list-item.e-disabled * {
  cursor: default;
}

.e-listview .e-list-parent {
  margin: 0;
  padding: 0;
}

.e-listview .e-icon-back {
  margin-top: 0;
}

.e-listview .e-list-header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 36px;
  border-bottom: 1px solid;
  padding: 0 12px;
}
.e-listview .e-list-header .e-text.header,
.e-listview .e-list-header .e-headertemplate-text.nested-header {
  display: none;
}
.e-listview .e-list-header.e-list-navigation .e-text {
  cursor: pointer;
}
.e-listview .e-list-header .e-text {
  cursor: default;
  text-indent: 0;
}

.e-listview .e-has-header > .e-view {
  top: 45px;
}

.e-listview .e-back-button {
  cursor: pointer;
  padding-right: 8px;
}

.e-listview .e-list-group-item {
  border-bottom: 1px solid #dee2e6;
  border-top: 0 solid;
  height: 36px;
  line-height: 20px;
  font-weight: 600;
  padding: 7px 9px 7px 12px;
}
.e-listview .e-list-group-item .e-list-text {
  cursor: default;
}
.e-listview .e-list-group-item:first-child {
  border: 0;
  border-bottom: 1px solid #dee2e6;
}

.e-listview .e-icon-collapsible {
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  right: 0%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.e-listview .e-text-content {
  height: 100%;
  position: relative;
  vertical-align: middle;
}
.e-listview .e-text-content.e-checkbox .e-list-text {
  width: calc(100% - 40px);
}
.e-listview .e-text-content.e-checkbox.e-checkbox-left .e-list-icon + .e-list-text {
  width: calc(100% - 90px);
}
.e-listview .e-text-content.e-checkbox.e-checkbox-right .e-list-icon + .e-list-text {
  width: calc(100% - 80px);
}

.e-listview .e-text-content * {
  display: inline-block;
  vertical-align: middle;
}

.e-listview .e-list-item.e-checklist.e-has-child .e-text-content.e-checkbox.e-checkbox-right .e-list-icon + .e-list-text {
  width: calc(100% - 92px);
}

.e-listview .e-checkbox .e-checkbox-left {
  margin: 0 8px 0 0;
}
.e-listview .e-checkbox .e-checkbox-right {
  margin: -2px 0 0 8px;
}

.e-listview .e-list-text {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.e-listview .e-list-icon + .e-list-text, .e-listview .e-icon-wrapper .e-list-text, .e-listview .e-icon-wrapper.e-text-content.e-checkbox .e-list-text {
  width: calc(100% - 60px);
}

.e-listview .e-list-icon {
  margin-right: 8px;
  height: 30px;
  width: 30px;
}

.e-listview.e-virtualization .e-list-container {
  overflow: unset;
}

.e-listview .e-list-container {
  overflow: hidden;
  position: relative;
}

.e-listview .e-text .e-headertext {
  display: inline-block;
  line-height: 18px;
}

.e-listview.e-rtl {
  direction: rtl;
}
.e-listview.e-rtl .e-list-icon {
  margin-left: 16px;
  margin-right: 0;
}
.e-listview.e-rtl .e-icon-collapsible {
  left: 0%;
  right: initial;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
.e-listview.e-rtl .e-list-header .e-text {
  cursor: pointer;
}
.e-listview.e-rtl .e-back-button {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.e-listview.e-rtl .e-icon-back {
  margin-top: 0;
}
.e-listview.e-rtl .e-checkbox .e-checkbox-left {
  margin: -2px 0 0 8px;
}
.e-listview.e-rtl .e-checkbox .e-checkbox-right {
  margin: -2px 8px 0 0;
}

.e-listview.e-disabled, .e-listview.e-disabled * {
  cursor: default;
}

.e-listview.e-disabled .e-checkbox .e-icons {
  cursor: default;
}

.e-listview {
  border-color: #dee2e6;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
}
.e-listview .e-list-header {
  background-color: transparent;
  color: #6c757d;
  border-color: #dee2e6;
  font-size: 12px;
}

.e-listview .e-icons:not(.e-primary .e-btn-icon, .e-check) {
  color: #6c757d;
}
.e-listview .e-icons:not(.e-primary .e-btn-icon, .e-check).e-back-button:hover {
  color: #343a40;
}
.e-listview .e-icons:not(.e-primary .e-btn-icon, .e-check).e-back-button:active {
  color: #212529;
}

.e-listview .e-list-item {
  border-bottom: 1px solid #dee2e6;
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  border-top: 0 solid transparent;
  background-color: transparent;
  color: #212529;
}
.e-listview .e-list-item.e-hover, .e-listview .e-list-item.e-hover.e-active.e-checklist {
  background-color: #e9ecef;
  color: #212529;
  border-color: #dee2e6;
}
.e-listview .e-list-item.e-active {
  background-color: #0d6efd;
  color: #fff;
}
.e-listview .e-list-item.e-active.e-checklist {
  background-color: transparent;
  color: #212529;
}
.e-listview .e-list-item.e-focused, .e-listview .e-list-item.e-focused.e-active.e-checklist {
  background-color: #0d6efd;
  color: #fff;
}
.e-listview .e-list-item.e-focused .e-checkbox-wrapper .e-frame.e-check, .e-listview .e-list-item.e-focused .e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: #fff;
  color: #0d6efd;
  border-color: #fff;
}

.e-listview .e-list-group-item {
  background-color: transparent;
  color: #6c757d;
  border-color: #dee2e6;
  font-size: 12px;
}

.e-listview .e-list-item.e-focused .e-icon-wrapper .e-icon-collapsible {
  color: #fff;
}

.e-listview.e-list-template .e-list-wrapper {
  height: inherit;
  position: relative;
}
.e-listview.e-list-template .e-list-wrapper:not(.e-list-multi-line) {
  padding: 10px 16px;
}
.e-listview.e-list-template .e-list-wrapper:not(.e-list-multi-line).e-list-badge:not(.e-list-avatar) {
  padding: 0 8px 0 12px;
}
.e-listview.e-list-template .e-list-wrapper.e-list-multi-line {
  padding: 4px;
}
.e-listview.e-list-template .e-list-wrapper.e-list-multi-line .e-list-item-header {
  color: #212529;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-listview.e-list-template .e-list-wrapper.e-list-multi-line .e-list-content {
  color: #6c757d;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  padding: 2px 0 0 0;
  word-wrap: break-word;
}
.e-listview.e-list-template .e-list-wrapper.e-list-multi-line .e-list-content:not(.e-text-overflow) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-listview.e-list-template .e-list-wrapper.e-list-avatar .e-avatar {
  height: 40px;
  width: 40px;
  top: 0;
  left: 0;
  position: absolute;
  margin: 7px 16px 7px 12px;
}
.e-listview.e-list-template .e-list-wrapper.e-list-avatar:not(.e-list-badge) {
  padding-left: 68px;
  padding-right: 16px;
}
.e-listview.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) {
  padding-left: 16px;
  padding-right: 68px;
}
.e-listview.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) .e-avatar {
  height: 40px;
  width: 40px;
  top: 0;
  right: 0;
  position: absolute;
}
.e-listview.e-list-template .e-list-wrapper.e-list-multi-line.e-list-avatar .e-avatar {
  top: 0;
}
.e-listview.e-list-template .e-list-wrapper.e-list-multi-line.e-list-avatar-right:not(.e-list-badge) .e-avatar {
  top: 0;
}
.e-listview.e-list-template .e-list-wrapper.e-list-badge .e-badge {
  font-size: 10px;
  height: 18px;
  width: 20px;
  top: 50%;
  right: 12px;
  line-height: 16px;
  padding: 0;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 10px;
}
.e-listview.e-list-template .e-list-wrapper.e-list-badge.e-list-avatar {
  padding-left: 68px;
  padding-right: 10px;
}
.e-listview.e-list-template .e-list-wrapper.e-list-badge:not(.e-list-avatar) {
  padding-left: 16px;
  padding-right: 10px;
}
.e-listview.e-list-template .e-list-wrapper:not(.e-list-multi-line) .e-list-content {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 8px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 18px;
}
.e-listview.e-list-template .e-list-item.e-focused .e-list-wrapper.e-list-multi-line .e-list-item-header,
.e-listview.e-list-template .e-list-item.e-focused .e-list-wrapper.e-list-multi-line .e-list-content {
  color: #fff;
}
.e-listview.e-list-template .e-list-item.e-hover .e-list-item-header, .e-listview.e-list-template .e-list-item.e-hover .e-list-content {
  color: #212529;
}
.e-listview.e-list-template .e-list-item.e-active .e-list-item-header, .e-listview.e-list-template .e-list-item.e-active .e-list-content {
  color: #fff;
}
.e-listview.e-rtl.e-list-template .e-list-wrapper.e-list-avatar .e-avatar {
  left: inherit;
  margin: 7px 12px 7px 16px;
  right: 0;
}
.e-listview.e-rtl.e-list-template .e-list-wrapper.e-list-avatar:not(.e-list-badge) {
  padding-left: 16px;
  padding-right: 68px;
}
.e-listview.e-rtl.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) {
  padding-left: 68px;
  padding-right: 16px;
}
.e-listview.e-rtl.e-list-template .e-list-wrapper.e-list-avatar-right:not(.e-list-badge) .e-avatar {
  left: 0;
  right: inherit;
}
.e-listview.e-rtl.e-list-template .e-list-wrapper.e-list-badge .e-badge {
  left: 12px;
  right: inherit;
}
.e-listview.e-rtl.e-list-template .e-list-wrapper.e-list-badge.e-list-avatar {
  padding-left: 10px;
  padding-right: 68px;
}
.e-listview.e-rtl.e-list-template .e-list-wrapper.e-list-badge:not(.e-list-avatar) {
  padding-left: 10px;
  padding-right: 16px;
}

.e-bigger .e-listview .e-list-item,
.e-listview.e-bigger .e-list-item {
  border-bottom: 1px solid #dee2e6;
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  border-top: 0 solid transparent;
}
.e-bigger .e-listview:not(.e-list-template) .e-list-item,
.e-listview.e-bigger:not(.e-list-template) .e-list-item {
  height: 40px;
  line-height: 24px;
  position: relative;
}
.e-bigger .e-listview .e-text-content,
.e-listview.e-bigger .e-text-content {
  font-size: 16px;
}
.e-bigger .e-listview .e-list-group-item,
.e-listview.e-bigger .e-list-group-item {
  height: 40px;
  line-height: 20px;
}
.e-bigger .e-listview .e-list-header,
.e-listview.e-bigger .e-list-header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 40px;
}
.e-bigger .e-listview .e-list-header .e-text.header,
.e-bigger .e-listview .e-list-header .e-headertemplate-text.nested-header,
.e-listview.e-bigger .e-list-header .e-text.header,
.e-listview.e-bigger .e-list-header .e-headertemplate-text.nested-header {
  display: none;
}
.e-bigger .e-listview .e-list-header .e-text,
.e-listview.e-bigger .e-list-header .e-text {
  font-size: 14px;
}
.e-bigger .e-listview .e-text-content.e-checkbox .e-list-text,
.e-listview.e-bigger .e-text-content.e-checkbox .e-list-text {
  width: calc(100% - 40px);
}

/* stylelint-disable-line no-empty-source */
.e-sortable {
  outline: none;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.e-sortable .e-disabled {
  background-image: none;
  cursor: default;
  opacity: 0.35;
}
.e-sortable * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.e-sortable *:focus {
  outline: none;
}

/* stylelint-disable */
.e-sortableclone {
  position: fixed !important;
}

/* stylelint-enable */