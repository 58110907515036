/* !componenticons */
.e-pager .e-icon-last::before,
.e-grid-menu .e-icon-last::before {
  content: "\e7a9";
}
.e-pager .e-icon-first::before,
.e-grid-menu .e-icon-first::before {
  content: "\e744";
}
.e-pager .e-icon-prev::before,
.e-grid-menu .e-icon-prev::before {
  content: "\e765";
}
.e-pager .e-icon-next::before,
.e-grid-menu .e-icon-next::before {
  content: "\e748";
}
.e-pager.e-rtl .e-icon-last.e-lastpage::before, .e-pager.e-rtl .e-icon-last.e-lastpage:hover, .e-pager.e-rtl .e-icon-last.e-lastpagedisabled::before,
.e-grid-menu.e-rtl .e-icon-last.e-lastpage::before,
.e-grid-menu.e-rtl .e-icon-last.e-lastpage:hover,
.e-grid-menu.e-rtl .e-icon-last.e-lastpagedisabled::before {
  content: "\e744";
}
.e-pager.e-rtl .e-icon-first.e-firstpage::before, .e-pager.e-rtl .e-icon-first.e-firstpage:hover, .e-pager.e-rtl .e-icon-first.e-firstpagedisabled::before,
.e-grid-menu.e-rtl .e-icon-first.e-firstpage::before,
.e-grid-menu.e-rtl .e-icon-first.e-firstpage:hover,
.e-grid-menu.e-rtl .e-icon-first.e-firstpagedisabled::before {
  content: "\e7a9";
}
.e-pager.e-rtl .e-icon-prev.e-prevpage::before, .e-pager.e-rtl .e-icon-prev.e-prevpage:hover, .e-pager.e-rtl .e-icon-prev.e-prevpagedisabled::before,
.e-grid-menu.e-rtl .e-icon-prev.e-prevpage::before,
.e-grid-menu.e-rtl .e-icon-prev.e-prevpage:hover,
.e-grid-menu.e-rtl .e-icon-prev.e-prevpagedisabled::before {
  content: "\e748";
}
.e-pager.e-rtl .e-icon-next.e-nextpage::before, .e-pager.e-rtl .e-icon-next.e-nextpage:hover, .e-pager.e-rtl .e-icon-next.e-nextpagedisabled::before,
.e-grid-menu.e-rtl .e-icon-next.e-nextpage::before,
.e-grid-menu.e-rtl .e-icon-next.e-nextpage:hover,
.e-grid-menu.e-rtl .e-icon-next.e-nextpagedisabled::before {
  content: "\e765";
}

/*! Pager layout */
.e-pager {
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  font-size: 14px;
  padding: 7px 8px;
  white-space: normal;
  width: 100%;
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-pager [class^=e-] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.e-pager .e-pager-default.e-hide,
.e-pager .e-pagesizes.e-hide,
.e-pager .e-hide {
  display: none;
}
.e-pager div.e-parentmsgbar {
  float: right;
  padding-bottom: 0;
  padding-right: 0;
  padding-top: 8px;
}
.e-pager .e-pagesizes {
  display: inline;
}
.e-pager .e-pagerdropdown {
  display: inline-block;
  height: 32px;
  margin-left: 10px;
  margin-right: 8px;
  margin-top: -24px;
  overflow: unset;
  vertical-align: middle;
  width: 90px;
}
.e-pager .e-pagerconstant {
  display: inline-block;
  margin: 0 0 6px 8px;
  overflow: hidden;
  width: auto;
}
.e-pager .e-icons {
  font-size: 9px;
}
.e-pager .e-numericitem {
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-block;
  line-height: 1;
  margin-right: 0;
  min-width: 26px;
  padding: 13px 12px 10px;
  text-align: center;
}
.e-pager .e-numericitem.e-currentitem, .e-pager .e-numericitem.e-currentitem:hover {
  padding: 7.4px 10.5px 8.4px 9.5px;
}
.e-pager div.e-pagermsgdiv {
  padding-right: 6px;
  padding-top: 4px;
  text-align: right;
}
.e-pager .e-firstpagedisabled,
.e-pager .e-prevpagedisabled,
.e-pager .e-nextpagedisabled,
.e-pager .e-lastpagedisabled {
  opacity: 1;
}
.e-pager .e-spacing,
.e-pager .e-numericitem:hover,
.e-pager .e-currentitem {
  border-radius: 0;
  cursor: pointer;
  padding: 10px 12.5px 11.5px;
  text-decoration: none;
}
.e-pager .e-currentitem {
  border: 3px solid #86b7fe;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
}
.e-pager div,
.e-pager a {
  display: inline;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.e-pager .e-icons::before {
  display: inline-block;
}
.e-pager .e-pagercontainer .e-firstpage,
.e-pager .e-pagercontainer .e-prevpage,
.e-pager .e-pagercontainer .e-firstpagedisabled,
.e-pager .e-pagercontainer .e-prevpagedisabled,
.e-pager .e-pagercontainer .e-nextpage,
.e-pager .e-pagercontainer .e-lastpage,
.e-pager .e-pagercontainer .e-nextpagedisabled,
.e-pager .e-pagercontainer .e-lastpagedisabled {
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-block;
  margin-right: 0;
  margin-top: 0;
  min-width: 26px;
  padding: 14.2px 12.2px 12.7px 11.5px;
}
.e-pager .e-pagercontainer .e-lastpage,
.e-pager .e-pagercontainer .e-lastpagedisabled {
  border-right: 1px;
}
.e-pager .e-firstpage:hover,
.e-pager .e-prevpage:hover,
.e-pager .e-lastpage:hover,
.e-pager .e-nextpage:hover {
  cursor: pointer;
  text-decoration: none;
}
.e-pager a.e-nextprevitemdisabled {
  display: none;
  margin-left: 1px;
  margin-right: 1px;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
}
.e-pager .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
.e-pager .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
  margin-left: 0;
}
.e-pager .e-pagercontainer {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  margin: 0 0 -4px 0.3px;
  overflow: hidden;
}
.e-pager .e-lastpage:hover {
  border-radius: 0 3px 3px 0;
}
.e-pager .e-firstpage:hover {
  border-radius: 3px 0 0 3px;
}
.e-pager .e-pagermessage,
.e-pager .e-pagerexternalmsg {
  display: block;
  margin: 6px 6px 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-pager .e-mfirst,
.e-pager .e-mprev,
.e-pager .e-mnext,
.e-pager .e-mlast {
  display: none;
}
.e-pager .e-mprev {
  text-indent: -3px;
}
.e-pager .e-mnext {
  text-indent: -2px;
}
.e-pager .e-mfirst,
.e-pager .e-mprev,
.e-pager .e-mnext,
.e-pager .e-mlast {
  -webkit-tap-highlight-color: #fff;
}
.e-pager .e-pp,
.e-pager .e-np,
.e-pager .e-pp:hover,
.e-pager .e-np:hover {
  font-size: 14px;
  padding: 10.4px 10.5px 11.4px;
  font-weight: bold;
  letter-spacing: 0;
}
.e-pager .e-np.e-focused,
.e-pager .e-pp.e-focused {
  line-height: 1;
  padding: 10.4px 10.5px 11.4px;
}
.e-pager.e-adaptive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 8px;
}
.e-pager.e-adaptive div.e-parentmsgbar {
  margin: 0 5px;
  margin-top: -6px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 10px;
  white-space: nowrap;
}
.e-pager.e-adaptive .e-pagesizes {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
}
.e-pager.e-adaptive .e-pagerdropdown {
  margin: 2px 2px 0 4px;
  width: 77px;
  min-width: 77px;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  height: 40px;
  padding-right: 5px;
}
.e-pager.e-adaptive .e-pagerconstant {
  font-size: 12px;
  position: relative;
  top: 6px;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  min-width: 52px;
  max-height: 34px;
  margin: 0 8px 0 10px;
}
.e-pager.e-adaptive .e-pagerconstant.e-page-all {
  top: 11px;
  text-align: center;
}
.e-pager.e-adaptive .e-mfirst,
.e-pager.e-adaptive .e-mprev,
.e-pager.e-adaptive .e-mnext,
.e-pager.e-adaptive .e-mlast {
  padding: 1% 2%;
  width: 5%;
}
.e-pager.e-adaptive .e-mfirst,
.e-pager.e-adaptive .e-mlast {
  width: calc(5% + 11px);
}
@media (max-width: 769px) {
  .e-pager {
    padding: 13px 0;
  }
  .e-pager div.e-parentmsgbar {
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: inline-block;
    float: initial;
    text-align: center;
    width: calc(60% - 48px);
  }
  .e-pager .e-pagesizes,
  .e-pager .e-pagecountmsg,
  .e-pager .e-pagercontainer {
    display: none;
  }
  .e-pager .e-icons {
    font-size: 11px;
  }
  .e-pager .e-mfirst,
  .e-pager .e-mprev,
  .e-pager .e-mnext,
  .e-pager .e-mlast {
    border: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: inline-block;
    padding: 1% 5%;
  }
  .e-pager .e-mfirst {
    margin-right: 4px;
    text-align: right;
    width: calc(10% + 11px);
  }
  .e-pager .e-mprev {
    margin: 0 4px;
    text-align: right;
    width: 10%;
  }
  .e-pager .e-mnext {
    margin: 0 4px;
    text-align: left;
    width: 10%;
  }
  .e-pager .e-mlast {
    margin-left: 4px;
    text-align: left;
    width: calc(10% + 11px);
  }
}
.e-pager.e-rtl {
  direction: rtl;
}
.e-pager.e-rtl .e-numericitem.e-currentitem, .e-pager.e-rtl .e-numericitem.e-currentitem:hover {
  padding: 7px 10.5px 8px 9.5px;
}
.e-pager.e-rtl .e-numericitem.e-currentitem {
  border-left-color: #86b7fe;
  border-left-style: solid;
  border-left-width: 3px;
}
.e-pager.e-rtl .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
.e-pager.e-rtl .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
  margin-left: 0;
  margin-right: 0;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
}
.e-pager.e-rtl .e-pagercontainer, .e-pager.e-rtl div, .e-pager.e-rtl a {
  float: initial;
}
.e-pager.e-rtl .e-parentmsgbar {
  float: left;
  margin-left: 6px;
  padding-top: 8px;
}
.e-pager.e-rtl .e-pagerdropdown {
  margin: -24px 16px 0 0;
}
.e-pager.e-rtl .e-pagerconstant {
  margin: 0 8px 6px 0;
}
.e-pager.e-rtl .e-pagerexternalmsg {
  float: none;
}
.e-pager.e-rtl .e-pagercontainer {
  float: none;
  margin: 0 0.3px -4px 0;
  padding: 0;
}
.e-pager.e-rtl .e-firstpage, .e-pager.e-rtl .e-prevpage, .e-pager.e-rtl .e-firstpagedisabled, .e-pager.e-rtl .e-prevpagedisabled, .e-pager.e-rtl .e-nextpage, .e-pager.e-rtl .e-lastpage, .e-pager.e-rtl .e-nextpagedisabled, .e-pager.e-rtl .e-lastpagedisabled {
  padding: 14px 12px 12px 11.5px;
}
.e-pager.e-rtl .e-firstpage::before, .e-pager.e-rtl .e-firstpage:hover, .e-pager.e-rtl .e-firstpagedisabled::before {
  content: "\e701";
}
.e-pager.e-rtl .e-prevpage::before, .e-pager.e-rtl .e-prevpage:hover, .e-pager.e-rtl .e-prevpagedisabled::before {
  content: "\e848";
}
.e-pager.e-rtl .e-nextpage::before, .e-pager.e-rtl .e-nextpage:hover, .e-pager.e-rtl .e-nextpagedisabled::before {
  content: "\e84b";
}
.e-pager.e-rtl .e-lastpage::before, .e-pager.e-rtl .e-lastpage:hover, .e-pager.e-rtl .e-lastpagedisabled::before {
  content: "\e716";
}
.e-pager.e-rtl .e-nextpage, .e-pager.e-rtl .e-nextpagedisabled, .e-pager.e-rtl .e-prevpage, .e-pager.e-rtl .e-prevpagedisabled, .e-pager.e-rtl .e-firstpage, .e-pager.e-rtl .e-firstpagedisabled {
  border-left-style: none;
  border-left-width: 0;
}
.e-pager.e-rtl .e-nextpage, .e-pager.e-rtl .e-nextpagedisabled, .e-pager.e-rtl .e-prevpage, .e-pager.e-rtl .e-prevpagedisabled {
  border-left-style: none;
  border-left-width: 0;
}
.e-pager.e-rtl .e-firstpage, .e-pager.e-rtl .e-firstpagedisabled {
  border-right: medium none;
}
.e-pager.e-rtl .e-firstpage:hover {
  border-radius: 0 4px 4px 0;
}
.e-pager.e-rtl .e-lastpage:hover {
  border-radius: 0 4px 4px 0;
}
.e-pager.e-rtl .e-numericcontainer {
  float: none;
}
.e-pager.e-rtl .e-numericitem {
  border-right-style: solid;
  min-width: 26px;
}
.e-pager.e-rtl .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable, .e-pager.e-rtl .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
  margin-left: 0;
  margin-right: 0;
}
.e-pager.e-rtl .e-spacing, .e-pager.e-rtl .e-numericitem:hover, .e-pager.e-rtl .e-currentitem {
  margin: 0;
  padding: 10.4px 12.5px 11px;
}
.e-pager.e-rtl .e-pp, .e-pager.e-rtl .e-np, .e-pager.e-rtl .e-pp:hover, .e-pager.e-rtl .e-np:hover {
  margin-top: 0;
  padding: 10px 10.5px 11px;
}
.e-pager.e-rtl.e-adaptive.e-rtl div.e-parentmsgbar {
  margin: 0 5px;
  margin-top: -4px;
  padding-top: 0;
}
.e-pager.e-rtl.e-adaptive.e-rtl div.e-pagerconstant {
  margin: 0 10px 0 8px;
}
.e-pager.e-rtl.e-adaptive.e-rtl div.e-pagerconstant.e-page-all {
  top: 14px;
}
.e-pager.e-rtl.e-adaptive.e-rtl div.e-pagerdropdown {
  min-width: 92px;
  margin: 5px 4px 0 2px;
  padding: 0 0 0 5px;
}
.e-pager.e-rtl.e-adaptive.e-rtl .e-mfirst,
.e-pager.e-rtl.e-adaptive.e-rtl .e-mprev,
.e-pager.e-rtl.e-adaptive.e-rtl .e-mnext,
.e-pager.e-rtl.e-adaptive.e-rtl .e-mlast {
  padding: 1% 2%;
  width: 5%;
}
.e-pager.e-rtl.e-adaptive.e-rtl .e-mfirst,
.e-pager.e-rtl.e-adaptive.e-rtl .e-mlast {
  width: calc(5% + 11px);
}
@media (max-width: 769px) {
  .e-pager.e-rtl .e-mfirst,
  .e-pager.e-rtl .e-mprev,
  .e-pager.e-rtl .e-mnext,
  .e-pager.e-rtl .e-mlast {
    border: 0;
  }
  .e-pager.e-rtl.e-rtl div.e-parentmsgbar {
    float: initial;
    margin-left: 0;
    margin-top: 10px;
  }
  .e-pager.e-rtl .e-parentmsgbar {
    float: right;
  }
}
.e-pager.sf-pager .e-pagercontainer {
  font-size: 0;
}
.e-pager.sf-pager .e-pagercontainer .e-first,
.e-pager.sf-pager .e-pagercontainer .e-prev,
.e-pager.sf-pager .e-pagercontainer .e-next,
.e-pager.sf-pager .e-pagercontainer .e-last {
  font-size: 9px;
}
.e-pager.sf-pager .e-numericcontainer a {
  font-size: 14px;
}
.e-pager.sf-pager .e-link,
.e-pager.sf-pager .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
  margin-left: 0;
}

/*! Pager theme */
.e-pager {
  border-color: #dee2e6;
  background: #fff;
  color: #495057;
  opacity: 1;
}
.e-pager div.e-icons.e-pager-default {
  color: #0d6efd;
}
.e-pager div.e-icons.e-disable {
  color: #adb5bd;
}
.e-pager .e-pager-default:hover {
  background: #e9ecef;
  color: #0d6efd;
  opacity: 1;
}
.e-pager .e-parentmsgbar {
  font-weight: 400;
}
.e-pager .e-spacing {
  color: #0d6efd;
  opacity: 1;
}
.e-pager .e-spacing.e-numericitem:not(.e-active):not(.e-np):not(.e-pp):hover {
  background: #e9ecef;
}
.e-pager .e-numericitem:hover {
  color: #0d6efd;
  opacity: 1;
}
.e-pager .e-numericitem:not(.e-active):not(.e-np):not(.e-pp):hover {
  background-color: #e9ecef;
  opacity: 1;
}
.e-pager .e-numericitem {
  background: #fff;
  color: #0d6efd;
}
.e-pager .e-prevpagedisabled,
.e-pager .e-prevpage,
.e-pager .e-nextpage,
.e-pager .e-nextpagedisabled,
.e-pager .e-lastpagedisabled,
.e-pager .e-lastpage,
.e-pager .e-firstpage,
.e-pager .e-firstpagedisabled {
  background-color: #fff;
  color: #0d6efd;
}
.e-pager .e-lastpage,
.e-pager .e-firstpage {
  background-color: transparent;
}
.e-pager .e-pagercontainer {
  background-color: #fff;
  border-color: #dee2e6;
}
.e-pager .e-numericitem,
.e-pager .e-firstpage,
.e-pager .e-prevpage,
.e-pager .e-firstpagedisabled,
.e-pager .e-prevpagedisabled,
.e-pager .e-nextpage,
.e-pager .e-lastpage,
.e-pager .e-nextpagedisabled,
.e-pager .e-lastpagedisabled {
  border-right-color: #dee2e6;
}
.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  border-right-color: #86b7fe;
  background: #e9ecef;
  color: #0d6efd;
  opacity: 1;
}
.e-pager .e-numericitem.e-focused,
.e-pager .e-first.e-focused,
.e-pager .e-prev.e-focused,
.e-pager .e-next.e-focused,
.e-pager .e-last.e-focused,
.e-pager .e-mfirst.e-focused,
.e-pager .e-mprev.e-focused,
.e-pager .e-mnext.e-focused,
.e-pager .e-mlast.e-focused {
  background-color: #eff1f4;
}
.e-pager .e-currentitem.e-numericitem.e-focused {
  background: #e9ecef;
  -webkit-box-shadow: 0 0 0 1px #0d6efd inset;
          box-shadow: 0 0 0 1px #0d6efd inset;
}
.e-pager .e-focused {
  -webkit-box-shadow: 0 0 0 1px #0d6efd inset;
          box-shadow: 0 0 0 1px #0d6efd inset;
}
@media (max-width: 590px) {
  .e-pager .e-pager-default:hover {
    background: #fff;
    color: #0d6efd;
    opacity: 1;
  }
}
.e-pager.e-rtl .e-numericitem, .e-pager.e-rtl .e-firstpage, .e-pager.e-rtl .e-prevpage, .e-pager.e-rtl .e-firstpagedisabled, .e-pager.e-rtl .e-prevpagedisabled, .e-pager.e-rtl .e-nextpage, .e-pager.e-rtl .e-lastpage, .e-pager.e-rtl .e-nextpagedisabled, .e-pager.e-rtl .e-lastpagedisabled, .e-pager.e-rtl .e-nextpage, .e-pager.e-rtl .e-nextpagedisabled {
  border-left-color: #dee2e6;
}

/*! Pager bigger */
.e-bigger .e-pager,
.e-pager.e-bigger {
  font-size: 16px;
  padding: 7px 8px;
}
.e-bigger .e-pager div.e-icons:not(.e-check),
.e-pager.e-bigger div.e-icons:not(.e-check) {
  font-size: 11px;
}
.e-bigger .e-pager div.e-parentmsgbar,
.e-pager.e-bigger div.e-parentmsgbar {
  padding-bottom: 8px;
  padding-right: 0;
  padding-top: 10px;
}
.e-bigger .e-pager:not(.e-adaptive) .e-pagerdropdown,
.e-pager.e-bigger:not(.e-adaptive) .e-pagerdropdown {
  height: 38px;
  margin: 0 10px 0 18px;
  margin-top: -24px;
  width: 90px;
}
.e-bigger .e-pager .e-pagercontainer,
.e-pager.e-bigger .e-pagercontainer {
  margin: 0 0 -6px 0.8px;
}
.e-bigger .e-pager .e-pagercontainer .e-firstpage,
.e-bigger .e-pager .e-pagercontainer .e-prevpage,
.e-bigger .e-pager .e-pagercontainer .e-firstpagedisabled,
.e-bigger .e-pager .e-pagercontainer .e-prevpagedisabled,
.e-bigger .e-pager .e-pagercontainer .e-nextpage,
.e-bigger .e-pager .e-pagercontainer .e-lastpage,
.e-bigger .e-pager .e-pagercontainer .e-nextpagedisabled,
.e-bigger .e-pager .e-pagercontainer .e-lastpagedisabled,
.e-pager.e-bigger .e-pagercontainer .e-firstpage,
.e-pager.e-bigger .e-pagercontainer .e-prevpage,
.e-pager.e-bigger .e-pagercontainer .e-firstpagedisabled,
.e-pager.e-bigger .e-pagercontainer .e-prevpagedisabled,
.e-pager.e-bigger .e-pagercontainer .e-nextpage,
.e-pager.e-bigger .e-pagercontainer .e-lastpage,
.e-pager.e-bigger .e-pagercontainer .e-nextpagedisabled,
.e-pager.e-bigger .e-pagercontainer .e-lastpagedisabled {
  padding: 16px 14px 14px;
}
.e-bigger .e-pager .e-pagerconstant,
.e-pager.e-bigger .e-pagerconstant {
  margin-bottom: 6px;
  margin-left: 16px;
}
@media (max-width: 769px) {
  .e-bigger .e-pager,
  .e-pager.e-bigger {
    padding: 19px 0;
  }
  .e-bigger .e-pager.e-rtl div.e-parentmsgbar,
  .e-pager.e-bigger.e-rtl div.e-parentmsgbar {
    margin-right: 0;
  }
  .e-bigger .e-pager div.e-parentmsgbar,
  .e-pager.e-bigger div.e-parentmsgbar {
    padding: 0;
  }
}
.e-bigger .e-pager .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
.e-bigger .e-pager .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default,
.e-pager.e-bigger .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
.e-pager.e-bigger .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
  margin-left: 0;
}
.e-bigger .e-pager .e-numericitem,
.e-pager.e-bigger .e-numericitem {
  margin: 0;
}
.e-bigger .e-pager .e-numericitem.e-currentitem, .e-bigger .e-pager .e-numericitem.e-currentitem:hover,
.e-pager.e-bigger .e-numericitem.e-currentitem,
.e-pager.e-bigger .e-numericitem.e-currentitem:hover {
  padding: 9px 13px 10px 12px;
}
.e-bigger .e-pager .e-pp.e-spacing,
.e-bigger .e-pager .e-np.e-spacing,
.e-bigger .e-pager .e-pp.e-spacing:hover,
.e-bigger .e-pager .e-np.e-spacing:hover,
.e-pager.e-bigger .e-pp.e-spacing,
.e-pager.e-bigger .e-np.e-spacing,
.e-pager.e-bigger .e-pp.e-spacing:hover,
.e-pager.e-bigger .e-np.e-spacing:hover {
  padding: 14px 14px 13px;
}
.e-bigger .e-pager .e-spacing,
.e-bigger .e-pager .e-numericitem:hover,
.e-bigger .e-pager .e-currentitem,
.e-pager.e-bigger .e-spacing,
.e-pager.e-bigger .e-numericitem:hover,
.e-pager.e-bigger .e-currentitem {
  border-radius: 0;
  padding: 12px 15px 13px;
}
.e-bigger .e-pager.e-rtl .e-spacing,
.e-bigger .e-pager.e-rtl .e-numericitem:hover,
.e-bigger .e-pager.e-rtl .e-currentitem,
.e-pager.e-bigger.e-rtl .e-spacing,
.e-pager.e-bigger.e-rtl .e-numericitem:hover,
.e-pager.e-bigger.e-rtl .e-currentitem {
  margin: 0;
  padding: 14px 14px 13px;
}
.e-bigger .e-pager.e-rtl .e-pagerdropdown,
.e-pager.e-bigger.e-rtl .e-pagerdropdown {
  margin: -26px 16px 0 0;
}
.e-bigger .e-pager.e-rtl .e-pagerconstant,
.e-pager.e-bigger.e-rtl .e-pagerconstant {
  margin: 0 16px 7px 0;
}
.e-bigger .e-pager.e-rtl .e-pagercontainer,
.e-pager.e-bigger.e-rtl .e-pagercontainer {
  margin: 0 0 -5px;
}
.e-bigger .e-pager.e-rtl .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
.e-bigger .e-pager.e-rtl .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default,
.e-pager.e-bigger.e-rtl .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
.e-pager.e-bigger.e-rtl .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
  margin-left: 0;
  margin-right: 0;
}
.e-bigger .e-pager.e-rtl div.e-parentmsgbar,
.e-pager.e-bigger.e-rtl div.e-parentmsgbar {
  margin-left: 6px;
  margin-top: 0;
}
@media (max-width: 769px) {
  .e-bigger .e-pager.e-rtl div.e-parentmsgbar,
  .e-pager.e-bigger.e-rtl div.e-parentmsgbar {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media (max-width: 769px) {
  .e-bigger .e-pager.e-rtl div.e-parentmsgbar,
  .e-pager.e-bigger.e-rtl div.e-parentmsgbar {
    margin-left: 0;
    margin-top: 10px;
  }
}
.e-bigger .e-pager.e-rtl .e-numericitem.e-currentitem,
.e-bigger .e-pager.e-rtl .e-numericitem.e-currentitem:hover,
.e-pager.e-bigger.e-rtl .e-numericitem.e-currentitem,
.e-pager.e-bigger.e-rtl .e-numericitem.e-currentitem:hover {
  padding: 9px 13px 10px 12px;
}
.e-bigger .e-pager.e-rtl .e-pp.e-spacing.e-numericitem, .e-bigger .e-pager.e-rtl .e-np.e-spacing.e-numericitem, .e-bigger .e-pager.e-rtl .e-pp.e-spacing.e-numericitem:hover, .e-bigger .e-pager.e-rtl .e-np.e-spacing.e-numericitem:hover,
.e-pager.e-bigger.e-rtl .e-pp.e-spacing.e-numericitem,
.e-pager.e-bigger.e-rtl .e-np.e-spacing.e-numericitem,
.e-pager.e-bigger.e-rtl .e-pp.e-spacing.e-numericitem:hover,
.e-pager.e-bigger.e-rtl .e-np.e-spacing.e-numericitem:hover {
  font-size: 14px;
  margin-top: 0;
  padding: 14px 14px 13px;
}
.e-bigger .e-pager.e-rtl.e-adaptive div.e-parentmsgbar,
.e-pager.e-bigger.e-rtl.e-adaptive div.e-parentmsgbar {
  margin-top: -2px;
}
.e-bigger .e-pager.e-rtl.e-adaptive div.e-pagerdropdown,
.e-pager.e-bigger.e-rtl.e-adaptive div.e-pagerdropdown {
  margin: 1px 4px 0 2px;
}
.e-bigger .e-pager.e-rtl.e-adaptive div.e-pagerconstant.e-page-all,
.e-pager.e-bigger.e-rtl.e-adaptive div.e-pagerconstant.e-page-all {
  top: 13px;
}
.e-bigger .e-pager .e-pagercontainer .e-firstpage,
.e-bigger .e-pager .e-pagercontainer .e-prevpage,
.e-bigger .e-pager .e-pagercontainer .e-firstpagedisabled,
.e-bigger .e-pager .e-pagercontainer .e-prevpagedisabled,
.e-bigger .e-pager .e-pagercontainer .e-nextpage,
.e-bigger .e-pager .e-pagercontainer .e-lastpage,
.e-bigger .e-pager .e-pagercontainer .e-nextpagedisabled,
.e-bigger .e-pager .e-pagercontainer .e-lastpagedisabled,
.e-pager.e-bigger .e-pagercontainer .e-firstpage,
.e-pager.e-bigger .e-pagercontainer .e-prevpage,
.e-pager.e-bigger .e-pagercontainer .e-firstpagedisabled,
.e-pager.e-bigger .e-pagercontainer .e-prevpagedisabled,
.e-pager.e-bigger .e-pagercontainer .e-nextpage,
.e-pager.e-bigger .e-pagercontainer .e-lastpage,
.e-pager.e-bigger .e-pagercontainer .e-nextpagedisabled,
.e-pager.e-bigger .e-pagercontainer .e-lastpagedisabled {
  margin-right: 0;
  padding: 16px 14px 14px;
}
.e-bigger .e-pager .e-pp,
.e-bigger .e-pager .e-np,
.e-bigger .e-pager .e-pp:hover,
.e-bigger .e-pager .e-np:hover,
.e-pager.e-bigger .e-pp,
.e-pager.e-bigger .e-np,
.e-pager.e-bigger .e-pp:hover,
.e-pager.e-bigger .e-np:hover {
  font-size: 14px;
}
.e-bigger .e-pager.e-adaptive div.e-parentmsgbar,
.e-pager.e-bigger.e-adaptive div.e-parentmsgbar {
  margin-top: -5px;
}
.e-bigger .e-pager.e-adaptive .e-pagerdropdown,
.e-pager.e-bigger.e-adaptive .e-pagerdropdown {
  margin: -3px 2px 0 4px;
  height: 40px;
}
.e-bigger .e-pager.e-adaptive .e-pagerconstant.e-page-all,
.e-pager.e-bigger.e-adaptive .e-pagerconstant.e-page-all {
  top: 10px;
}
.e-bigger .e-pager.e-adaptive .e-pagerconstant,
.e-pager.e-bigger.e-adaptive .e-pagerconstant {
  margin: 0 8px 0 10px;
}

/*! component's theme wise override definitions and variables */
@-webkit-keyframes hscroll-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
            box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}
@keyframes hscroll-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
            box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}
/*! hscroll icons */
.e-hscroll.e-rtl.e-scroll-device .e-nav-right-arrow::before {
  content: "\e765";
}
.e-hscroll.e-rtl.e-scroll-device .e-nav-left-arrow::before {
  content: "\e748";
}
.e-hscroll.e-rtl .e-nav-left-arrow::before {
  content: "\e748";
}
.e-hscroll.e-rtl .e-nav-right-arrow::before {
  content: "\e765";
}
.e-hscroll.e-scroll-device .e-nav-right-arrow::before {
  content: "\e748";
}
.e-hscroll.e-scroll-device .e-nav-left-arrow::before {
  content: "\e765";
}
.e-hscroll .e-nav-left-arrow::before {
  content: "\e765";
  line-height: normal;
}
.e-hscroll .e-nav-right-arrow::before {
  content: "\e748";
  line-height: normal;
}

/*! h-scroll layout */
.e-hscroll {
  display: block;
  position: relative;
  width: inherit;
}
.e-hscroll.e-rtl.e-scroll-device {
  padding-left: 40px;
  padding-right: initial;
}
.e-hscroll.e-rtl.e-scroll-device .e-scroll-nav {
  -webkit-transform: skewX(-16deg) translateX(-6px);
          transform: skewX(-16deg) translateX(-6px);
}
.e-hscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-right-overlay {
  left: 48px;
  right: auto;
  -webkit-transform: skewX(-16deg) translateX(-6px);
          transform: skewX(-16deg) translateX(-6px);
}
.e-hscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-left-overlay {
  left: auto;
  right: 0;
}
.e-hscroll:not(.e-scroll-device) {
  padding: 0 32px;
}
.e-hscroll.e-scroll-device {
  padding-right: 40px;
}
.e-hscroll.e-scroll-device .e-scroll-nav {
  -webkit-transform: skewX(-16deg) translateX(6px);
          transform: skewX(-16deg) translateX(6px);
  width: 48px;
}
.e-hscroll.e-scroll-device .e-scroll-nav .e-nav-arrow {
  font-size: 18px;
  -webkit-transform: skewX(16deg);
          transform: skewX(16deg);
}
.e-hscroll.e-scroll-device .e-scroll-overlay {
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 100;
}
.e-hscroll.e-scroll-device .e-scroll-overlay.e-scroll-right-overlay {
  left: auto;
  right: 48px;
  -webkit-transform: skewX(-16deg) translateX(6px);
          transform: skewX(-16deg) translateX(6px);
}
.e-hscroll.e-scroll-device .e-scroll-overlay.e-scroll-left-overlay {
  left: 0;
  right: auto;
}
.e-hscroll.e-overlay .e-hscroll-content > * {
  pointer-events: none;
}
.e-hscroll > * {
  height: inherit;
  line-height: normal;
}
.e-hscroll .e-hscroll-content {
  display: inline-block;
  height: inherit;
  position: relative;
}
.e-hscroll .e-hscroll-content > * {
  pointer-events: auto;
}
.e-hscroll.e-rtl .e-scroll-nav.e-scroll-right-nav {
  left: 0;
  right: auto;
}
.e-hscroll.e-rtl .e-scroll-nav.e-scroll-left-nav {
  left: auto;
  right: 0;
}
.e-hscroll .e-scroll-nav {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 38px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 32px;
}
.e-hscroll .e-scroll-nav.e-scroll-left-nav {
  left: 0;
}
.e-hscroll .e-scroll-nav.e-scroll-right-nav {
  right: 0;
}
.e-hscroll .e-scroll-nav.e-ie-align {
  display: table;
}
.e-hscroll .e-nav-arrow {
  position: relative;
}
.e-hscroll .e-nav-arrow.e-icons {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  font-size: 18px;
}
.e-hscroll .e-hscroll-bar .e-hscroll-content .e-overlay {
  pointer-events: none;
}

/*! h-scroll theme */
.e-hscroll .e-scroll-nav .e-icons {
  color: #6c757d;
}
.e-hscroll.e-rtl.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  border-color: #dee2e6;
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
}
.e-hscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  background-color: #e9ecef;
  border-color: #dee2e6;
  border-width: 1px;
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
}
.e-hscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav .e-nav-arrow {
  color: #6c757d;
}
.e-hscroll .e-scroll-overlay {
  background-color: transparent;
  background-repeat: repeat-x;
}
.e-hscroll .e-scroll-overlay.e-scroll-left-overlay {
  background-image: -webkit-gradient(linear, left top, right top, from(#e9ecef), to(rgba(233, 236, 239, 0)));
  background-image: linear-gradient(-270deg, #e9ecef 0%, rgba(233, 236, 239, 0) 100%);
}
.e-hscroll .e-scroll-overlay.e-scroll-right-overlay {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(233, 236, 239, 0)), to(#e9ecef));
  background-image: linear-gradient(-270deg, rgba(233, 236, 239, 0) 0%, #e9ecef 100%);
}
.e-hscroll.e-rtl .e-scroll-nav {
  background: #e9ecef;
}
.e-hscroll.e-rtl .e-scroll-nav.e-scroll-left-nav {
  border-left: 1px solid #dee2e6;
  border-right: 0;
}
.e-hscroll.e-rtl .e-scroll-nav.e-scroll-right-nav {
  border-left: 0;
  border-right: 1px solid #dee2e6;
}
.e-hscroll.e-rtl .e-scroll-nav:hover {
  background: #dee2e6;
  border: 0;
  color: #6c757d;
}
.e-hscroll.e-rtl .e-scroll-nav:hover:active {
  background: #dee2e6;
}
.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover {
  background: #dee2e6;
  color: #6c757d;
}
.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover .e-icons {
  color: #6c757d;
}
.e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus {
  background: #dee2e6;
  border: 0;
  color: #6c757d;
}
.e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-icons {
  color: #6c757d;
}
.e-hscroll:not(.e-scroll-device) .e-scroll-nav:active {
  background: #e9ecef;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #495057;
}
.e-hscroll:not(.e-scroll-device) .e-scroll-nav:active .e-icons {
  color: #6c757d;
}
.e-hscroll .e-scroll-nav {
  background: #e9ecef;
}
.e-hscroll .e-scroll-nav.e-scroll-left-nav {
  border-right: 1px solid #dee2e6;
}
.e-hscroll .e-scroll-nav.e-scroll-right-nav {
  border-left: 1px solid #dee2e6;
}
.e-hscroll .e-scroll-nav::after {
  background-color: transparent;
  border-radius: 50%;
  border-width: 1px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  content: "";
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 1px;
}
.e-hscroll .e-scroll-nav:active::after {
  -webkit-animation: hscroll-popup-shadow 0.6s ease-out 0ms;
          animation: hscroll-popup-shadow 0.6s ease-out 0ms;
  visibility: visible;
}

.e-bigger .e-hscroll,
.e-hscroll.e-bigger {
  min-height: 46px;
}
.e-bigger .e-hscroll:not(.e-scroll-device),
.e-hscroll.e-bigger:not(.e-scroll-device) {
  padding: 0 38px;
}
.e-bigger .e-hscroll.e-scroll-device,
.e-hscroll.e-bigger.e-scroll-device {
  padding-right: 38px;
}
.e-bigger .e-hscroll.e-rtl.e-scroll-device,
.e-hscroll.e-bigger.e-rtl.e-scroll-device {
  padding-left: 38px;
  padding-right: initial;
}
.e-bigger .e-hscroll .e-nav-arrow.e-icons,
.e-hscroll.e-bigger .e-nav-arrow.e-icons {
  font-size: 22px;
}
.e-bigger .e-hscroll.e-rtl .e-scroll-overlay.e-scroll-right-overlay,
.e-hscroll.e-bigger.e-rtl .e-scroll-overlay.e-scroll-right-overlay {
  left: 38px;
}
.e-bigger .e-hscroll .e-scroll-overlay.e-scroll-right-overlay,
.e-hscroll.e-bigger .e-scroll-overlay.e-scroll-right-overlay {
  right: 38px;
}
.e-bigger .e-hscroll .e-scroll-nav,
.e-hscroll.e-bigger .e-scroll-nav {
  min-height: 46px;
  width: 38px;
}

/*! component's theme wise override definitions and variables */
/*! vscroll icons */
.e-vscroll.e-scroll-device .e-nav-up-arrow::before {
  content: "\e7dd";
}
.e-vscroll.e-scroll-device .e-nav-down-arrow::before {
  content: "\e734";
}
.e-vscroll .e-nav-up-arrow::before {
  content: "\e776";
  line-height: normal;
}
.e-vscroll .e-nav-down-arrow::before {
  content: "\e729";
  line-height: normal;
}

/*! v-scroll layout */
.e-vscroll {
  display: block;
  position: relative;
  width: inherit;
}
.e-vscroll.e-rtl.e-scroll-device .e-scroll-nav {
  -webkit-transform: skewX(-16deg) translateX(-6px);
          transform: skewX(-16deg) translateX(-6px);
}
.e-vscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-down-overlay {
  left: 48px;
  right: auto;
  -webkit-transform: skewX(-16deg) translateX(-6px);
          transform: skewX(-16deg) translateX(-6px);
}
.e-vscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-up-overlay {
  left: auto;
  right: 0;
}
.e-vscroll:not(.e-scroll-device) {
  padding: 0 28px;
}
.e-vscroll.e-scroll-device .e-scroll-nav {
  -webkit-transform: skewX(-16deg) translateX(6px);
          transform: skewX(-16deg) translateX(6px);
  width: 48px;
  z-index: 1001;
}
.e-vscroll.e-scroll-device .e-scroll-nav .e-nav-arrow {
  font-size: 14px;
  -webkit-transform: skewX(16deg);
          transform: skewX(16deg);
}
.e-vscroll.e-scroll-device .e-scroll-overlay {
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 100;
}
.e-vscroll.e-scroll-device .e-scroll-overlay.e-scroll-down-overlay {
  left: auto;
  right: 48px;
  -webkit-transform: skewX(-16deg) translateX(6px);
          transform: skewX(-16deg) translateX(6px);
}
.e-vscroll.e-scroll-device .e-scroll-overlay.e-scroll-up-overlay {
  left: 0;
  right: auto;
}
.e-vscroll > * {
  height: inherit;
}
.e-vscroll .e-vscroll-content {
  display: inline-block;
  height: auto;
  position: relative;
  width: 100%;
}
.e-vscroll .e-vscroll-content > * {
  pointer-events: auto;
}
.e-vscroll.e-rtl .e-scroll-nav.e-scroll-up-nav {
  left: auto;
  right: 0;
}
.e-vscroll.e-rtl .e-scroll-nav.e-scroll-down-nav {
  left: 0;
  right: auto;
}
.e-vscroll .e-scroll-nav {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 38px;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.e-vscroll .e-scroll-nav.e-scroll-up-nav {
  top: 0;
}
.e-vscroll .e-scroll-nav.e-scroll-down-nav {
  bottom: 0;
}
.e-vscroll .e-scroll-nav.e-ie-align {
  display: table;
}
.e-vscroll .e-nav-arrow {
  position: relative;
}
.e-vscroll .e-nav-arrow.e-icons {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

/*! v-scroll theme */
.e-vscroll .e-icons {
  color: #6c757d;
}
.e-vscroll.e-rtl.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  border-color: #6c757d;
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
}
.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  background-color: #e9ecef;
  border-color: #6c757d;
  border-width: 1px;
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
}
.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav .e-nav-arrow {
  color: #6c757d;
}
.e-vscroll .e-scroll-overlay {
  background-color: transparent;
  background-repeat: repeat-x;
}
.e-vscroll .e-scroll-overlay.e-scroll-up-overlay {
  background-image: -webkit-gradient(linear, left top, right top, from(#e9ecef), to(rgba(233, 236, 239, 0)));
  background-image: linear-gradient(-270deg, #e9ecef 0%, rgba(233, 236, 239, 0) 100%);
}
.e-vscroll .e-scroll-overlay.e-scroll-down-overlay {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(233, 236, 239, 0)), to(#e9ecef));
  background-image: linear-gradient(-270deg, rgba(233, 236, 239, 0) 0%, #e9ecef 100%);
}
.e-vscroll.e-rtl .e-scroll-nav {
  background: #e9ecef;
}
.e-vscroll.e-rtl .e-scroll-nav:hover {
  background: #dee2e6;
  border: 0;
  border-color: transparent;
  color: #495057;
}
.e-vscroll:not(.e-scroll-device) .e-scroll-nav:hover {
  background: #dee2e6;
  border: 0;
  color: #495057;
}
.e-vscroll:not(.e-scroll-device) .e-scroll-nav:focus {
  background: #dee2e6;
  border: 0;
  border-color: transparent;
  color: #495057;
}
.e-vscroll:not(.e-scroll-device) .e-scroll-nav:active {
  background: #dee2e6;
  border: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  color: #495057;
}
.e-vscroll .e-scroll-nav {
  background: #e9ecef;
}
.e-vscroll .e-scroll-nav.e-scroll-up-nav {
  border-bottom: 1px solid #6c757d;
}
.e-vscroll .e-scroll-nav.e-scroll-down-nav {
  border-top: 1px solid #6c757d;
}
.e-vscroll .e-scroll-nav::after {
  content: "";
}
.e-vscroll .e-scroll-nav:active::after {
  content: "";
}

.e-bigger .e-vscroll:not(.e-scroll-device),
.e-vscroll.e-bigger:not(.e-scroll-device) {
  padding: 48px 0;
}
.e-bigger .e-vscroll .e-icons,
.e-vscroll.e-bigger .e-icons {
  font-size: 18px;
}
.e-bigger .e-vscroll.e-rtl .e-scroll-overlay.e-scroll-down-overlay,
.e-vscroll.e-bigger.e-rtl .e-scroll-overlay.e-scroll-down-overlay {
  left: 48px;
}
.e-bigger .e-vscroll .e-scroll-overlay.e-scroll-down-overlay,
.e-vscroll.e-bigger .e-scroll-overlay.e-scroll-down-overlay {
  right: 48px;
}
.e-bigger .e-vscroll .e-scroll-nav,
.e-vscroll.e-bigger .e-scroll-nav {
  height: 48px;
}

/* stylelint-disable-line no-empty-source */
/* stylelint-disable */
.e-toolbar .e-popup-down-icon::before {
  content: "\e729";
  line-height: normal;
}
.e-toolbar .e-popup-up-icon::before {
  content: "\e776";
  line-height: normal;
}

.e-toolbar {
  border-radius: 0;
  display: block;
  height: 38px;
  min-height: 38px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  overflow: hidden;
}
.e-toolbar.e-spacer-toolbar .e-toolbar-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  width: 100%;
}
.e-toolbar.e-spacer-toolbar .e-toolbar-items:not(.e-toolbar-multirow) {
  position: absolute;
}
.e-toolbar.e-spacer-toolbar.e-pop-mode .e-toolbar-items {
  width: calc(100% - 28px);
}
.e-toolbar .e-blazor-toolbar-items {
  position: absolute;
  top: -9999px;
  visibility: hidden;
}
.e-toolbar.e-control[class*=e-toolbar] {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.e-toolbar.e-corner {
  border-radius: 4px;
}
.e-toolbar.e-hidden {
  display: none;
}
.e-toolbar .e-toolbar-items {
  border-radius: 0 0 0 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 100%;
  vertical-align: middle;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.e-toolbar .e-toolbar-items.e-toolbar-multirow {
  margin-bottom: 1px;
  margin-left: 10px;
  margin-right: 10px;
  white-space: normal;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item:not(.e-separator) {
  margin: 0;
}
.e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-multirow-separator, .e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-hidden {
  display: none;
}
.e-toolbar .e-toolbar-items.e-multirow-pos .e-toolbar-left,
.e-toolbar .e-toolbar-items.e-multirow-pos .e-toolbar-center,
.e-toolbar .e-toolbar-items.e-multirow-pos .e-toolbar-right {
  display: inline;
}
.e-toolbar .e-toolbar-items.e-tbar-pos {
  display: block;
}
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-center,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  display: table;
  height: 100%;
  top: 0;
}
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  position: absolute;
}
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  right: 0;
}
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  left: 0;
}
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-center {
  margin: 0 auto;
}
.e-toolbar .e-toolbar-items .e-toolbar-left,
.e-toolbar .e-toolbar-items .e-toolbar-center,
.e-toolbar .e-toolbar-items .e-toolbar-right {
  display: inline-block;
}
.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child, .e-toolbar .e-toolbar-items:not(.e-tbar-pos):not(.e-toolbar-multirow) .e-toolbar-item:first-child {
  margin-left: 8px;
}
.e-toolbar .e-toolbar-items:first-child:not(.e-toolbar-multirow) > .e-toolbar-item:last-child,
.e-toolbar .e-toolbar-items:first-child:not(.e-toolbar-multirow) > .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 8px;
}
.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content {
  -ms-touch-action: pan-y pinch-zoom;
      touch-action: pan-y pinch-zoom;
}
.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-right: 8px;
}
.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item,
.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}
.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 8px;
}
.e-toolbar .e-toolbar-item {
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 38px;
  vertical-align: middle;
  width: auto;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.e-toolbar .e-toolbar-item.e-spacer {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.e-toolbar .e-toolbar-item .e-tbar-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0;
  min-height: 32px;
  min-width: 32px;
  padding: 0 4px;
  border-radius: 4px;
  line-height: 22px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-transform: none;
}
.e-toolbar .e-toolbar-item .e-tbar-btn.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 4px;
}
.e-toolbar .e-toolbar-item .e-tbar-btn.e-tbtn-txt .e-icons.e-btn-icon.e-icon-right {
  padding: 4px;
}
.e-toolbar .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
  margin: 0;
  min-width: 12px;
  width: auto;
  font-size: 18px;
  line-height: 14px;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:hover, .e-toolbar .e-toolbar-item .e-tbar-btn:focus, .e-toolbar .e-toolbar-item .e-tbar-btn:active {
  padding: 0 4px;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:focus {
  outline: 0;
}
.e-toolbar .e-toolbar-item .e-tbar-btn div {
  vertical-align: middle;
}
.e-toolbar .e-toolbar-item .e-tbar-btn .e-tbar-btn-text {
  font-size: 14px;
  padding: 4px;
}
.e-toolbar .e-toolbar-item:not(.e-separator):not(.e-spacer) {
  height: inherit;
  min-width: 28px;
  padding: 4px;
}
.e-toolbar .e-toolbar-item.e-separator {
  margin: 5px 10px;
  min-height: 24px;
  min-width: 1px;
  height: 24px;
}
.e-toolbar .e-toolbar-item.e-separator + .e-separator, .e-toolbar .e-toolbar-item.e-separator:last-of-type, .e-toolbar .e-toolbar-item.e-separator:first-of-type {
  display: none;
}
.e-toolbar .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 4px 4px 5px 4px;
}
.e-toolbar .e-toolbar-item > * {
  text-overflow: ellipsis;
}
.e-toolbar .e-toolbar-item.e-hidden {
  display: none;
}
.e-toolbar .e-toolbar-item input[type=checkbox] {
  height: auto;
}
.e-toolbar.e-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.e-toolbar.e-vertical .e-toolbar-items .e-vscroll-bar .e-vscroll-content {
  -ms-touch-action: pan-x pinch-zoom;
      touch-action: pan-x pinch-zoom;
}
.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-left,
.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-center,
.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  height: auto;
}
.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  left: auto;
  right: auto;
  top: 0;
}
.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  bottom: 0;
  left: auto;
  right: auto;
}
.e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}
.e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin-right: 0;
}
.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
}
.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  min-width: 33px;
}
.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item.e-separator {
  height: auto;
  margin: 3px 7px;
  min-height: auto;
}
.e-toolbar.e-vertical .e-hor-nav {
  bottom: 0;
  height: auto;
  left: 0;
  min-height: 40px;
  min-width: 50px;
  right: auto;
  top: auto;
  width: auto;
}
.e-toolbar.e-vertical.e-rtl.e-tbar-pos .e-toolbar-left {
  bottom: 0;
  top: auto;
}
.e-toolbar.e-vertical.e-rtl.e-tbar-pos .e-toolbar-right {
  bottom: auto;
  top: 0;
}
.e-toolbar .e-hor-nav {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0 0 0 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 38px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
}
.e-toolbar .e-hor-nav.e-ie-align {
  display: table;
}
.e-toolbar .e-popup-down-icon.e-icons,
.e-toolbar .e-popup-up-icon.e-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  vertical-align: middle;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  font-size: 18px;
  color: #6c757d;
}
.e-toolbar.e-toolpop {
  overflow: visible;
}
.e-toolbar.e-toolpop .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn-text {
  display: none;
}
.e-toolbar .e-toolbar-pop {
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 38px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 38px;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item.e-toolbar-popup.e-hidden {
  display: none;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn {
  min-height: 38px;
  min-width: 100%;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  margin: 0;
  padding: 0 4px 0 0;
  width: auto;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
  padding: 0 0 0 4px;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  height: 38px;
  min-width: 34px;
  padding: 0;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item > * {
  height: 100%;
  min-width: 100%;
  text-overflow: ellipsis;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item.e-tbtn-align .e-btn.e-control .e-icons.e-btn-icon {
  min-width: 100%;
}
.e-toolbar .e-toolbar-pop .e-toolbar-text .e-tbar-btn-text {
  display: none;
}
.e-toolbar .e-toolbar-pop .e-toolbar-popup,
.e-toolbar .e-toolbar-pop .e-toolpopup {
  text-align: center;
}
.e-toolbar.e-extended-toolbar {
  overflow: visible;
}
.e-toolbar.e-extended-toolbar.e-tbar-extended {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: 38px;
  padding-bottom: 0;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 0;
  margin-left: -1px;
  -webkit-box-shadow: none;
          box-shadow: none;
  display: inline;
  white-space: normal;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 38px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  min-height: 32px;
  min-width: 32px;
  padding: 0 4px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text {
  padding: 4px;
  font-size: 14px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon:not(.e-toolbar-pop),
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon:not(.e-toolbar-pop) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 5px 6px;
  font-size: 16px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon:not(.e-toolbar-pop),
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon:not(.e-toolbar-pop) {
  padding: 4px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon:not(.e-toolbar-pop),
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon:not(.e-toolbar-pop) {
  padding: 4px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:hover, .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:focus, .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover,
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:focus,
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:active {
  padding: 0 4px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item:not(.e-separator) {
  min-width: 28px;
  padding: 4px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  min-height: 16px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator.e-extended-separator {
  display: none;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-toolbar-text .e-tbar-btn-text {
  display: none;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-popup-close {
  display: none;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-popup-open {
  display: inline;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-toolbar-pop {
  width: inherit;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-toolbar-pop .e-toolbar-item .e-tbar-btn {
  cursor: pointer;
  font-size: 18px;
  overflow: hidden;
  padding: 0 4px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-toolbar-pop .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
  font-size: 14px;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  height: auto;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-toolbar-pop .e-toolbar-item > * {
  -ms-flex-item-align: center;
      align-self: center;
  text-overflow: ellipsis;
}
.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn-text {
  display: none;
}
.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-separator:last-of-type {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.e-toolbar.e-extended-toolbar .e-hor-nav.e-ie-align {
  display: table;
}
.e-toolbar.e-rtl:not(.e-spacer-toolbar) .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-right: 8px;
}
.e-toolbar.e-rtl .e-toolbar-item .e-tbar-btn.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 4px;
}
.e-toolbar.e-rtl .e-toolbar-item .e-tbar-btn.e-tbtn-txt .e-icons.e-btn-icon.e-icon-right {
  padding: 4px;
}
.e-toolbar.e-rtl .e-toolbar-item .e-tbar-btn .e-tbar-btn-text {
  padding: 4px;
}
.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-left: 8px;
  margin-right: initial;
}
.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item,
.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}
.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 8px;
}
.e-toolbar.e-rtl .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  left: auto;
  right: 0;
}
.e-toolbar.e-rtl .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  left: 0;
  right: auto;
}
.e-toolbar.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 0;
}
.e-toolbar.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:last-child {
  margin-left: 8px;
}
.e-toolbar.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}
.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-item:last-child:last-child,
.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 0;
}
.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-item:last-child:first-child,
.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-right .e-toolbar-item:first-child {
  margin-right: 8px;
}
.e-toolbar.e-rtl .e-hor-nav {
  left: 0;
  right: auto;
  border-radius: 0 0 0 0;
}
.e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-center .e-toolbar-item,
.e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item {
  margin: 0;
}
.e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item:last-child {
  margin: 0;
  margin-left: 8px;
}
.e-toolbar.e-extended-toolbar.e-rtl .e-hor-nav {
  left: 0;
  right: auto;
}
.e-toolbar.e-extended-toolbar.e-rtl .e-toolbar-extended {
  padding-right: 8px;
  margin-left: 0;
}
.e-toolbar.e-extended-toolbar.e-rtl .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-icon-left {
  padding-left: 0;
}

/* stylelint-disable property-no-vendor-prefix */
.e-toolbar {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #f8f9fa;
  border: 0 solid #dee2e6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-toolbar .e-toolbar-items {
  background: #f8f9fa;
}
.e-toolbar .e-toolbar-item .e-tbar-btn {
  background: #f8f9fa;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #212529;
  border: none;
}
.e-toolbar .e-toolbar-item .e-tbar-btn .e-icons {
  color: #6c757d;
}
.e-toolbar .e-toolbar-item .e-tbar-btn .e-tbar-btn-text {
  color: #212529;
}
.e-toolbar .e-toolbar-item .e-tbar-btn.e-flat.e-active {
  background: #565e64;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-toolbar .e-toolbar-item .e-tbar-btn.e-flat.e-active .e-tbar-btn-text {
  color: #fff;
}
.e-toolbar .e-toolbar-item .e-tbar-btn.e-flat.e-active:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(130, 138, 145, 0.5);
          box-shadow: 0 0 0 4px rgba(130, 138, 145, 0.5);
}
.e-toolbar .e-toolbar-item .e-tbar-btn:focus {
  background: #5c636a;
  border-radius: 4px;
  color: #6c757d;
  border-color: #6c757d;
  border-style: solid;
  border-width: 0;
  -webkit-box-shadow: 0 0 0 4px rgba(130, 138, 145, 0.5);
          box-shadow: 0 0 0 4px rgba(130, 138, 145, 0.5);
}
.e-toolbar .e-toolbar-item .e-tbar-btn:focus .e-icons {
  color: #fff;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:focus .e-tbar-btn-text {
  color: #fff;
  color: #fff;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:hover {
  background: #5c636a;
  border-color: #6c757d;
  border-style: solid;
  border-width: 0;
  border-radius: 4px;
  color: #6c757d;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:hover .e-icons {
  color: #fff;
  color: #fff;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:hover .e-tbar-btn-text {
  color: #fff;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:hover:active .e-icons {
  color: #fff;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:hover:active .e-tbar-btn-text {
  color: #fff;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:active {
  background: #565e64;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #495057;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:active .e-icons {
  color: #fff;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:active .e-tbar-btn-text {
  color: #fff;
}
.e-toolbar .e-toolbar-item.e-separator {
  border: solid #dee2e6;
  border-width: 0 1px 0 0;
}
.e-toolbar .e-toolbar-item.e-overlay {
  background: #f8f9fa;
  opacity: 0.5;
  color: #495057;
}
.e-toolbar .e-toolbar-item.e-overlay .e-tbar-btn-text {
  color: #495057;
}
.e-toolbar .e-toolbar-item.e-overlay .e-icons {
  color: #495057;
}
.e-toolbar.e-vertical .e-hor-nav {
  border: solid #dee2e6;
  border-width: 0 0 0 0;
}
.e-toolbar.e-vertical.e-rtl .e-hor-nav {
  border: solid #dee2e6;
  border-width: 0 0 0 0;
}
.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item.e-separator {
  border-width: 0 0 1px 0;
}
.e-toolbar .e-hor-nav {
  background: #f8f9fa;
  border: solid #dee2e6;
  border-width: 0 0 0 0;
}
.e-toolbar .e-hor-nav::after {
  content: "";
}
.e-toolbar .e-hor-nav.e-nav-active:active, .e-toolbar .e-hor-nav.e-nav-active:focus, .e-toolbar .e-hor-nav.e-nav-active:hover {
  border-bottom-right-radius: 0;
}
.e-toolbar .e-hor-nav:active {
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #495057;
  background: #f8f9fa;
}
.e-toolbar .e-hor-nav:active .e-icons {
  color: #6c757d;
}
.e-toolbar .e-hor-nav:active::after {
  content: "";
}
.e-toolbar .e-hor-nav:hover {
  background: #5c636a;
  border-left: 0;
  color: #fff;
}
.e-toolbar .e-hor-nav:hover .e-icons {
  color: inherit;
}
.e-toolbar .e-hor-nav:hover:not(.e-nav-active) {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0;
}
.e-toolbar .e-hor-nav:hover:active {
  background: #5c636a;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0;
}
.e-toolbar .e-hor-nav:focus {
  background: #5c636a;
  color: #fff;
  border-color: #6c757d;
  border-style: solid;
  border-width: 0;
}
.e-toolbar .e-hor-nav:focus .e-icons {
  color: inherit;
}
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active,
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active:not(.e-expended-nav) {
  background: #5c636a;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #5c636a;
  border-style: solid;
  border-width: 0;
}
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active:focus,
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active:not(.e-expended-nav):focus {
  border-color: #5c636a;
  border-style: solid;
  border-width: 0;
}
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active .e-icons,
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active:not(.e-expended-nav) .e-icons {
  color: #fff;
}
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active .e-icons:active,
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active:not(.e-expended-nav) .e-icons:active {
  color: #fff;
}
.e-toolbar .e-toolbar-pop {
  background: #fff;
  border: 0 solid #dee2e6;
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
}
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn {
  background: transparent;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:hover {
  background: #5c636a;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #6c757d;
  border-style: solid;
  border-width: 0;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:active {
  background: #565e64;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:focus {
  background: #5c636a;
  border-color: #6c757d;
  border-style: solid;
  border-width: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-btn:focus {
  outline: 0;
}
.e-toolbar .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  background: transparent;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn {
  background: #f8f9fa;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:focus {
  background: #5c636a;
  border-radius: 4px;
  border-color: #6c757d;
  border-style: solid;
  border-width: 0;
  -webkit-box-shadow: 0 0 0 4px rgba(130, 138, 145, 0.5);
          box-shadow: 0 0 0 4px rgba(130, 138, 145, 0.5);
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:hover {
  background: #5c636a;
  border-radius: 4px;
  border-color: #6c757d;
  border-style: solid;
  border-width: 0;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:active {
  background: #565e64;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  border: solid #dee2e6;
  border-width: 0 1px 0 0;
}
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-overlay {
  background: #f8f9fa;
}
.e-toolbar.e-extended-toolbar .e-toolbar-pop {
  background: #f8f9fa;
}
.e-toolbar.e-rtl .e-hor-nav {
  background: #f8f9fa;
  border: solid #dee2e6;
  border-width: 0 0 0 0;
}
.e-toolbar.e-rtl .e-hor-nav:not(.e-nav-active):hover {
  background: #5c636a;
  color: #6c757d;
  border: solid #dee2e6;
  border-width: 0 0 0 0;
}

.e-bigger .e-toolbar,
.e-toolbar.e-bigger {
  height: 46px;
  min-height: 46px;
}
.e-bigger .e-toolbar .e-toolbar-items:not(.e-tbar-pos):not(.e-toolbar-multirow) .e-toolbar-item:first-child,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child,
.e-toolbar.e-bigger .e-toolbar-items:not(.e-tbar-pos):not(.e-toolbar-multirow) .e-toolbar-item:first-child,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 12px;
}
.e-bigger .e-toolbar .e-toolbar-items:first-child:not(.e-toolbar-multirow) > .e-toolbar-item:last-child,
.e-bigger .e-toolbar .e-toolbar-items:first-child:not(.e-toolbar-multirow) > .e-toolbar-right .e-toolbar-item:last-child,
.e-toolbar.e-bigger .e-toolbar-items:first-child:not(.e-toolbar-multirow) > .e-toolbar-item:last-child,
.e-toolbar.e-bigger .e-toolbar-items:first-child:not(.e-toolbar-multirow) > .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 12px;
}
.e-bigger .e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child,
.e-toolbar.e-bigger .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-right: 12px;
}
.e-bigger .e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child,
.e-toolbar.e-bigger .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 12px;
}
.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow {
  margin-left: 12.5px;
  margin-right: 12.5px;
}
.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow .e-toolbar-item:not(.e-separator) {
  margin: 0;
}
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus, .e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover, .e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:active,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:focus,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:active {
  padding: 0 8px;
}
.e-bigger .e-toolbar .e-toolbar-item,
.e-toolbar.e-bigger .e-toolbar-item {
  min-height: 46px;
}
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn,
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  min-height: 38px;
  min-width: 38px;
  padding: 0 8px;
  line-height: 24px;
}
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text {
  padding: 4px;
  font-size: 16px;
}
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn .e-icons,
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-icons,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn .e-icons,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-icons {
  font-size: 20px;
}
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon,
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon {
  font-size: 18px;
  line-height: 14px;
}
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 4px 6px;
}
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon,
.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon {
  padding: 4px 6px;
}
.e-bigger .e-toolbar .e-toolbar-item:not(.e-separator):not(.e-spacer),
.e-toolbar.e-bigger .e-toolbar-item:not(.e-separator):not(.e-spacer) {
  min-width: 49px;
  padding: 4px;
}
.e-bigger .e-toolbar .e-toolbar-item.e-separator,
.e-toolbar.e-bigger .e-toolbar-item.e-separator {
  height: 30px;
  margin: 8px;
  min-height: 30px;
}
.e-bigger .e-toolbar .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-bigger .e-toolbar .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 4px 6px 3px 6px;
}
.e-bigger .e-toolbar .e-hor-nav,
.e-toolbar.e-bigger .e-hor-nav {
  min-height: 46px;
  min-width: 36px;
}
.e-bigger .e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-toolbar.e-bigger.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}
.e-bigger .e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin-right: 0;
}
.e-bigger .e-toolbar.e-vertical .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger.e-vertical .e-toolbar-item:not(.e-separator) {
  min-height: 38px;
}
.e-bigger .e-toolbar.e-vertical .e-toolbar-item.e-separator,
.e-toolbar.e-bigger.e-vertical .e-toolbar-item.e-separator {
  height: auto;
  margin: 5px 10px;
  min-height: auto;
}
.e-bigger .e-toolbar.e-vertical .e-hor-nav,
.e-toolbar.e-bigger.e-vertical .e-hor-nav {
  min-height: 40px;
  min-width: 50px;
}
.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item {
  height: 38px;
}
.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  min-width: 48px;
  padding: 0;
  min-height: 46px;
}
.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn {
  min-height: 46px;
  padding: 8px 18px;
  min-width: 100%;
}
.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  margin-left: 0;
  padding: 0 6px 0 0;
  font-size: 16px;
}
.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
  padding: 0 0 0 6px;
  font-size: 16px;
}
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended {
  min-height: 46px;
  padding-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0;
}
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item {
  min-height: 46px;
}
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  min-height: 38px;
  min-width: 38px;
  padding: 0 8px;
}
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text {
  padding: 4px;
}
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:hover, .e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:focus, .e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:active,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:hover,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:focus,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:focus,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:active {
  padding: 0 8px;
}
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item:not(.e-separator) {
  min-width: 49px;
  padding: 4px;
}
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  height: 30px;
  margin: 8px;
  min-height: 30px;
}
.e-bigger .e-toolbar.e-extended-toolbar.e-tbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar.e-tbar-extended {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.e-bigger .e-toolbar.e-extended-toolbar.e-tbar-extended .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar.e-tbar-extended .e-toolbar-extended {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.e-bigger .e-toolbar.e-rtl .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger.e-rtl .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 4px 6px;
}
.e-bigger .e-toolbar.e-rtl .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon.e-icon-right,
.e-toolbar.e-bigger.e-rtl .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon.e-icon-right {
  padding: 4px 6px;
}
.e-bigger .e-toolbar.e-rtl .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-bigger.e-rtl .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
  padding: 4px;
}
.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-left: 12px;
  margin-right: initial;
}
.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item,
.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}
.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-left: 12px;
  margin-right: 0;
}
.e-bigger .e-toolbar.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 12px;
}
.e-bigger .e-toolbar.e-extended-toolbar.e-rtl .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar.e-rtl .e-toolbar-extended {
  padding-right: 12px;
}

.e-accordion .e-tgl-collapse-icon::before {
  content: "\e729";
}
.e-accordion .e-tgl-collapse-icon.e-expand-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.e-accordion {
  display: block;
  position: relative;
}
.e-accordion .e-acrdn-item {
  border-radius: 0;
  margin-top: 0;
}
.e-accordion .e-acrdn-item.e-select.e-selected:first-child {
  border-top: 1px none #dee2e6;
}
.e-accordion .e-acrdn-item.e-select.e-selected:last-child {
  border-bottom: 1px solid #dee2e6;
}
.e-accordion .e-acrdn-item:first-child {
  margin-top: 0;
}
.e-accordion .e-acrdn-item:not(.e-select) + .e-acrdn-item:not(.e-select) {
  margin-bottom: 0;
  margin-top: 0;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-item.e-select {
  margin: 0;
}
.e-accordion .e-acrdn-item > .e-acrdn-header .e-acrdn-header-content {
  font-weight: 400;
}
.e-accordion .e-acrdn-item.e-selected {
  padding-top: 0;
}
.e-accordion .e-acrdn-item.e-selected > .e-acrdn-header .e-acrdn-header-content {
  font-weight: 400;
}
.e-accordion .e-acrdn-item.e-overlay {
  height: auto;
}
.e-accordion .e-acrdn-item {
  overflow: visible;
  padding-top: 0;
  position: relative;
}
.e-accordion .e-acrdn-item.e-hide {
  display: none;
}
.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-size: 14px;
}
.e-accordion .e-acrdn-item.e-select > .e-acrdn-header {
  cursor: pointer;
}
.e-accordion .e-acrdn-item.e-select > .e-acrdn-header:hover .e-acrdn-header-content, .e-accordion .e-acrdn-item.e-select > .e-acrdn-header:focus .e-acrdn-header-content {
  text-decoration: none;
}
.e-accordion .e-acrdn-item .e-acrdn-header {
  border-radius: 0;
  line-height: 22px;
  min-height: 38px;
  overflow: hidden;
  padding: 10px 16px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-accordion .e-acrdn-item .e-acrdn-header > * {
  display: inline-block;
}
.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  line-height: 22px;
}
.e-accordion .e-acrdn-item .e-acrdn-header .e-toggle-icon {
  display: table;
  font-size: 18px;
  height: 38px;
  min-height: 38px;
  min-width: 22px;
  position: absolute;
  right: 16px;
  top: 0;
}
.e-accordion .e-acrdn-item .e-acrdn-header .e-toggle-icon .e-tgl-collapse-icon.e-icons {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.e-accordion .e-acrdn-item .e-acrdn-header .e-toggle-animation {
  -webkit-transition: 0.5s ease 0s;
  transition: 0.5s ease 0s;
}
.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  display: inline-block;
  padding: 0 8px 0 0;
}
.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon .e-acrdn-icons.e-icons {
  font-size: 18px;
}
.e-accordion .e-acrdn-item .e-acrdn-panel {
  font-size: 14px;
  overflow-y: hidden;
  text-decoration: none;
  width: 100%;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-panel:not(.e-nested) > .e-acrdn-content {
  padding: 16px 16px 16px 26px;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-header {
  padding: 10px 0 10px 26px;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-header:focus {
  -webkit-box-shadow: inset 0 0 0 4px rgba(13, 110, 253, 0.25);
          box-shadow: inset 0 0 0 4px rgba(13, 110, 253, 0.25);
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion {
  border: 0;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 8px 0 8px 38px;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-panel .e-acrdn-content {
  padding: 20px 20px 20px 38px;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-item.e-select.e-selected .e-acrdn-header > .e-acrdn-header-content {
  font-weight: 400;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-nested .e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-weight: normal;
}
.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content {
  line-height: 1.5;
  overflow: hidden;
  padding: 12px;
  text-overflow: ellipsis;
}
.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content > * {
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-header-content {
  font-size: 14px;
}
.e-accordion .e-acrdn-item .e-content-hide {
  display: none;
}
.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-panel:not(.e-nested) > .e-acrdn-content {
  padding: 16px 26px 16px 16px;
}
.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-header {
  padding: 8px 26px 8px 0;
}
.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 8px 38px 8px 0;
}
.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-panel .e-acrdn-content {
  padding: 20px 38px 20px 20px;
}
.e-accordion.e-rtl .e-acrdn-item .e-acrdn-header {
  padding: 7px 12px;
}
.e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-toggle-icon {
  left: 16px;
  right: auto;
}
.e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  padding: 0 0 0 8px;
}

.e-accordion {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
.e-accordion .e-acrdn-item {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.e-accordion .e-acrdn-item.e-active {
  background: #fff;
}
.e-accordion .e-acrdn-item.e-select:last-child {
  border-bottom: 0;
  border-radius: 0;
}
.e-accordion .e-acrdn-item:first-child .e-acrdn-header:focus {
  border-radius: 4px 4px 0 0;
}
.e-accordion .e-acrdn-item:last-child {
  border-bottom: 0;
}
.e-accordion .e-acrdn-item:last-child:not(.e-expand-state) .e-acrdn-header:focus {
  border-radius: 0 0 4px 4px;
}
.e-accordion .e-acrdn-item:last-child.e-selected .e-acrdn-header:focus {
  border-radius: 0;
}
.e-accordion .e-acrdn-item.e-item-focus {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
}
.e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state {
  border-top: 1px none #dee2e6;
}
.e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state .e-acrdn-header:focus {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
}
.e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state .e-acrdn-header:hover:focus {
  background: #e7f1ff;
}
.e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state .e-acrdn-header:hover:focus .e-acrdn-header-content {
  color: #0d6efd;
}
.e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state .e-acrdn-header:hover:focus .e-icons {
  color: #0d6efd;
}
.e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state .e-acrdn-header:hover:focus .e-acrdn-header-icon .e-icons {
  color: #6c757d;
}
.e-accordion .e-acrdn-item.e-item-focus.e-expand-state.e-select, .e-accordion .e-acrdn-item.e-item-focus.e-select.e-selected.e-expand-state {
  border-color: #dee2e6;
}
.e-accordion .e-acrdn-item.e-expand-state.e-select {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
}
.e-accordion .e-acrdn-item .e-acrdn-header {
  border: 0;
}
.e-accordion .e-acrdn-item.e-overlay.e-select.e-expand-state .e-acrdn-header .e-icons,
.e-accordion .e-acrdn-item.e-overlay.e-select.e-expand-state .e-acrdn-header .e-acrdn-header-content {
  color: #adb5bd;
}
.e-accordion .e-acrdn-item.e-overlay {
  background: #fff;
  opacity: 0.5;
}
.e-accordion .e-acrdn-item.e-overlay .e-acrdn-header .e-icons,
.e-accordion .e-acrdn-item.e-overlay .e-acrdn-header .e-acrdn-header-content {
  color: #212529;
}
.e-accordion .e-acrdn-item.e-overlay.e-overlay.e-select.e-expand-state .e-acrdn-header .e-icons {
  color: #adb5bd;
}
.e-accordion .e-acrdn-item.e-overlay.e-overlay.e-select.e-expand-state .e-acrdn-header .e-acrdn-header-content {
  color: #adb5bd;
}
.e-accordion .e-acrdn-item.e-selected.e-select.e-expand-state > .e-acrdn-header:focus {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
  background: #e7f1ff;
}
.e-accordion .e-acrdn-item.e-selected.e-select.e-expand-state > .e-acrdn-header:hover {
  background: #e7f1ff;
}
.e-accordion .e-acrdn-item.e-selected.e-select.e-expand-state > .e-acrdn-header:active {
  background: #e7f1ff;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header .e-acrdn-header-content, .e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header .e-acrdn-header-content {
  color: #212529;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-item:last-child {
  border-bottom: 1px none #dee2e6;
}
.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-header .e-acrdn-header-content {
  color: #212529;
}
.e-accordion .e-acrdn-item.e-select {
  background: none;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
}
.e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content {
  color: #212529;
  background: none;
  border-top: 0;
  border-bottom: 0;
}
.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  color: #212529;
}
.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  color: #6c757d;
}
.e-accordion .e-acrdn-item.e-select.e-selected > .e-acrdn-header:focus {
  border: 0;
}
.e-accordion .e-acrdn-item.e-expand-state.e-select:not(.e-selected) > .e-acrdn-header:focus {
  background: none;
  border: 0;
  border-color: initial;
}
.e-accordion .e-acrdn-item .e-acrdn-header:hover {
  border: 0;
  background: none;
  border-color: initial;
}
.e-accordion .e-acrdn-item .e-acrdn-header:active {
  border: 0;
  border-color: initial;
  background: #e7f1ff;
}
.e-accordion .e-acrdn-item .e-acrdn-header:focus {
  border: 0;
  background: none;
  -webkit-box-shadow: 0 0 0 4px rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 4px rgba(13, 110, 253, 0.25);
}
.e-accordion .e-acrdn-item .e-acrdn-header:focus:active .e-icons,
.e-accordion .e-acrdn-item .e-acrdn-header:focus:active .e-acrdn-header-content,
.e-accordion .e-acrdn-item .e-acrdn-header:focus:active .e-toggle-icon {
  color: #212529;
}
.e-accordion .e-acrdn-item.e-select.e-acrdn-item > .e-acrdn-header:hover .e-acrdn-header-content {
  color: #0d6efd;
}
.e-accordion .e-acrdn-item.e-select.e-acrdn-item > .e-acrdn-header:hover .e-icons {
  color: #0c63e4;
}
.e-accordion .e-acrdn-item.e-select.e-acrdn-item > .e-acrdn-header:hover .e-toggle-icon .e-icons.e-tgl-collapse-icon {
  color: #0c63e4;
}
.e-accordion .e-acrdn-item.e-select.e-acrdn-item > .e-acrdn-header:hover:focus .e-icons, .e-accordion .e-acrdn-item.e-select.e-acrdn-item > .e-acrdn-header:hover:focus .e-acrdn-header-content {
  color: #212529;
}
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
  background: #e7f1ff;
}
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header:hover .e-icons, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header:hover .e-icons {
  color: #0c63e4;
}
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header:hover .e-acrdn-header-icon .e-icons, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header:hover .e-acrdn-header-icon .e-icons {
  color: #6c757d;
}
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header:hover .e-acrdn-header-content, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header:hover .e-acrdn-header-content {
  color: #0c63e4;
}
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header:hover:focus .e-icons, .e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header:hover:focus .e-acrdn-header-content, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header:hover:focus .e-icons, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header:hover:focus .e-acrdn-header-content {
  color: #0c63e4;
}
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header .e-acrdn-header-icon, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header .e-acrdn-header-icon {
  color: #6c757d;
}
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header:hover, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header:hover {
  border-color: #dee2e6;
}
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header > .e-toggle-icon, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header > .e-toggle-icon {
  color: #0c63e4;
}
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header .e-acrdn-header-icon,
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header .e-acrdn-header-content, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header .e-acrdn-header-icon,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header .e-acrdn-header-content {
  color: #0c63e4;
}
.e-accordion .e-acrdn-item.e-select.e-item-focus {
  border-color: #dee2e6;
}
.e-accordion .e-acrdn-item.e-selected {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
}
.e-accordion .e-acrdn-item.e-selected.e-select {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
}
.e-accordion .e-acrdn-item.e-selected.e-select.e-active {
  background: #fff;
}
.e-accordion .e-acrdn-item.e-selected.e-select.e-select:last-child {
  border-bottom: 1px none #dee2e6;
}
.e-accordion .e-acrdn-item.e-selected > .e-acrdn-header > .e-acrdn-header-content {
  color: #0c63e4;
}
.e-accordion .e-acrdn-item.e-selected > .e-acrdn-header > .e-toggle-icon {
  color: #0c63e4;
}
.e-accordion .e-acrdn-item.e-selected > .e-acrdn-header:focus {
  position: relative;
  z-index: 2;
  -webkit-box-shadow: 0 0 0 4px rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 4px rgba(13, 110, 253, 0.25);
}
.e-accordion .e-acrdn-item.e-selected + .e-selected {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
}
.e-accordion .e-acrdn-item.e-selected .e-acrdn-item.e-selected.e-expand-state:hover > .e-acrdn-header .e-icons {
  color: #0d6efd;
}
.e-accordion .e-acrdn-item.e-selected .e-acrdn-item.e-selected.e-expand-state:hover > .e-acrdn-header .e-acrdn-header-content {
  color: #212529;
}
.e-accordion .e-acrdn-item.e-selected.e-select > .e-acrdn-header {
  background: #e7f1ff;
  border-radius: 0;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 0;
}
.e-accordion .e-acrdn-item.e-selected.e-select > .e-acrdn-header:focus {
  background: none;
}
.e-accordion .e-acrdn-item.e-selected.e-select > .e-acrdn-header:hover {
  border-color: #dee2e6;
}
.e-accordion .e-acrdn-item.e-selected > .e-acrdn-panel .e-acrdn-content {
  color: #212529;
}
.e-accordion .e-acrdn-item.e-selected > .e-acrdn-panel .e-acrdn-header-content {
  color: #212529;
}
.e-accordion .e-acrdn-item .e-toggle-icon {
  color: #6c757d;
}
.e-accordion .e-acrdn-item .e-acrdn-panel {
  font-size: 14px;
}

.e-bigger .e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content,
.e-accordion.e-bigger .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-size: 16px;
  vertical-align: middle;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel {
  font-size: 16px;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-panel:not(.e-nested) > .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-panel:not(.e-nested) > .e-acrdn-content {
  padding: 20px 20px 20px 28px;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-header {
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 12px 0 12px 28px;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion {
  border: 0;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 12px 0 12px 40px;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-panel .e-acrdn-content {
  padding: 24px 24px 24px 40px;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-header .e-acrdn-header-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-header .e-acrdn-header-content {
  font-size: 14px;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header {
  line-height: 24px;
  min-height: 48px;
  padding: 13px 20px;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header .e-toggle-icon,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header .e-toggle-icon {
  height: 48px;
  min-height: 48px;
  min-width: 24px;
  right: 16px;
  font-size: 20px;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header .e-acrdn-header-icon,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header .e-acrdn-header-icon {
  display: inline-block;
  padding: 0 12px 0 0;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header .e-acrdn-header-icon .e-acrdn-icons.e-icons,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header .e-acrdn-header-icon .e-acrdn-icons.e-icons {
  font-size: 20px;
}
.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content {
  padding: 16px;
  font-size: 16px;
}
.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-panel:not(.e-nested) > .e-acrdn-content,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-panel:not(.e-nested) > .e-acrdn-content {
  padding: 20px 28px 20px 20px;
}
.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-header,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content > .e-accordion > .e-acrdn-item > .e-acrdn-header {
  padding: 12px 28px 12px 0;
}
.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 12px 40px 12px 0;
}
.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-panel .e-acrdn-content {
  padding: 24px 40px 24px 24px;
}
.e-bigger .e-accordion.e-rtl .e-acrdn-item .e-acrdn-header,
.e-accordion.e-bigger.e-rtl .e-acrdn-item .e-acrdn-header {
  padding: 11px 17px;
}
.e-bigger .e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-toggle-icon,
.e-accordion.e-bigger.e-rtl .e-acrdn-item .e-acrdn-header .e-toggle-icon {
  left: 16px;
  right: auto;
}
.e-bigger .e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon,
.e-accordion.e-bigger.e-rtl .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  padding: 0 0 0 12px;
}

.e-carousel .e-previous-icon::before {
  content: "\e765";
}
.e-carousel .e-next-icon::before {
  content: "\e748";
}
.e-carousel .e-play-icon::before {
  content: "\e70c";
}
.e-carousel .e-pause-icon::before {
  content: "\e77b";
}
.e-carousel.e-rtl .e-previous-icon::before {
  content: "\e748";
}
.e-carousel.e-rtl .e-next-icon::before {
  content: "\e765";
}

.e-carousel {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
}
.e-carousel .e-carousel-items,
.e-carousel .e-carousel-slide-container {
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.e-carousel .e-carousel-items.e-swipe-start {
  cursor: pointer;
}
.e-carousel.e-partial .e-carousel-slide-container {
  padding: 0 100px;
}
@media screen and (max-width: 480px) {
  .e-carousel.e-partial .e-carousel-slide-container {
    padding: 0 75px;
  }
}
@media screen and (max-width: 320px) {
  .e-carousel.e-partial .e-carousel-slide-container {
    padding: 0 50px;
  }
}
.e-carousel .e-carousel-items {
  width: calc(var(--carousel-items-count) * 100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
}
.e-carousel .e-carousel-items .e-carousel-item {
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: calc(100% / var(--carousel-items-count));
}
.e-carousel.e-blazor-carousel .e-carousel-items {
  -webkit-transform: translateX(calc(-100% / var(--carousel-items-count) * var(--carousel-items-current)));
          transform: translateX(calc(-100% / var(--carousel-items-count) * var(--carousel-items-current)));
  -webkit-transition: -webkit-transform;
  transition: -webkit-transform;
  transition: transform;
  transition: transform, -webkit-transform;
}
.e-carousel.e-blazor-carousel.e-rtl .e-carousel-items {
  -webkit-transform: translateX(calc(100% / var(--carousel-items-count) * var(--carousel-items-current)));
          transform: translateX(calc(100% / var(--carousel-items-count) * var(--carousel-items-current)));
  -webkit-transition: -webkit-transform;
  transition: -webkit-transform;
  transition: transform;
  transition: transform, -webkit-transform;
}
.e-carousel.e-carousel-fade-animation .e-carousel-items.e-fade-in-out {
  -webkit-transition-property: none;
  transition-property: none;
  -webkit-animation: fade-in-out 0.6s ease-in-out;
          animation: fade-in-out 0.6s ease-in-out;
}
@-webkit-keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.e-carousel.e-carousel-slide-animation .e-carousel-items.e-slide {
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
}
.e-carousel.e-carousel-custom-animation:not(.e-partial) .e-carousel-item {
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  top: 0;
}
.e-carousel.e-carousel-custom-animation:not(.e-partial) .e-carousel-item.e-active {
  opacity: 1;
  pointer-events: visible;
}
.e-carousel .e-carousel-navigators {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.e-carousel .e-carousel-navigators .e-play-pause,
.e-carousel .e-carousel-navigators .e-previous,
.e-carousel .e-carousel-navigators .e-next {
  padding: 0.5em;
  pointer-events: auto;
}
.e-carousel .e-carousel-navigators .e-play-pause .e-btn,
.e-carousel .e-carousel-navigators .e-previous .e-btn,
.e-carousel .e-carousel-navigators .e-next .e-btn {
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-carousel .e-carousel-navigators .e-play-pause .e-btn:hover,
.e-carousel .e-carousel-navigators .e-previous .e-btn:hover,
.e-carousel .e-carousel-navigators .e-next .e-btn:hover {
  border-radius: 50%;
}
.e-carousel .e-carousel-navigators .e-play-pause .e-btn.e-rtl,
.e-carousel .e-carousel-navigators .e-previous .e-btn.e-rtl,
.e-carousel .e-carousel-navigators .e-next .e-btn.e-rtl {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.e-carousel .e-carousel-navigators .e-play-pause .e-btn .e-play-icon,
.e-carousel .e-carousel-navigators .e-previous .e-btn .e-play-icon,
.e-carousel .e-carousel-navigators .e-next .e-btn .e-play-icon {
  line-height: 1;
  padding-left: 3px;
}
.e-carousel .e-carousel-navigators .e-play-pause.e-hover-arrows,
.e-carousel .e-carousel-navigators .e-previous.e-hover-arrows,
.e-carousel .e-carousel-navigators .e-next.e-hover-arrows {
  display: none;
}
.e-carousel .e-carousel-indicators {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 48px;
  padding: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.e-carousel .e-carousel-indicators.e-default .e-indicator-bars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  pointer-events: auto;
}
.e-carousel .e-carousel-indicators.e-default .e-indicator-bars .e-indicator-bar {
  padding: 0;
}
.e-carousel .e-carousel-indicators.e-default .e-indicator-bars .e-indicator-bar .e-indicator {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 4px;
}
.e-carousel .e-carousel-indicators.e-default .e-indicator-bars .e-indicator-bar .e-indicator div {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  height: 5px;
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
  -webkit-transition-property: background-color, border-color;
  transition-property: background-color, border-color;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  width: 20px;
}
.e-carousel .e-carousel-indicators.e-default .e-indicator-bars .e-indicator-bar .e-indicator .e-ripple-element {
  display: none;
}
.e-carousel .e-carousel-indicators.e-dynamic {
  min-height: 36px;
}
.e-carousel .e-carousel-indicators.e-dynamic .e-indicator-bars {
  display: block;
  overflow: hidden;
  -webkit-transition: opacity 0.6s ease-in-out;
  transition: opacity 0.6s ease-in-out;
  white-space: nowrap;
  width: 80px;
}
.e-carousel .e-carousel-indicators.e-dynamic .e-indicator-bars .e-indicator-bar {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  left: calc(32px - 16px * var(--carousel-items-current));
  margin: 0 4px;
  opacity: 1;
  position: relative;
  -webkit-transform: scale(0.33);
          transform: scale(0.33);
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
  -webkit-transition-property: left, -webkit-transform;
  transition-property: left, -webkit-transform;
  transition-property: transform, left;
  transition-property: transform, left, -webkit-transform;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  white-space: nowrap;
  width: 8px;
}
.e-carousel .e-carousel-indicators.e-dynamic .e-indicator-bars .e-indicator-bar.e-active {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.e-carousel .e-carousel-indicators.e-dynamic .e-indicator-bars .e-indicator-bar.e-prev, .e-carousel .e-carousel-indicators.e-dynamic .e-indicator-bars .e-indicator-bar.e-next {
  -webkit-transform: scale(0.66);
          transform: scale(0.66);
}
.e-carousel .e-carousel-indicators.e-fraction {
  min-height: 36px;
}
.e-carousel .e-carousel-indicators.e-progress {
  min-height: 4px;
}
.e-carousel .e-carousel-indicators.e-progress .e-indicator-bars {
  height: 4px;
  width: 100%;
}
.e-carousel .e-carousel-indicators.e-progress .e-indicator-bars .e-indicator-bar {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0) scaleX(calc(var(--carousel-items-current) / var(--carousel-items-count))) scaleY(1);
          transform: translate3d(0, 0, 0) scaleX(calc(var(--carousel-items-current) / var(--carousel-items-count))) scaleY(1);
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  width: 100%;
}

.e-carousel .e-carousel-navigators .e-previous .e-btn:not(:disabled),
.e-carousel .e-carousel-navigators .e-next .e-btn:not(:disabled),
.e-carousel .e-carousel-navigators .e-play-pause .e-btn:not(:disabled) {
  background-color: transparent;
}
.e-carousel .e-carousel-navigators .e-previous .e-btn:not(:disabled) .e-btn-icon,
.e-carousel .e-carousel-navigators .e-next .e-btn:not(:disabled) .e-btn-icon,
.e-carousel .e-carousel-navigators .e-play-pause .e-btn:not(:disabled) .e-btn-icon {
  color: #fff;
}
.e-carousel .e-carousel-navigators .e-previous .e-btn:not(:disabled):active, .e-carousel .e-carousel-navigators .e-previous .e-btn:not(:disabled):focus, .e-carousel .e-carousel-navigators .e-previous .e-btn:not(:disabled):hover,
.e-carousel .e-carousel-navigators .e-next .e-btn:not(:disabled):active,
.e-carousel .e-carousel-navigators .e-next .e-btn:not(:disabled):focus,
.e-carousel .e-carousel-navigators .e-next .e-btn:not(:disabled):hover,
.e-carousel .e-carousel-navigators .e-play-pause .e-btn:not(:disabled):active,
.e-carousel .e-carousel-navigators .e-play-pause .e-btn:not(:disabled):focus,
.e-carousel .e-carousel-navigators .e-play-pause .e-btn:not(:disabled):hover {
  background-color: rgba(255, 255, 255, 0.4);
  outline: none;
}
.e-carousel .e-carousel-indicators.e-default .e-indicator-bar .e-indicator {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-carousel .e-carousel-indicators.e-default .e-indicator-bar .e-indicator div {
  background: #fff;
  border-color: #fff;
}
.e-carousel .e-carousel-indicators.e-default .e-indicator-bar .e-indicator:active, .e-carousel .e-carousel-indicators.e-default .e-indicator-bar .e-indicator:focus, .e-carousel .e-carousel-indicators.e-default .e-indicator-bar .e-indicator:hover {
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}
.e-carousel .e-carousel-indicators.e-default .e-indicator-bar .e-indicator:focus-visible {
  border-color: #000;
}
.e-carousel .e-carousel-indicators.e-default .e-indicator-bar.e-active .e-indicator div {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.e-carousel .e-carousel-indicators.e-dynamic .e-indicator-bars .e-indicator-bar {
  background-color: #fff;
}
.e-carousel .e-carousel-indicators.e-dynamic .e-indicator-bars .e-indicator-bar.e-active {
  background-color: #0d6efd;
}
.e-carousel .e-carousel-indicators.e-fraction .e-indicator-bars {
  color: #fff;
}
.e-carousel .e-carousel-indicators.e-progress .e-indicator-bars {
  background-color: rgba(13, 110, 253, 0.4);
}
.e-carousel .e-carousel-indicators.e-progress .e-indicator-bars .e-indicator-bar {
  background-color: #0d6efd;
}

.e-contextmenu-wrapper ul .e-menu-item .e-previous::before,
.e-contextmenu-container ul .e-menu-item .e-previous::before {
  content: "\e773";
}
.e-contextmenu-wrapper ul .e-menu-item .e-caret::before,
.e-contextmenu-container ul .e-menu-item .e-caret::before {
  content: "\e748";
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-caret::before,
.e-rtl.e-contextmenu-container .e-menu-item .e-caret::before {
  content: "\e765";
}

.e-contextmenu-wrapper ul,
.e-contextmenu-container ul {
  font-weight: 400;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 14px;
  padding: 0;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
  display: none;
  min-width: 120px;
  position: absolute;
}
.e-contextmenu-wrapper ul.e-ul, .e-contextmenu-wrapper ul.e-ul *,
.e-contextmenu-container ul.e-ul,
.e-contextmenu-container ul.e-ul * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.e-contextmenu-wrapper ul.e-ul:focus, .e-contextmenu-wrapper ul.e-ul *:focus,
.e-contextmenu-container ul.e-ul:focus,
.e-contextmenu-container ul.e-ul *:focus {
  outline: none;
}
.e-contextmenu-wrapper ul.e-contextmenu,
.e-contextmenu-container ul.e-contextmenu {
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
  padding: 4px 0;
}
.e-contextmenu-wrapper ul.e-ul,
.e-contextmenu-container ul.e-ul {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.e-contextmenu-wrapper ul .e-menu-item,
.e-contextmenu-container ul .e-menu-item {
  cursor: pointer;
  position: relative;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
}
.e-contextmenu-wrapper ul .e-menu-item.e-menu-hide,
.e-contextmenu-container ul .e-menu-item.e-menu-hide {
  display: none;
}
.e-contextmenu-wrapper ul .e-menu-item.e-menu-header,
.e-contextmenu-container ul .e-menu-item.e-menu-header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.e-contextmenu-wrapper ul .e-menu-item .e-menu-url,
.e-contextmenu-container ul .e-menu-item .e-menu-url {
  text-decoration: none;
}
.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon,
.e-contextmenu-container ul .e-menu-item .e-menu-icon {
  display: inline-block;
  vertical-align: middle;
}
.e-contextmenu-wrapper ul .e-menu-item.e-separator,
.e-contextmenu-container ul .e-menu-item.e-separator {
  cursor: auto;
  line-height: normal;
  pointer-events: none;
}
.e-contextmenu-wrapper ul .e-menu-item .e-menu-url,
.e-contextmenu-container ul .e-menu-item .e-menu-url {
  display: inline-block;
  min-width: 120px;
}
.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon,
.e-contextmenu-container ul .e-menu-item .e-menu-icon {
  font-size: 18px;
  line-height: 30px;
  margin-right: 8px;
  width: 1em;
}
.e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-contextmenu-container ul .e-menu-item .e-caret {
  line-height: 30px;
  margin-left: 16px;
  margin-right: 0;
  position: absolute;
  right: 7px;
}
.e-contextmenu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-contextmenu-container ul .e-menu-item.e-menu-caret-icon {
  padding-right: 36px;
}
.e-contextmenu-wrapper ul .e-menu-item.e-separator,
.e-contextmenu-container ul .e-menu-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
  margin: 4px 0;
}
.e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-contextmenu-container ul .e-menu-item .e-caret {
  font-size: 16px;
}
.e-contextmenu-wrapper ul .e-menu-item .e-previous,
.e-contextmenu-container ul .e-menu-item .e-previous {
  margin-right: 8px;
}
.e-contextmenu-wrapper ul .e-menu-item.e-disabled,
.e-contextmenu-container ul .e-menu-item.e-disabled {
  cursor: auto;
  pointer-events: none;
}
.e-contextmenu-wrapper ul .e-menu-item .e-checkbox-wrapper,
.e-contextmenu-container ul .e-menu-item .e-checkbox-wrapper {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.e-contextmenu-wrapper.e-sfcontextmenu,
.e-contextmenu-container.e-sfcontextmenu {
  position: absolute;
}
.e-contextmenu-wrapper.e-sfcontextmenu .e-menu-vscroll,
.e-contextmenu-container.e-sfcontextmenu .e-menu-vscroll {
  position: absolute;
}
.e-contextmenu-wrapper.e-sfcontextmenu ul,
.e-contextmenu-container.e-sfcontextmenu ul {
  white-space: nowrap;
}
.e-contextmenu-wrapper.e-sfcontextmenu ul.e-transparent,
.e-contextmenu-container.e-sfcontextmenu ul.e-transparent {
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  height: 1px;
  left: 0;
  min-width: 0;
  padding: 0;
  top: 0;
  width: 0;
}
.e-contextmenu-wrapper.e-sfcontextmenu ul.e-transparent .e-menu-item,
.e-contextmenu-container.e-sfcontextmenu ul.e-transparent .e-menu-item {
  height: 0;
  padding: 0;
}
.e-contextmenu-wrapper.e-sfcontextmenu ul.e-transparent .e-menu-item.e-separator,
.e-contextmenu-container.e-sfcontextmenu ul.e-transparent .e-menu-item.e-separator {
  border: 0;
  margin: 0;
}
.e-contextmenu-wrapper:not(.e-menu-container) .e-menu-vscroll.e-vscroll,
.e-contextmenu-container:not(.e-menu-container) .e-menu-vscroll.e-vscroll {
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
}
.e-contextmenu-wrapper:not(.e-menu-container) .e-menu-vscroll.e-vscroll .e-vscroll-bar,
.e-contextmenu-container:not(.e-menu-container) .e-menu-vscroll.e-vscroll .e-vscroll-bar {
  height: 100%;
  width: inherit;
}
.e-contextmenu-wrapper:not(.e-menu-container) .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-contextmenu-container:not(.e-menu-container) .e-menu-vscroll.e-vscroll .e-scroll-nav {
  height: 16px;
}
.e-contextmenu-wrapper:not(.e-menu-container) .e-menu-vscroll.e-vscroll:not(.e-scroll-device),
.e-contextmenu-container:not(.e-menu-container) .e-menu-vscroll.e-vscroll:not(.e-scroll-device) {
  padding: 16px 0;
}
.e-contextmenu-wrapper:not(.e-menu-container) .e-menu-vscroll.e-vscroll ul,
.e-contextmenu-container:not(.e-menu-container) .e-menu-vscroll.e-vscroll ul {
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-top: -12px;
  width: inherit;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-menu-icon,
.e-rtl.e-contextmenu-container .e-menu-item .e-menu-icon {
  margin-right: 0;
}
.e-rtl.e-contextmenu-wrapper .e-menu-item .e-caret,
.e-rtl.e-contextmenu-container .e-menu-item .e-caret {
  margin-left: 0;
  margin-right: 16px;
  right: auto;
}
.e-rtl.e-contextmenu-wrapper .e-menu-item .e-menu-icon,
.e-rtl.e-contextmenu-container .e-menu-item .e-menu-icon {
  margin-left: 8px;
}
.e-rtl.e-contextmenu-wrapper .e-menu-item .e-caret,
.e-rtl.e-contextmenu-container .e-menu-item .e-caret {
  left: 7px;
}
.e-rtl.e-contextmenu-wrapper .e-menu-item.e-menu-caret-icon,
.e-rtl.e-contextmenu-container .e-menu-item.e-menu-caret-icon {
  padding-left: 36px;
  padding-right: 12px;
}
.e-rtl.e-contextmenu-wrapper .e-menu-item.e-blankicon,
.e-rtl.e-contextmenu-container .e-menu-item.e-blankicon {
  padding-left: 14px;
}
.e-rtl.e-contextmenu-wrapper .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-rtl.e-contextmenu-container .e-menu-item.e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

.e-contextmenu-wrapper .e-menu-item,
.e-contextmenu-container .e-menu-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-contextmenu-wrapper ul,
.e-contextmenu-container ul {
  background-color: #fff;
  color: #212529;
}
.e-contextmenu-wrapper ul .e-menu-item.e-menu-header,
.e-contextmenu-container ul .e-menu-item.e-menu-header {
  border-bottom-color: #dee2e6;
}
.e-contextmenu-wrapper ul .e-menu-item .e-menu-url,
.e-contextmenu-container ul .e-menu-item .e-menu-url {
  color: #212529;
}
.e-contextmenu-wrapper ul .e-menu-item.e-focused,
.e-contextmenu-container ul .e-menu-item.e-focused {
  background-color: #e9ecef;
  color: #212529;
  outline: 0 solid #dee2e6;
  outline-offset: 0;
}
.e-contextmenu-wrapper ul .e-menu-item.e-selected,
.e-contextmenu-container ul .e-menu-item.e-selected {
  background-color: #0d6efd;
  color: #fff;
  outline: 0 solid #0d6efd;
  outline-offset: 0;
}
.e-contextmenu-wrapper ul .e-disabled,
.e-contextmenu-container ul .e-disabled {
  color: rgba(33, 37, 41, 0.65);
  opacity: 0.5;
}
.e-contextmenu-wrapper ul .e-disabled .e-menu-icon,
.e-contextmenu-container ul .e-disabled .e-menu-icon {
  color: rgba(108, 117, 125, 0.65);
}
.e-contextmenu-wrapper ul .e-disabled .e-caret,
.e-contextmenu-container ul .e-disabled .e-caret {
  color: rgba(108, 117, 125, 0.65);
}
.e-contextmenu-wrapper ul .e-disabled .e-menu-url,
.e-contextmenu-container ul .e-disabled .e-menu-url {
  color: rgba(33, 37, 41, 0.65);
}
.e-contextmenu-wrapper ul .e-separator,
.e-contextmenu-container ul .e-separator {
  border-bottom-color: #dee2e6;
}

.e-contextmenu-wrapper:not(.e-menu-container) .e-menu-vscroll.e-vscroll,
.e-contextmenu-container:not(.e-menu-container) .e-menu-vscroll.e-vscroll {
  background-color: inherit;
  border: 1px solid #dee2e6;
}
.e-contextmenu-wrapper:not(.e-menu-container) .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-contextmenu-container:not(.e-menu-container) .e-menu-vscroll.e-vscroll .e-scroll-nav {
  border-color: #dee2e6;
}

.e-bigger .e-contextmenu-wrapper ul,
.e-bigger.e-contextmenu-wrapper ul,
.e-bigger .e-contextmenu-container ul,
.e-bigger.e-contextmenu-container ul {
  font-size: 16px;
  padding: 4px 0;
  white-space: nowrap;
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
  max-width: 280px;
  min-width: 112px;
  padding: 8px 0;
}
.e-bigger .e-contextmenu-wrapper ul .e-menu-item,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item,
.e-bigger .e-contextmenu-container ul .e-menu-item,
.e-bigger.e-contextmenu-container ul .e-menu-item {
  height: 36px;
  line-height: 36px;
}
.e-bigger .e-contextmenu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger .e-contextmenu-container ul .e-menu-item .e-menu-icon,
.e-bigger.e-contextmenu-container ul .e-menu-item .e-menu-icon {
  font-size: 22px;
  line-height: 36px;
}
.e-bigger .e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-bigger .e-contextmenu-container ul .e-menu-item .e-caret,
.e-bigger.e-contextmenu-container ul .e-menu-item .e-caret {
  line-height: 36px;
}
.e-bigger .e-contextmenu-wrapper ul .e-menu-item.e-separator,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item.e-separator,
.e-bigger .e-contextmenu-container ul .e-menu-item.e-separator,
.e-bigger.e-contextmenu-container ul .e-menu-item.e-separator {
  height: auto;
  line-height: normal;
}
.e-bigger .e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-bigger .e-contextmenu-container ul .e-menu-item .e-caret,
.e-bigger.e-contextmenu-container ul .e-menu-item .e-caret {
  font-size: 16px;
}

.e-bigger .e-contextmenu-wrapper.e-rtl ul .e-blankicon,
.e-bigger.e-contextmenu-wrapper.e-rtl ul .e-blankicon,
.e-bigger .e-contextmenu-container.e-rtl ul .e-blankicon,
.e-bigger.e-contextmenu-container.e-rtl ul .e-blankicon {
  padding-left: 14px;
}
.e-bigger .e-contextmenu-wrapper.e-rtl ul .e-blankicon.e-menu-caret-icon,
.e-bigger.e-contextmenu-wrapper.e-rtl ul .e-blankicon.e-menu-caret-icon,
.e-bigger .e-contextmenu-container.e-rtl ul .e-blankicon.e-menu-caret-icon,
.e-bigger.e-contextmenu-container.e-rtl ul .e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

.e-tab .e-tab-header .e-close-icon::before {
  content: "\e7e7";
  position: relative;
}
@media screen and (max-width: 480px) {
  .e-tab.e-vertical-icon .e-tab-header .e-popup-up-icon::before {
    content: "\e776";
  }
  .e-tab.e-vertical-icon .e-tab-header .e-popup-down-icon::before {
    content: "\e729";
  }
}
.e-tab.e-vertical-tab .e-tab-header .e-popup-up-icon::before {
  content: "More";
}
.e-tab.e-vertical-tab .e-tab-header .e-popup-up-icon::after {
  content: "\e776";
}
.e-tab.e-vertical-tab .e-tab-header .e-popup-down-icon::before {
  content: "More";
}
.e-tab.e-vertical-tab .e-tab-header .e-popup-down-icon::after {
  content: "\e729";
}
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-up-icon::before {
  content: "";
}
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-up-icon::after {
  content: "\e776";
}
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-down-icon::before {
  content: "";
}
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-down-icon::after {
  content: "\e729";
}

.e-tab-clone-element .e-close-icon::before {
  content: "\e7e7";
  position: relative;
}

.e-tab {
  display: block;
  position: relative;
}
.e-tab.e-hidden {
  display: none;
}
.e-tab.e-fill-mode .e-content, .e-tab.e-fill-mode .e-content .e-item, .e-tab.e-fill-mode .e-content .e-item > div, .e-tab.e-fill-mode .e-content .e-item > div > .e-blazor-template {
  height: 100%;
}
.e-tab .e-tab-header {
  height: 32px;
  min-height: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header {
    height: 42px;
    min-height: 42px;
  }
}
.e-tab .e-tab-header::before {
  content: "";
  position: absolute;
  display: block;
}
.e-tab .e-tab-header:not(.e-vertical)::before {
  bottom: 0;
  top: 0;
  width: 100%;
}
.e-tab .e-tab-header .e-toolbar-items {
  height: auto;
  margin: 0;
  min-height: 32px;
  position: relative;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-items {
    min-height: 42px;
  }
}
.e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child, .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}
.e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0 2px 0 0;
  }
}
.e-tab .e-tab-header .e-toolbar-items .e-toolbar-item.e-active {
  position: unset;
}
.e-tab .e-tab-header .e-toolbar-items .e-toolbar-item.e-active:hover::before {
  left: unset;
  right: unset;
}
.e-tab .e-tab-header .e-toolbar-items .e-toolbar-item.e-active::before {
  background-color: unset;
  bottom: unset;
  content: unset;
  height: unset;
  left: unset;
  position: unset;
  right: unset;
  -webkit-transition: unset;
  transition: unset;
}
.e-tab .e-tab-header .e-toolbar-items .e-toolbar-item.e-active::after {
  color: unset;
  content: unset;
  display: unset;
  font-weight: 400;
  height: unset;
  overflow: unset;
  visibility: unset;
}
.e-tab .e-tab-header .e-toolbar-items.e-hscroll.e-scroll-device {
  padding: 0;
}
.e-tab .e-tab-header .e-toolbar-items.e-hscroll.e-scroll-device .e-scroll-right-nav {
  display: none;
}
.e-tab .e-tab-header .e-toolbar-items.e-hscroll .e-hscroll-bar {
  padding: 0;
}
.e-tab .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav {
  width: 32px;
  border-radius: 4px;
}
.e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0 2px 0 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0 2px 0 0;
  }
}
.e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0 2px 0 0;
}
.e-tab .e-tab-header .e-hscroll-bar {
  overflow: hidden;
}
.e-tab .e-tab-header .e-indicator {
  display: none;
  position: absolute;
}
.e-tab .e-tab-header .e-indicator.e-hidden {
  display: none;
}
.e-tab .e-tab-header:not(.e-vertical) .e-indicator {
  bottom: 0;
  height: 2px;
  left: 0;
  right: 0;
  border-radius: 0;
  -webkit-transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1);
}
.e-tab .e-tab-header .e-toolbar-item.e-hidden {
  display: none;
}
.e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 32px;
  margin: 0;
  min-height: 32px;
  min-width: auto;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 42px;
    min-height: 42px;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 32px;
  padding: 0 12px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: 42px;
    padding: 0 15px;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-text-wrap {
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-text-wrap {
    height: 42px;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  display: inherit;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  -ms-flex-item-align: auto;
      align-self: auto;
  margin-bottom: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-text {
    font-size: 16px;
  }
}
.e-tab .e-tab-header .e-toolbar-item.e-active {
  margin: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-active {
    margin: 0 2px 0 0;
  }
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 32px;
  margin-top: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    height: 42px;
  }
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  font-weight: 400;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon::before {
  top: 1px;
  left: 5px;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-icon-top.e-tab-icon::before,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-icon-bottom.e-tab-icon::before {
  left: 0;
}
.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 32px;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
    height: 42px;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
  display: none;
  margin: 0 0 0 6px;
  min-width: 12px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
    margin: 0 0 0 8px;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-close-icon,
.e-tab .e-tab-header .e-toolbar-item .e-close-icon::before {
  font-size: 10px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-close-icon,
  .e-tab .e-tab-header .e-toolbar-item .e-close-icon::before {
    font-size: 12px;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-close-icon::before {
  top: 1px;
}
.e-tab .e-tab-header .e-toolbar-item .e-icons.e-tab-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 24px;
  min-width: 24px;
  width: 24px;
  line-height: 24px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-icons.e-tab-icon {
    height: 20px;
    width: 20px;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-tab-icon,
.e-tab .e-tab-header .e-toolbar-item .e-tab-icon::before {
  font-size: 14px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-icon,
  .e-tab .e-tab-header .e-toolbar-item .e-tab-icon::before {
    font-size: 20px;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-tab-icon::before {
  position: relative;
  top: 1px;
  left: 5px;
}
.e-tab .e-tab-header .e-toolbar-item .e-icon-top.e-tab-icon::before,
.e-tab .e-tab-header .e-toolbar-item .e-icon-bottom.e-tab-icon::before {
  left: 0;
}
.e-tab .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 15px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap {
    padding: 0 16px;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-icon-right {
  margin: 0 0 0 8px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-icon-right {
    margin: 0;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 8px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
    margin: 0 0 0 10px;
  }
}
.e-tab .e-tab-header .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-item.e-ibottom {
  height: 62px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-item.e-ibottom {
    height: 62px;
  }
}
.e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 62px;
  position: relative;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
    height: 62px;
  }
}
.e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-tab .e-tab-header .e-toolbar-item.e-itop .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-text-wrap {
  display: block;
  height: auto;
}
.e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap .e-text-wrap {
  height: auto;
}
.e-tab .e-tab-header .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon {
  position: absolute;
  right: 15px;
  top: calc(50% - 6px);
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon {
    right: 12px;
  }
}
.e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-text {
  margin: 8px 0 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-text {
    margin: 8px 0 0;
  }
}
.e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0 0 8px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0 0 10px;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-tab-icon.e-icon-top,
.e-tab .e-tab-header .e-toolbar-item .e-tab-icon.e-icon-bottom {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
}
.e-tab .e-tab-header .e-toolbar-pop {
  overflow-y: auto;
  padding: 5px 0;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item {
  height: 26px;
  min-height: 26px;
  min-width: auto;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item {
    height: 50px;
    min-height: 50px;
  }
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  margin: 0;
  padding: 0;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
  height: 26px;
  padding: 0 20px;
  text-align: initial;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
    height: 50px;
    padding: 0 24px;
  }
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-text-wrap {
  height: 26px;
  width: 100%;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon {
  margin: 0 0 0 8px;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon::before {
  top: 0;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style="display:block"] {
  padding-right: 10px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style="display:block"] {
    padding-right: 0;
  }
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom {
  height: 40px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom {
    height: 50px;
  }
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  min-height: 40px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
    min-height: 50px;
  }
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 40px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
    height: 50px;
  }
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-text-wrap, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-text-wrap {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: auto;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  display: block;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 0 0 10px;
  padding-left: 0;
  padding-right: 10px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 0 0 0 12px;
    padding-left: 0;
    padding-right: 0;
  }
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  right: 20px;
  top: initial;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-icon.e-icon-top,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-icon.e-icon-bottom {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.e-tab .e-tab-header.e-close-show .e-icons.e-close-icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-close-show .e-icons.e-close-icon {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-close-show .e-toolbar-item.e-active .e-close-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}
.e-tab .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-right: 20px;
}
.e-tab .e-tab-header .e-scroll-nav {
  height: 32px;
  min-height: 32px;
  min-width: auto;
  width: auto;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-scroll-nav {
    height: 42px;
    min-height: 42px;
  }
}
.e-tab .e-tab-header .e-scroll-nav.e-scroll-left-nav {
  padding: 0;
}
.e-tab .e-tab-header .e-scroll-nav.e-scroll-right-nav {
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-scroll-nav.e-scroll-right-nav {
    padding: 0;
  }
}
.e-tab .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  font-size: 10px;
  line-height: 32px;
  position: relative;
  top: 0;
  vertical-align: initial;
}
.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow {
  font-size: 10px;
  height: 32px;
  line-height: 32px;
  width: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-scroll-nav .e-nav-arrow {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    width: 38px;
  }
}
.e-tab .e-tab-header .e-hor-nav {
  height: 32px;
  min-height: 32px;
  min-width: auto;
  padding: 0;
  width: auto;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav {
    height: 42px;
    min-height: 42px;
    padding: 0;
  }
}
.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
  font-size: 10px;
  height: 32px;
  line-height: 32px;
  width: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    width: 38px;
  }
}
.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before {
  font-size: 10px;
  line-height: 32px;
  position: relative;
  top: 0;
  vertical-align: initial;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 16px;
  }
}
.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover {
  line-height: 32px;
}
.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before {
  line-height: 32px;
  top: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before {
    line-height: 43px;
  }
}
.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: none;
  transition: none;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon {
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
  }
}
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: none;
  transition: none;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
  }
}
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  line-height: 32px;
}
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before {
  line-height: 32px;
  top: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
  .e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before {
    line-height: 43px;
  }
}
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon:hover {
  line-height: 32px;
  top: -0.5px;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items .e-toolbar-item.e-active {
  position: unset;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items .e-toolbar-item.e-active:hover::before {
  left: unset;
  right: unset;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items .e-toolbar-item.e-active::before {
  background-color: unset;
  content: unset;
  height: unset;
  left: unset;
  position: unset;
  right: unset;
  top: unset;
  -webkit-transition: unset;
  transition: unset;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items .e-toolbar-item.e-active::after {
  color: unset;
  content: unset;
  display: unset;
  font-weight: 400;
  height: unset;
  overflow: unset;
  visibility: unset;
}
.e-tab .e-tab-header.e-horizontal-bottom::before {
  bottom: auto;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-hscroll-bar {
  margin-top: -1px;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0 2px 0 0;
  padding-bottom: 0;
  padding-top: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0 2px 0 0;
  }
}
.e-tab .e-tab-header.e-horizontal-bottom .e-indicator {
  bottom: auto;
  top: 0;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin: 0 2px 0 0;
  padding-bottom: 0;
  padding-top: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
    margin: 0 2px 0 0;
  }
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 32px;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
    height: 42px;
  }
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap::before, .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:not(.e-separator):last-child .e-text-wrap::before {
  top: unset;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-tab-wrap:focus .e-close-icon::before {
  top: 0;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap, .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap {
  height: initial;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap, .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap {
  height: initial;
  padding: 0;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap::before, .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap::before {
  bottom: auto;
  top: 0;
}
.e-tab .e-tab-header.e-reorder-active-item .e-toolbar-pop .e-toolbar-item.e-active .e-text-wrap::before {
  display: none;
}
.e-tab .e-tab-header.e-vertical {
  max-width: 150px;
  z-index: 1;
}
.e-tab .e-tab-header.e-vertical::before {
  bottom: 0;
  height: 100%;
  left: 0;
  top: 0;
}
.e-tab .e-tab-header.e-vertical[style*="overflow: hidden"]::before {
  bottom: 15px;
  height: auto;
  top: 15px;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-vertical[style*="overflow: hidden"]::before {
    bottom: 0;
    top: 0;
  }
}
.e-tab .e-tab-header.e-vertical .e-indicator {
  display: none;
  -webkit-transition: top 0.125s cubic-bezier(0.35, 0, 0.25, 1), bottom 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  transition: top 0.125s cubic-bezier(0.35, 0, 0.25, 1), bottom 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  width: 2px;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: normal;
      -ms-flex-align: normal;
          align-items: normal;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-items.e-vscroll:not(.e-scroll-device) {
  padding: 16px 0;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item:last-child {
  margin: 0;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator) {
  margin: 0;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-wrap {
  padding: 0 12px;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-text-wrap {
  position: relative;
  width: 100%;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-text,
.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-icon::before {
  text-align: center;
  width: 100%;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-active .e-text-wrap::before {
  display: none;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-ileft.e-icon {
  min-height: 32px;
  min-width: 33px;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-ileft.e-icon .e-tab-icon {
  margin: 0;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon {
  right: -15px;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-pop {
  top: initial !important; /* stylelint-disable-line declaration-no-important */
}
.e-tab .e-tab-header.e-vertical.e-vertical-left {
  float: left;
}
.e-tab .e-tab-header.e-vertical.e-vertical-left::before {
  right: 0;
}
.e-tab .e-tab-header.e-vertical.e-vertical-left .e-indicator {
  left: auto;
  right: 0;
}
.e-tab .e-tab-header.e-vertical.e-vertical-left .e-toolbar-items .e-toolbar-item.e-active:hover::before {
  bottom: unset;
  height: unset;
}
.e-tab .e-tab-header.e-vertical.e-vertical-left .e-toolbar-items .e-toolbar-item.e-active::before {
  background-color: unset;
  bottom: unset;
  content: unset;
  height: unset;
  left: unset;
  position: unset;
  right: unset;
  -webkit-transition: unset;
  transition: unset;
  width: unset;
}
.e-tab .e-tab-header.e-vertical.e-vertical-left .e-toolbar-items .e-toolbar-item.e-active::after {
  color: unset;
  content: unset;
  display: unset;
  font-weight: 400;
  height: unset;
  overflow: unset;
  visibility: unset;
}
.e-tab .e-tab-header.e-vertical.e-vertical-right {
  float: right;
}
.e-tab .e-tab-header.e-vertical.e-vertical-right::before {
  right: auto;
}
.e-tab .e-tab-header.e-vertical.e-vertical-right .e-indicator {
  left: 0;
  right: auto;
}
.e-tab .e-tab-header.e-vertical.e-vertical-right .e-tab-wrap {
  text-align: right;
}
.e-tab .e-tab-header.e-vertical.e-vertical-right .e-toolbar-pop .e-tab-text {
  width: auto;
}
.e-tab .e-tab-header.e-vertical.e-vertical-right .e-toolbar-items .e-toolbar-item.e-active:hover::before {
  bottom: unset;
  height: unset;
}
.e-tab .e-tab-header.e-vertical.e-vertical-right .e-toolbar-items .e-toolbar-item.e-active::before {
  background-color: unset;
  bottom: unset;
  content: unset;
  height: unset;
  left: unset;
  position: unset;
  right: unset;
  -webkit-transition: unset;
  transition: unset;
  width: unset;
}
.e-tab .e-tab-header.e-vertical.e-vertical-right .e-toolbar-items .e-toolbar-item.e-active::after {
  color: unset;
  content: unset;
  display: unset;
  font-weight: 400;
  height: unset;
  overflow: unset;
  visibility: unset;
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav {
  height: 16px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 16px;
  width: 100%;
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  margin: 0 auto;
  width: 16px;
}
.e-tab .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 12px;
  width: 100%;
}
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon {
  height: 100%;
  -webkit-transform: none;
          transform: none;
  -webkit-transition: none;
  transition: none;
  width: 100%;
}
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  float: left;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  text-transform: capitalize;
  -webkit-transform: none;
          transform: none;
  -webkit-transition: none;
  transition: none;
}
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  float: left;
  font-size: 12px;
  line-height: 36px;
  margin: 0 0 0 8px;
  vertical-align: initial;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
  .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
    font-size: 16px;
  }
}
.e-tab .e-tab-header.e-vertical .e-scroll-device .e-scroll-nav,
.e-tab .e-tab-header.e-vertical .e-scroll-device .e-scroll-overlay {
  display: none;
}
.e-tab .e-tab-header.e-vertical.e-toolpop .e-toolbar-items {
  height: auto;
}
.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}
.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator) {
  margin: 0;
}
.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator).e-itop .e-close-icon, .e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon {
  left: -15px;
}
.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 12px;
}
.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  float: right;
}
.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  float: right;
  margin: 0 8px 0 0;
}
.e-tab .e-content {
  position: relative;
}
.e-tab .e-content .e-item.e-view {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.e-tab .e-content > .e-item {
  display: none;
}
.e-tab .e-content > .e-item.e-active {
  background: inherit;
  display: block;
}
.e-tab .e-content.e-progress {
  overflow: hidden;
}
.e-tab.e-vertical-tab .e-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.e-tab.e-vertical-tab .e-content .e-item.e-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
}
.e-tab.e-vertical-tab .e-content .e-item,
.e-tab.e-vertical-tab .e-content .e-item > :first-child {
  width: 100%;
}
.e-tab.e-vertical-icon > .e-tab-header {
  height: 62px;
  min-height: 62px;
}
.e-tab.e-vertical-icon > .e-tab-header.e-reorder-active-item .e-toolbar-pop .e-toolbar-item.e-active .e-tab-wrap::before,
.e-tab.e-vertical-icon > .e-tab-header.e-reorder-active-item .e-toolbar-pop .e-toolbar-item:not(.e-separator):last-child.e-active .e-tab-wrap::before {
  display: none;
}
.e-tab.e-vertical-icon > .e-tab-header > .e-toolbar-items {
  height: 62px;
}
.e-tab.e-vertical-icon > .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-vertical-icon > .e-tab-header .e-toolbar-item:not(.e-separator):last-child.e-active .e-text-wrap {
  position: unset;
}
.e-tab.e-vertical-icon > .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before,
.e-tab.e-vertical-icon > .e-tab-header .e-toolbar-item:not(.e-separator):last-child.e-active .e-text-wrap::before {
  border: unset;
  content: unset;
  display: none;
  position: unset;
  top: unset;
  width: unset;
}
.e-tab.e-vertical-icon > .e-tab-header .e-toolbar-item.e-active .e-tab-wrap::before,
.e-tab.e-vertical-icon > .e-tab-header .e-toolbar-item:not(.e-separator):last-child.e-active .e-tab-wrap::before {
  content: unset;
  display: unset;
  position: unset;
  top: unset;
  width: unset;
  border: unset;
}
.e-tab.e-vertical-icon > .e-tab-header .e-scroll-nav {
  height: 62px;
}
.e-tab.e-vertical-icon > .e-tab-header .e-hor-nav {
  height: 62px;
}
@media screen and (max-width: 480px) {
  .e-tab.e-vertical-icon > .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab.e-vertical-icon > .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 12px;
  }
}
.e-tab.e-vertical-icon > .e-tab-header.e-vertical .e-indicator {
  width: 2px;
}
.e-tab.e-vertical-icon > .e-tab-header.e-vertical .e-toolbar-item.e-active .e-tab-wrap::before,
.e-tab.e-vertical-icon > .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator):last-child.e-active .e-tab-wrap::before {
  display: none;
}
.e-tab.e-vertical-icon > .e-tab-header.e-vertical .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-vertical-icon > .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator):last-child.e-active .e-text-wrap {
  position: relative;
}
.e-tab.e-vertical-icon > .e-tab-header.e-vertical .e-scroll-nav {
  height: 16px;
}
.e-tab.e-vertical-icon > .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap::before,
.e-tab.e-vertical-icon > .e-tab-header.e-horizontal-bottom .e-toolbar-item:not(.e-separator):last-child.e-active .e-tab-wrap::before {
  top: unset;
}
.e-tab.e-vertical-icon.e-rtl .e-tab-header .e-toolbar-item.e-active {
  margin: 0 0 0 2px;
}
.e-tab.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}
.e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 32px;
}
.e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 32px;
}
.e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus, .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 62px;
}
.e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus {
  height: 26px;
}
.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 26px;
}
.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap:focus, .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 32px;
}
.e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap {
  height: 32px;
  padding: 0;
}
.e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-tab.e-template .e-tab-header .e-toolbar-items .e-toolbar-item .e-tab-wrap .e-text-wrap .e-tab-text > div,
.e-tab.e-template .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-text-wrap .e-tab-text > div {
  display: inherit;
}
.e-tab.e-template .e-content > .e-item {
  display: none;
}
.e-tab.e-template .e-content > .e-item.e-active {
  background: inherit;
  display: block;
}
.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  display: block;
  line-height: 15px;
}
.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  display: block;
  line-height: 14px;
}
.e-tab.e-ie .e-tab-header .e-popup-up-icon,
.e-tab.e-ie .e-tab-header .e-popup-down-icon {
  -webkit-transform: none;
          transform: none;
  -webkit-transition: none;
  transition: none;
}
.e-tab.e-ie .e-tab-header .e-popup-up-icon::before {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: none;
  transition: none;
}
@media screen and (max-width: 480px) {
  .e-tab.e-ie .e-tab-header .e-popup-up-icon::before {
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
  }
}
.e-tab.e-ie .e-tab-header .e-popup-down-icon::before {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: none;
  transition: none;
}
@media screen and (max-width: 480px) {
  .e-tab.e-ie .e-tab-header .e-popup-down-icon::before {
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
  }
}
.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  display: block;
  line-height: 14px;
  position: relative;
}
.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  display: block;
  line-height: 11px;
  position: relative;
}
.e-tab.e-edge .e-tab-header .e-popup-up-icon {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: none;
  transition: none;
}
@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-up-icon {
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
  }
}
.e-tab.e-edge .e-tab-header .e-popup-down-icon {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: none;
  transition: none;
}
@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-down-icon {
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
  }
}
@media screen and (max-width: 480px) and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-down-icon {
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
  }
}
.e-tab.e-edge .e-tab-header .e-popup-up-icon::before {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: none;
  transition: none;
}
@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-up-icon::before {
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
  }
}
.e-tab.e-edge .e-tab-header .e-popup-down-icon::before {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: none;
  transition: none;
}
@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-down-icon::before {
    -webkit-transform: none;
            transform: none;
    -webkit-transition: none;
    transition: none;
  }
}
.e-tab.e-safari .e-tab-header .e-close-icon::before {
  top: -1px;
}
.e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-up-icon::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-down-icon::before {
  top: 0;
}
.e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-left-arrow::before,
.e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-right-arrow::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  top: 0;
}
.e-tab.e-disable {
  pointer-events: none;
}
.e-tab.e-fill .e-tab-header {
  height: 32px;
  min-height: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header {
    height: 42px;
    min-height: 42px;
  }
}
.e-tab.e-fill .e-tab-header .e-indicator {
  display: none;
}
.e-tab.e-fill .e-tab-header .e-toolbar-items {
  height: auto;
  min-height: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-items {
    min-height: 42px;
  }
}
.e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child, .e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 32px;
  margin: 0 2px 0 0;
  min-height: 32px;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 42px;
    min-height: 42px;
  }
}
.e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-itop, .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom {
  height: 62px;
  min-height: 62px;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 32px;
  padding: 0 15px;
}
@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: 42px;
    padding: 0 15px;
  }
}
.e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
  height: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
    height: 42px;
  }
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    height: 42px;
  }
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  font-weight: 400;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
    height: 42px;
  }
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-tab.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 62px;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-itop .e-text-wrap, .e-tab.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-text-wrap {
  height: auto;
}
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  height: auto;
}
.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-active, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  padding: 0;
}
.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item.e-itop, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-itop, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-itop {
  padding-top: 0;
}
.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin-right: 0;
  padding: 0;
}
.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
    height: 42px;
  }
}
.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: 44px;
}
.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap {
  height: auto;
}
.e-tab.e-fill .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}
.e-tab.e-fill .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 12px;
}
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 32px;
}
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 32px;
}
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap {
  height: 32px;
}
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus, .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 62px;
}
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
@media screen and (max-width: 480px) {
  .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
    height: auto;
  }
}
.e-tab.e-fill.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}
.e-tab.e-fill.e-vertical-icon > .e-tab-header {
  height: 60px;
  min-height: 60px;
}
.e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}
.e-tab.e-background .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
  padding: 0;
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
  padding: 0 15px;
}
@media screen and (max-width: 480px) {
  .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 15px;
  }
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  font-weight: 400;
}
.e-tab.e-background .e-tab-header.e-vertical .e-indicator {
  display: none;
}
.e-tab.e-background .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 12px;
}
.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-active,
.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  padding: 0;
}
.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin: 0;
  padding: 0;
}
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item.e-active .e-text-wrap {
  height: 26px;
}
.e-tab.e-background.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-right {
  margin: 0;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text {
  margin: 0 8px 0 0;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon {
  margin: 0 8px 0 0;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-itop .e-close-icon, .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon {
  left: 15px;
  right: auto;
}
.e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-left: 20px;
  margin-right: 0;
}
.e-tab.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item {
  margin: 0;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
  padding-left: 10px;
  padding-right: 0;
}
@media screen and (max-width: 480px) {
  .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
    padding-left: 0;
  }
}
.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-icons.e-close-icon {
  margin: 0;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon {
  left: 16px;
  right: auto;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon, .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  left: 24px;
  right: auto;
}
.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
  padding-left: 10px;
  padding-right: 0;
}
@media screen and (max-width: 480px) {
  .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0;
    padding-left: 10px;
    padding-right: 0;
  }
}
.e-tab.e-rtl .e-tab-header .e-hor-nav,
.e-tab.e-rtl .e-tab-header .e-scroll-right-nav {
  padding: 0;
}
.e-tab.e-rtl .e-tab-header .e-scroll-left-nav {
  padding: 0;
}
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  display: none;
}
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  margin: 0;
  width: 100%;
}

.e-tab-clone-element {
  overflow: visible;
  z-index: 10000;
}
.e-tab-clone-element:not(.e-separator) {
  height: 32px;
  margin: 0;
  min-height: 32px;
  min-width: auto;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element:not(.e-separator) {
    height: 42px;
    min-height: 42px;
  }
}
.e-tab-clone-element .e-tab-wrap {
  height: 32px;
  padding: 0 12px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-tab-wrap {
    height: 42px;
    padding: 0 15px;
  }
}
.e-tab-clone-element .e-text-wrap {
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 32px;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-text-wrap {
    height: 42px;
  }
}
.e-tab-clone-element .e-tab-text {
  display: inherit;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  -ms-flex-item-align: auto;
      align-self: auto;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-tab-text {
    font-size: 16px;
  }
}
.e-tab-clone-element .e-icons.e-close-icon {
  display: none;
}
.e-tab-clone-element .e-close-icon,
.e-tab-clone-element .e-close-icon::before {
  font-size: 10px;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-close-icon,
  .e-tab-clone-element .e-close-icon::before {
    font-size: 12px;
  }
}
.e-tab-clone-element .e-close-icon::before {
  top: 1px;
}
.e-tab-clone-element .e-icons.e-tab-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 24px;
  min-width: 24px;
  width: 24px;
  line-height: 24px;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-icons.e-tab-icon {
    height: 20px;
    width: 20px;
  }
}
.e-tab-clone-element .e-tab-icon,
.e-tab-clone-element .e-tab-icon::before {
  font-size: 14px;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-tab-icon,
  .e-tab-clone-element .e-tab-icon::before {
    font-size: 20px;
  }
}
.e-tab-clone-element.e-icon .e-tab-wrap {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 15px;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-icon .e-tab-wrap {
    padding: 0 16px;
  }
}
.e-tab-clone-element .e-icon-right {
  margin: 0 0 0 8px;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-icon-right {
    margin: 0;
  }
}
.e-tab-clone-element .e-icon-left + .e-tab-text {
  margin: 0 0 0 8px;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-icon-left + .e-tab-text {
    margin: 0 0 0 10px;
  }
}
.e-tab-clone-element.e-itop, .e-tab-clone-element.e-ibottom {
  height: 62px;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-itop, .e-tab-clone-element.e-ibottom {
    height: 62px;
  }
}
.e-tab-clone-element.e-itop .e-tab-wrap, .e-tab-clone-element.e-ibottom .e-tab-wrap {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 62px;
  position: relative;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-itop .e-tab-wrap, .e-tab-clone-element.e-ibottom .e-tab-wrap {
    height: 62px;
  }
}
.e-tab-clone-element.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab-clone-element.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-tab-clone-element.e-itop .e-text-wrap, .e-tab-clone-element.e-ibottom .e-text-wrap {
  display: block;
  height: auto;
}
.e-tab-clone-element.e-itop .e-close-icon, .e-tab-clone-element.e-ibottom .e-close-icon {
  position: absolute;
  right: 15px;
  top: calc(50% - 6px);
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-itop .e-close-icon, .e-tab-clone-element.e-ibottom .e-close-icon {
    right: 12px;
  }
}
.e-tab-clone-element.e-itop .e-tab-text {
  margin: 8px 0 0;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-itop .e-tab-text {
    margin: 8px 0 0;
  }
}
.e-tab-clone-element.e-ibottom .e-tab-text {
  margin: 0 0 8px;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-ibottom .e-tab-text {
    margin: 0 0 10px;
  }
}
.e-tab-clone-element .e-tab-icon.e-icon-top,
.e-tab-clone-element .e-tab-icon.e-icon-bottom {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
}
.e-tab-clone-element.e-close-show .e-icons.e-close-icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-close-show .e-icons.e-close-icon {
    display: none;
  }
}
.e-tab-clone-element.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab-clone-element.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-right: 20px;
}

.e-tab {
  background: transparent;
  border: none;
}
.e-tab .e-tab-header {
  background: inherit;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 400;
  border-bottom: 0;
}
.e-tab .e-tab-header::before {
  border-color: #dee2e6;
  border-style: solid;
}
.e-tab .e-tab-header:not(.e-vertical)::before {
  border-width: 0 0 1px;
}
.e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active {
  border-bottom: 1px solid #fff;
  margin-bottom: 0;
}
.e-tab .e-tab-header .e-toolbar-items {
  background: inherit;
}
.e-tab .e-tab-header .e-indicator {
  background: #dee2e6;
}
.e-tab .e-tab-header .e-toolbar-item {
  background: inherit;
  border: 1px solid transparent;
}
.e-tab .e-tab-header .e-toolbar-item:hover {
  background: inherit;
}
.e-tab .e-tab-header .e-toolbar-item.e-active:hover {
  background: #fff;
}
.e-tab .e-tab-header .e-toolbar-item .e-ripple-element {
  background: rgba(0, 0, 0, 0.12);
}
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  border-radius: 4px;
  color: #0d6efd;
  border: 1px solid transparent;
}
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: #6c757d;
}
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
  background: transparent;
  border: 1px solid #dee2e6;
  border-radius: 4px 4px 0 0;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
    background: initial;
  }
}
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
  color: #0a58ca;
}
.e-tab .e-tab-header .e-toolbar-item.e-active {
  border: 1px solid #dee2e6;
  border-radius: 4px 4px 0 0;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  border: 1px solid transparent;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
  color: #495057;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  position: unset;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
  border: unset;
  content: unset;
  display: unset;
  position: unset;
  width: unset;
  top: unset;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #495057;
}
.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-tab-icon, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-tab-icon {
  color: #6c757d;
}
.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-tab-text, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-tab-text {
  color: #495057;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-close-icon {
  color: #6c757d;
}
.e-tab .e-tab-header .e-toolbar-item .e-close-icon {
  color: #6c757d;
}
.e-tab .e-tab-header .e-toolbar-item .e-close-icon:hover {
  color: #0a58ca;
}
.e-tab .e-tab-header .e-toolbar-item .e-close-icon:active {
  color: #0d6efd;
}
.e-tab .e-tab-header .e-toolbar-item.e-disable.e-overlay {
  background: inherit;
  opacity: 1;
  pointer-events: none;
}
.e-tab .e-tab-header .e-toolbar-item.e-disable.e-overlay .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-disable.e-overlay .e-tab-icon {
  color: #adb5bd;
}
.e-tab .e-tab-header.e-reorder-active-item:not(.e-vertical) .e-toolbar-pop .e-toolbar-item.e-active {
  border: 1px solid #fff;
}
.e-tab .e-tab-header .e-toolbar-pop {
  background: #fff;
  border: 1px #000;
  border-radius: 4px;
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
  overflow-y: auto;
}
.e-tab .e-tab-header .e-toolbar-pop[e-animate=true] {
  overflow-y: hidden;
}
@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop {
    -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
            box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
  }
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: #212529;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover {
  background: #dee2e6;
  border: 1px solid transparent;
  border-radius: 0;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-icon,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-close-icon {
  color: #0a58ca;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active {
  background: #e9ecef;
  border-color: transparent;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-tab-icon {
  color: #fff;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-active .e-tab-wrap .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-active .e-tab-wrap .e-tab-icon {
  color: #495057;
}
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-disable.e-overlay .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-disable.e-overlay .e-tab-icon {
  color: #adb5bd;
}
.e-tab .e-tab-header .e-scroll-nav,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav {
  background: inherit;
  border: 0;
}
.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow {
  color: #6c757d;
  border: 0;
}
.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow:hover,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow:hover {
  background: #dee2e6;
  color: #0a58ca;
  border: 0;
}
.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow:active,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow:active {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0;
}
.e-tab .e-tab-header .e-scroll-nav:focus,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus {
  background: inherit;
}
.e-tab .e-tab-header .e-scroll-nav:focus .e-nav-arrow,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow {
  background: #e9ecef;
  color: #6c757d;
  border: 0;
}
.e-tab .e-tab-header .e-scroll-nav:focus .e-nav-arrow:hover,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow:hover {
  background: #dee2e6;
  color: #0a58ca;
}
.e-tab .e-tab-header .e-scroll-nav:focus .e-nav-arrow:active,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow:active {
  background: #e9ecef;
  border-color: unset;
  color: #6c757d;
}
.e-tab .e-tab-header .e-scroll-nav:active,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:active {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: inherit;
  border: 3px solid #adb5bd;
}
.e-tab .e-tab-header .e-scroll-nav.e-overlay .e-nav-arrow,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-overlay .e-nav-arrow {
  color: #495057;
}
.e-tab .e-tab-header .e-scroll-nav.e-overlay .e-nav-arrow:hover,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-overlay .e-nav-arrow:hover {
  color: #495057;
  background: #dee2e6;
  border-color: unset;
}
.e-tab .e-tab-header .e-hor-nav,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) {
  background: transparent;
  border: 0;
}
.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon {
  color: #495057;
  border: 0;
}
.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon:hover {
  background: #dee2e6;
  border: 0;
  border-radius: 4px;
}
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon:hover {
  color: #0a58ca;
}
.e-tab .e-tab-header .e-hor-nav:focus,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus {
  background: transparent;
}
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon {
  background: #e9ecef;
  border: 0;
  border-radius: 4px;
  color: #6c757d;
}
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon:hover {
  background: #dee2e6;
  border: 0;
  border-radius: 4px;
}
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon:active,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon:active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon:active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon:active {
  background: #dee2e6;
  color: #6c757d;
  border: 0;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.075);
}
.e-tab .e-tab-header .e-hor-nav:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):hover {
  border: 0;
  background: transparent;
}
.e-tab .e-tab-header .e-hor-nav:hover:active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):hover:active {
  background: transparent;
}
.e-tab .e-tab-header .e-hor-nav:active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):active {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
}
.e-tab .e-tab-header .e-hor-nav.e-nav-active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav).e-nav-active {
  background: #e9ecef;
  border: 3px solid #adb5bd;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 4px;
}
.e-tab .e-tab-header .e-hor-nav.e-nav-active .e-icons,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav).e-nav-active .e-icons {
  color: #495057;
}
.e-tab .e-tab-header .e-hor-nav.e-nav-active:focus,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav).e-nav-active:focus {
  border: 3px solid #adb5bd;
}
.e-tab .e-tab-header.e-horizontal-bottom {
  border-color: unset;
  border-style: solid;
  border-width: 0;
}
.e-tab .e-tab-header.e-horizontal-bottom[style*="overflow: hidden"] {
  border-color: unset;
  border-style: solid;
  border-width: 0;
}
.e-tab .e-tab-header.e-horizontal-bottom[style*="overflow: hidden"] .e-toolbar-items {
  border-color: #fff;
  border-style: solid;
  border-width: 1px 0 0;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item .e-tab-wrap:hover {
  border-radius: 0 0 4px 4px;
}
.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  border-color: #fff #dee2e6 #dee2e6;
  border-style: solid;
  border-width: 1px;
  margin-top: 0;
  border-radius: 0 0 4px 4px;
}
.e-tab .e-tab-header.e-vertical {
  border-bottom: 0;
}
.e-tab .e-tab-header.e-vertical::before {
  border-width: 0 1px 0 0;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-active {
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}
.e-tab .e-tab-header.e-vertical.e-vertical-left .e-toolbar-item .e-tab-wrap:hover {
  border-radius: 4px 0 0 4px;
}
.e-tab .e-tab-header.e-vertical.e-vertical-left .e-toolbar-item.e-active {
  border-radius: 4px 0 0 4px;
  border-right: 1px solid #fff;
}
.e-tab .e-tab-header.e-vertical.e-vertical-right .e-toolbar-item .e-tab-wrap:hover {
  border-radius: 0 4px 4px 0;
}
.e-tab .e-tab-header.e-vertical.e-vertical-right .e-toolbar-item.e-active {
  border-left: 1px solid #fff;
  border-radius: 0 4px 4px 0;
}
.e-tab .e-tab-header.e-vertical .e-toolbar-pop {
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav {
  background: inherit;
  border: 1px solid transparent;
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav.e-scroll-up-nav {
  border-bottom-color: rgba(222, 226, 230, 0.12);
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav.e-scroll-down-nav {
  border-top-color: rgba(222, 226, 230, 0.12);
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  border: 0;
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow:hover {
  background: none;
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav:hover {
  background: rgba(233, 236, 239, 0.12);
  border-color: rgba(222, 226, 230, 0.12) transparent transparent;
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav:hover:active:focus {
  background: rgba(233, 236, 239, 0.12);
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav:hover .e-nav-arrow {
  color: #0a58ca;
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav:hover .e-nav-arrow:hover {
  background: none;
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav:focus {
  background: rgba(233, 236, 239, 0.12);
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav:focus:hover:active .e-nav-arrow {
  color: none;
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow {
  background: none;
}
.e-tab .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow:hover {
  background: none;
  border: 0;
}
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon {
  border: 0;
}
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon:hover,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon:hover {
  background: inherit;
  border: 0;
  color: #0a58ca;
}
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon {
  background: inherit;
  border: 0;
}
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:hover, .e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:active, .e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:active:hover,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:hover,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:active,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:active:hover {
  background: inherit;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:active,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:active {
  color: #6c757d;
}
.e-tab .e-tab-header.e-vertical .e-hor-nav:hover .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav:hover .e-popup-down-icon {
  border: 0;
}
.e-tab.e-focused > .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  background: rgba(233, 236, 239, 0.12);
  border: 1px solid #6c757d;
}
.e-tab.e-focused > .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon,
.e-tab.e-focused > .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text {
  color: #0a58ca;
}
.e-tab.e-focused > .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon {
  color: #6c757d;
}
.e-tab.e-focused > .e-tab-header .e-scroll-nav:focus .e-nav-arrow {
  color: #6c757d;
}
.e-tab.e-focused > .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-focused > .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  color: #6c757d;
  background: #e9ecef;
  border: 0;
}
.e-tab.e-focused > .e-tab-header.e-vertical .e-scroll-nav:focus {
  border-color: rgba(222, 226, 230, 0.12) transparent transparent;
}
.e-tab.e-focused > .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow {
  border: 0;
}
.e-tab.e-focused > .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-tab.e-focused > .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon {
  border: 0;
}
.e-tab.e-focused > .e-tab-header.e-vertical .e-hor-nav:focus {
  outline: 0;
}
.e-tab.e-disable {
  pointer-events: none;
}
.e-tab.e-disable .e-tab-header .e-indicator {
  background: rgba(222, 226, 230, 0.38);
}
.e-tab.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: #adb5bd;
}
.e-tab.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: rgba(222, 226, 230, 0.38);
}
.e-tab.e-disable .e-content {
  opacity: 0.38;
}
.e-tab.e-fill .e-tab-header {
  border-bottom: 0;
}
.e-tab.e-fill .e-tab-header .e-toolbar-items.e-hscroll {
  border: 0;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item {
  border: 0;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item .e-ripple-element {
  background: rgba(0, 0, 0, 0.05);
}
.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: #0d6efd;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text, .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
  color: #0a58ca;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
  color: #0a58ca;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
  margin-top: 0;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active {
  border: 0;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-disable.e-overlay .e-tab-text,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-disable.e-overlay .e-tab-icon {
  color: #adb5bd;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  background: #0d6efd;
  border-radius: 4px;
  border-color: unset;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text, .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
  color: #fff;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  margin-top: 0;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
  border: unset;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #fff;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-close-icon {
  color: #fff;
}
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-close-icon:hover {
  color: #fff;
}
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item:active:hover .e-tab-text,
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item:active:hover .e-tab-icon {
  color: #212529;
}
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-tab-wrap:hover {
  background: #e9ecef;
}
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-tab-wrap:hover .e-tab-text {
  color: #fff;
}
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-tab-wrap:hover .e-tab-icon {
  color: #fff;
}
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-tab-wrap:hover .e-close-icon {
  color: #fff;
}
.e-tab.e-fill .e-tab-header.e-horizontal-bottom {
  border-bottom: 0;
  border-top: 0;
}
.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  margin-top: 0;
}
.e-tab.e-fill .e-tab-header.e-vertical {
  border-bottom: 0;
}
.e-tab.e-fill .e-tab-header.e-vertical.e-vertical-left {
  border-right: 0;
}
.e-tab.e-fill .e-tab-header.e-vertical.e-vertical-right {
  border-left: 0;
}
.e-tab.e-fill.e-corner .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  border-radius: 3px 3px 0 0;
}
.e-tab.e-fill.e-corner .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap {
  border-radius: 0 0 3px 3px;
}
.e-tab.e-fill.e-disable .e-tab-header {
  border-bottom: 0;
}
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: #adb5bd;
}
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active {
  opacity: 0.65;
}
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: rgba(13, 110, 253, 0.38);
}
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-text,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-icon,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #6c757d;
}
.e-tab.e-fill.e-disable .e-tab-header.e-horizontal-bottom {
  border-bottom: 0;
  border-top: 0;
}
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-close-icon {
  color: #0a58ca;
}
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: rgba(233, 236, 239, 0.8);
  border-color: #6c757d;
}
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text {
  color: #adb5bd;
}
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-close-icon {
  color: #adb5bd;
}
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #212529;
}
.e-tab.e-background .e-tab-header {
  background: #0d6efd;
  border: 0;
}
.e-tab.e-background .e-tab-header .e-toolbar-items {
  background: inherit;
}
.e-tab.e-background .e-tab-header .e-toolbar-items.e-hscroll {
  border: 0;
}
.e-tab.e-background .e-tab-header .e-indicator {
  background: #fff;
  bottom: 0;
}
.e-tab.e-background .e-tab-header .e-toolbar-item {
  border: 0;
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-ripple-element {
  background: rgba(0, 0, 0, 0.1);
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
  background: inherit;
  border-color: transparent;
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap .e-close-icon {
  color: #e7f1ff;
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text {
  color: #e7f1ff;
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap .e-close-icon:hover {
  color: #fff;
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
  background: transparent;
  background-color: transparent;
  border-color: #dee2e6;
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text {
  color: #fff;
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
  color: #fff;
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-close-icon {
  color: #fff;
}
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-close-icon:hover, .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-close-icon:active {
  color: #fff;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active {
  border: 0;
  margin: 0;
  background: inherit;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active.e-disable.e-overlay .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active.e-disable.e-overlay .e-tab-icon {
  color: #adb5bd;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  background: #fff;
  border-color: #0d6efd;
  border-radius: 4px 4px 0 0;
  border-style: solid;
  border-width: 1px 1px 0;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-text {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-icon {
  color: #6c757d;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-close-icon {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover {
  background: #fff;
  border-color: #0d6efd;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
  color: #6c757d;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-close-icon {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-close-icon:hover {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-close-icon:active {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover:focus {
  background: #fff;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover:focus .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover:focus .e-tab-text {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: #fff;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus:hover .e-tab-text {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus:hover .e-close-icon {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
  border: unset;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #6c757d;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-close-icon {
  color: #fff;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-close-icon:hover,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-text:hover {
  color: #495057;
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active.e-itop .e-tab-wrap, .e-tab.e-background .e-tab-header .e-toolbar-item.e-active.e-ibottom .e-tab-wrap {
  background: rgba(13, 110, 253, 0);
}
.e-tab.e-background .e-tab-header .e-toolbar-item.e-disable .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-disable .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-disable .e-close-icon {
  color: rgba(222, 226, 230, 0.38);
}
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item {
  background: inherit;
}
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-close-icon {
  color: #e7f1ff;
}
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover {
  background: #e9ecef;
}
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-close-icon {
  color: #fff;
}
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-tab-text {
  color: #e7f1ff;
}
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-tab-icon {
  color: #e7f1ff;
}
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-close-icon {
  color: #e7f1ff;
}
.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav,
.e-tab.e-background .e-tab-header .e-scroll-nav {
  background: inherit;
}
.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow,
.e-tab.e-background .e-tab-header .e-scroll-nav .e-nav-arrow {
  color: #e7f1ff;
  border: 0;
}
.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow:hover,
.e-tab.e-background .e-tab-header .e-scroll-nav .e-nav-arrow:hover {
  background: inherit;
  color: #6c757d;
  border-color: unset;
}
.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow:active,
.e-tab.e-background .e-tab-header .e-scroll-nav .e-nav-arrow:active {
  background: unset;
  color: #e7f1ff;
}
.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow,
.e-tab.e-background .e-tab-header .e-scroll-nav:focus .e-nav-arrow {
  color: #6c757d;
  border: 0;
  background: #e9ecef;
}
.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow:hover,
.e-tab.e-background .e-tab-header .e-scroll-nav:focus .e-nav-arrow:hover {
  background: inherit;
  color: #6c757d;
}
.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow:active,
.e-tab.e-background .e-tab-header .e-scroll-nav:focus .e-nav-arrow:active {
  background: #e9ecef;
  border-color: unset;
  color: #6c757d;
}
.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:active::after,
.e-tab.e-background .e-tab-header .e-scroll-nav:active::after {
  -webkit-animation: none;
          animation: none;
}
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav),
.e-tab.e-background .e-tab-header .e-hor-nav {
  background: transparent;
}
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-down-icon {
  color: #e7f1ff;
  border: 0;
}
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon:hover,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-down-icon:hover {
  background: #dee2e6;
  border-color: unset;
  color: #6c757d;
}
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon:active:hover,
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon:active:hover,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-up-icon:active:hover,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-down-icon:active:hover {
  background: #dee2e6;
  color: #6c757d;
}
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon,
.e-tab.e-background .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  color: #6c757d;
  background: #e9ecef;
  border: 0;
}
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon:hover,
.e-tab.e-background .e-tab-header .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header .e-hor-nav:focus .e-popup-down-icon:hover {
  background: #dee2e6;
}
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon:active,
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon:active,
.e-tab.e-background .e-tab-header .e-hor-nav:focus .e-popup-up-icon:active,
.e-tab.e-background .e-tab-header .e-hor-nav:focus .e-popup-down-icon:active {
  background: #dee2e6;
  border-color: unset;
  color: #6c757d;
}
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav):active::after,
.e-tab.e-background .e-tab-header .e-hor-nav:active::after {
  -webkit-animation: none;
          animation: none;
}
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav).e-nav-active .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav.e-nav-active .e-popup-up-icon {
  color: #6c757d;
}
.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  border: 0;
  margin: 0;
}
.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap {
  border-color: #0d6efd;
  border-radius: 0 0 4px 4px;
  border-style: solid;
  border-width: 0 2px 2px;
}
.e-tab.e-background .e-tab-header.e-vertical.e-vertical-left .e-toolbar-item.e-active {
  border-color: unset;
  border-style: solid;
  border-width: 0;
  margin: 0;
}
.e-tab.e-background .e-tab-header.e-vertical.e-vertical-left .e-toolbar-item.e-active .e-tab-wrap {
  border-color: #0d6efd;
  border-radius: 4px 0 0 4px;
  border-style: solid;
  border-width: 2px 0 2px 2px;
}
.e-tab.e-background .e-tab-header.e-vertical.e-vertical-right .e-toolbar-item.e-active {
  border-color: unset;
  border-style: solid;
  border-width: 0;
  margin: 0;
}
.e-tab.e-background .e-tab-header.e-vertical.e-vertical-right .e-toolbar-item.e-active .e-tab-wrap {
  border-color: #0d6efd;
  border-radius: 0 4px 4px 0;
  border-style: solid;
  border-width: 2px 2px 2px 0;
}
.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  border: 0;
}
.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow {
  border: 0;
}
.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow:hover {
  background: none;
}
.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav:hover .e-nav-arrow {
  color: #6c757d;
}
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon {
  background: inherit;
  border-color: transparent;
}
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:hover {
  background: inherit;
  border-color: transparent;
}
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon:hover {
  background: inherit;
  border-color: transparent;
}
.e-tab.e-background.e-disable .e-tab-header .e-indicator {
  background: #dee2e6;
}
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: rgba(222, 226, 230, 0.38);
}
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: rgba(222, 226, 230, 0.38);
}
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon {
  color: rgba(222, 226, 230, 0.38);
}
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active {
  opacity: 0.65;
}
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: #0d6efd;
  color: #6c757d;
}
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-icon,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #6c757d;
}
.e-tab.e-background.e-accent .e-tab-header .e-indicator {
  background: #dee2e6;
}
.e-tab.e-background.e-accent .e-tab-header.e-disable .e-indicator {
  background: rgba(222, 226, 230, 0.38);
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  background: #e9ecef;
  border-color: #6c757d;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-close-icon {
  color: #e7f1ff;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: rgba(13, 110, 253, 0.12);
  border-color: #0d6efd;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #495057;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover {
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px 4px 0 0;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text {
  color: #495057;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #495057;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-close-icon {
  color: #495057;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text {
  color: #495057;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
  color: #6c757d;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-close-icon {
  color: #495057;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus:hover .e-tab-text {
  color: #495057;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus:hover .e-close-icon {
  color: #495057;
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-itop .e-tab-wrap, .e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-ibottom .e-tab-wrap {
  background: rgba(13, 110, 253, 0);
}
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-itop .e-tab-wrap:focus, .e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-ibottom .e-tab-wrap:focus {
  background: rgba(13, 110, 253, 0.12);
}
.e-tab.e-background.e-focused .e-tab-header .e-scroll-nav:focus .e-nav-arrow {
  color: #6c757d;
}
.e-tab.e-background.e-focused .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-background.e-focused .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  color: #6c757d;
}
.e-tab.e-rtl .e-tab-header .e-hscroll.e-rtl .e-scroll-nav {
  border: 0;
}
.e-tab .e-content {
  background: inherit;
}
.e-tab .e-content .e-item {
  background: inherit;
  color: #212529;
  font-size: 14px;
}

.e-tab-clone-element {
  background: #dee2e6;
  color: #343a40;
}
.e-tab-clone-element .e-tab-wrap {
  border-radius: 4px;
  color: #343a40;
  border: 1px solid transparent;
}
.e-tab-clone-element .e-tab-wrap .e-tab-icon {
  color: #343a40;
}

.e-bigger .e-tab .e-tab-header,
.e-tab.e-bigger .e-tab-header {
  height: 42px;
  min-height: 42px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-items,
.e-tab.e-bigger .e-tab-header .e-toolbar-items {
  height: auto;
  min-height: auto;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child, .e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child, .e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  padding-bottom: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0 2px 0 0;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-items .e-indicator + .e-toolbar-item:last-child.e-active,
.e-tab.e-bigger .e-tab-header .e-toolbar-items .e-indicator + .e-toolbar-item:last-child.e-active {
  margin: 1px 2px 0 0;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0 2px 0 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0 2px 0 0;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active,
.e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0 2px 0 0;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav,
.e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav {
  width: 38px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 42px;
  margin: 0;
  min-height: 42px;
  min-width: auto;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator),
  .e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator) {
    margin: 0;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator).e-itop, .e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom,
.e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator).e-itop,
.e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom {
  height: 72px;
  min-height: 72px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 42px;
  padding: 0 16px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 15px;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-text-wrap {
  height: 42px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-text {
  font-size: 16px;
  margin-bottom: 0;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ileft:not(.e-icon) .e-tab-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ileft:not(.e-icon) .e-tab-icon::before {
  position: relative;
  top: 0;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active {
  margin: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active {
    margin: 0 2px 0 0;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft:not(.e-icon) .e-tab-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active.e-ileft:not(.e-icon) .e-tab-icon::before {
  position: relative;
  top: 0;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 42px;
  margin-top: 0;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 42px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
  cursor: pointer;
  margin: 2px 0 0 8px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
    margin: 0 0 0 8px;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-close-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-close-icon::before {
  font-size: 12px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-close-icon::before,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-close-icon::before {
    font-size: 12px;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-tab-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-tab-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
  line-height: 24px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-icon,
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-icon::before {
  font-size: 16px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap {
  padding: 0 20px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icon-right,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icon-right {
  margin: 0 0 0 12px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icon-right,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icon-right {
    margin: 0;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 8px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
    margin: 0 0 0 10px;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom {
  height: 72px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 72px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap {
  height: 72px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon {
  right: 20px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-tab-text {
  margin: 8px 0 0;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0 0 10px;
}
.e-bigger .e-tab .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-bigger .e-tab .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-right: 22px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item {
  height: 40px;
  min-height: 40px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
  height: 40px;
  padding: 0 24px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
    padding: 0 24px;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-text-wrap {
  height: 40px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style="display:block"],
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style="display:block"] {
  padding-right: 12px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style="display:block"],
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style="display:block"] {
    padding-right: 0;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon {
  margin: 0 0 0 8px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon::before {
  top: 0;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  right: 24px;
}
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator),
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  min-height: 50px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator),
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
    min-height: 50px;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 50px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
    height: 50px;
  }
}
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
  padding-left: 0;
  padding-right: 12px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.e-bigger .e-tab .e-tab-header .e-scroll-nav,
.e-tab.e-bigger .e-tab-header .e-scroll-nav {
  height: 42px;
  min-height: 42px;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-scroll-nav,
  .e-tab.e-bigger .e-tab-header .e-scroll-nav {
    padding: 0;
  }
}
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-scroll-right-nav,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-scroll-right-nav {
  padding: 0;
}
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-scroll-left-nav,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-scroll-left-nav {
  padding: 0;
}
.e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
.e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-arrow {
  font-size: 12px;
  height: 38px;
  line-height: 38px;
  width: 38px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
  .e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-arrow {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    width: 38px;
  }
}
.e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-right-arrow::before,
.e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  font-size: 12px;
  line-height: 38px;
  top: 0;
  vertical-align: initial;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon {
  height: 100%;
  width: 100%;
}
.e-bigger .e-tab .e-tab-header .e-hor-nav,
.e-tab.e-bigger .e-tab-header .e-hor-nav {
  height: 42px;
  min-height: 42px;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-hor-nav,
  .e-tab.e-bigger .e-tab-header .e-hor-nav {
    padding: 0;
  }
}
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon {
  font-size: 12px;
  height: 38px;
  line-height: 38px;
  width: 38px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    width: 38px;
  }
}
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon::before {
  font-size: 12px;
  line-height: 38px;
  top: 0;
  vertical-align: initial;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 16px;
  }
}
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon:hover {
  line-height: 38px;
}
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before {
  line-height: 38px;
  top: 0;
}
.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  line-height: 38px;
}
.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before {
  line-height: 38px;
  top: 0;
}
.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon:hover,
.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon:hover,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-down-icon:hover {
  line-height: 38px;
  top: -2px;
}
.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  line-height: 15px;
}
.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  line-height: 15px;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0 2px 0 0;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin: 0 2px 0 0;
  padding-bottom: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active,
  .e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
    margin: 0 2px 0 0;
  }
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-text {
  padding-top: 1px;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-close-icon::before,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-close-icon::before {
  top: 0.5px;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-close-icon::before, .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ibottom .e-close-icon::before,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-close-icon::before,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ibottom .e-close-icon::before {
  top: 1.5px;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child {
  margin: 0 2px 0 0;
  padding-bottom: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child,
  .e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child {
    margin: 0 2px 0 0;
  }
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child .e-tab-text,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child .e-tab-text {
  padding-top: 0.5px;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child .e-close-icon::before,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child .e-close-icon::before {
  top: 0;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop, .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom {
  padding-bottom: 0;
  padding-top: 0;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ileft.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ileft.e-active .e-text-wrap {
  margin: 0;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 42px;
  padding: 0;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap, .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap {
  height: initial;
}
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ileft.e-active,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ileft.e-active {
  height: 42px;
}
.e-bigger .e-tab .e-tab-header.e-vertical,
.e-tab.e-bigger .e-tab-header.e-vertical {
  max-width: 150px;
}
.e-bigger .e-tab .e-tab-header.e-vertical[style*="overflow: hidden"]::before,
.e-tab.e-bigger .e-tab-header.e-vertical[style*="overflow: hidden"]::before {
  bottom: 23px;
  top: 23px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header.e-vertical[style*="overflow: hidden"]::before,
  .e-tab.e-bigger .e-tab-header.e-vertical[style*="overflow: hidden"]::before {
    bottom: 0;
    top: 0;
  }
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-items,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-items.e-vscroll:not(.e-scroll-device),
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-items.e-vscroll:not(.e-scroll-device) {
  padding: 24px 0;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-item .e-tab-wrap {
  padding: 0 16px;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon {
  right: -20px;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-item.e-ileft.e-icon,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-item.e-ileft.e-icon {
  min-height: 38px;
  min-width: auto;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-scroll-nav,
.e-tab.e-bigger .e-tab-header.e-vertical .e-scroll-nav {
  height: 24px;
  min-height: 24px;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow,
.e-tab.e-bigger .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 16px;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  line-height: 48px;
}
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  font-size: 16px;
  line-height: 48px;
  margin: 0 0 0 8px;
}
.e-bigger .e-tab .e-tab-header.e-vertical.e-toolpop .e-toolbar-items,
.e-tab.e-bigger .e-tab-header.e-vertical.e-toolpop .e-toolbar-items {
  height: auto;
}
.e-bigger .e-tab.e-vertical-icon > .e-tab-header,
.e-tab.e-bigger.e-vertical-icon > .e-tab-header {
  height: 72px;
  min-height: 72px;
}
.e-bigger .e-tab.e-vertical-icon > .e-tab-header > .e-toolbar-items,
.e-tab.e-bigger.e-vertical-icon > .e-tab-header > .e-toolbar-items {
  height: 72px;
}
.e-bigger .e-tab.e-vertical-icon > .e-tab-header .e-scroll-nav,
.e-tab.e-bigger.e-vertical-icon > .e-tab-header .e-scroll-nav {
  height: 72px;
}
.e-bigger .e-tab.e-vertical-icon > .e-tab-header .e-hor-nav,
.e-tab.e-bigger.e-vertical-icon > .e-tab-header .e-hor-nav {
  height: 72px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-vertical-icon > .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-bigger .e-tab.e-vertical-icon > .e-tab-header .e-hor-nav .e-popup-down-icon::before,
  .e-tab.e-bigger.e-vertical-icon > .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab.e-bigger.e-vertical-icon > .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 12px;
  }
}
.e-bigger .e-tab.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-toolbar-items,
.e-tab.e-bigger.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}
.e-bigger .e-tab.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-scroll-nav,
.e-tab.e-bigger.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-scroll-nav {
  height: 24px;
}
.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 42px;
}
.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 42px;
}
.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus, .e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 72px;
}
.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus {
  height: 40px;
}
.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 40px;
}
.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap:focus, .e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 42px;
}
.e-bigger .e-tab.e-focused .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap {
  height: 42px;
  padding: 0;
}
.e-bigger .e-tab.e-focused .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ileft,
.e-tab.e-bigger.e-focused .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ileft {
  height: 42px;
}
.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  line-height: 15px;
}
.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  line-height: 15px;
}
.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  line-height: 15px;
}
.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  line-height: 13px;
}
.e-bigger .e-tab.e-safari .e-tab-header .e-close-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-close-icon::before {
  top: 0;
}
.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-up-icon::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-down-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-popup-up-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-popup-down-icon::before {
  top: 0;
}
.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-left-arrow::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-right-arrow::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-right-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-nav-left-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-nav-right-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  top: 0;
}
.e-bigger .e-tab.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}
.e-bigger .e-tab.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator).e-active,
.e-tab.e-bigger.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator).e-active {
  margin: 0 0 0 2px;
}
.e-bigger .e-tab.e-fill .e-tab-header,
.e-tab.e-bigger.e-fill .e-tab-header {
  height: 42px;
  min-height: 42px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header,
  .e-tab.e-bigger.e-fill .e-tab-header {
    height: 42px;
    min-height: 42px;
  }
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items {
  height: auto;
  min-height: 42px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items {
    min-height: 42px;
  }
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 42px;
  margin: 0 2px 0 0;
  min-height: 42px;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator),
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 42px;
    min-height: 42px;
  }
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-itop, .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-itop,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom {
  height: 72px;
  min-height: 72px;
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 42px;
  padding: 0 20px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: 42px;
    padding: 0 20px;
  }
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
  height: 42px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
    height: 42px;
  }
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 42px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    height: 42px;
  }
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 42px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
    height: 42px;
  }
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 72px;
}
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator),
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  height: auto;
}
.e-bigger .e-tab.e-fill .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 16px;
}
.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 50px;
}
.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap,
.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap {
  height: auto;
}
.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: 50px;
}
.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 42px;
}
.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 42px;
}
.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap {
  height: 42px;
}
.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus, .e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 72px;
}
.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
  .e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
  .e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
    height: auto;
  }
}
.e-bigger .e-tab.e-fill.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-fill.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}
.e-bigger .e-tab.e-fill.e-vertical-icon > .e-tab-header,
.e-tab.e-bigger.e-fill.e-vertical-icon > .e-tab-header {
  height: 70px;
  min-height: 70px;
}
.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}
.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item:not(.e-separator) {
  padding: 0;
}
.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
  padding: 0 20px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 20px;
  }
}
.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item.e-active,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}
.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}
.e-bigger .e-tab.e-background .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-background .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 16px;
}
.e-bigger .e-tab.e-background.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-background.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-right,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-right {
  margin: 0;
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text {
  margin: 0 10px 0 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text {
    margin: 0 10px 0 0;
  }
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon {
  margin: 0 10px 0 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon {
    margin: 0 8px 0 0;
  }
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-itop .e-close-icon, .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-itop .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon {
  left: 20px;
  right: auto;
}
.e-bigger .e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-bigger .e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap,
.e-tab.e-bigger.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-bigger.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-left: 22px;
  margin-right: 0;
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item {
  margin: 0;
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
  padding-left: 12px;
  padding-right: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
    padding-left: 0;
  }
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-icons.e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-icons.e-close-icon {
  margin: 0;
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  left: 24px;
  right: auto;
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
  padding-left: 12px;
  padding-right: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-hor-nav,
.e-bigger .e-tab.e-rtl .e-tab-header .e-scroll-right-nav,
.e-tab.e-bigger.e-rtl .e-tab-header .e-hor-nav,
.e-tab.e-bigger.e-rtl .e-tab-header .e-scroll-right-nav {
  padding: 0;
}
.e-bigger .e-tab.e-rtl .e-tab-header .e-scroll-left-nav,
.e-tab.e-bigger.e-rtl .e-tab-header .e-scroll-left-nav {
  padding: 0;
}
.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator) {
  margin: 0;
}
.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon {
  left: -20px;
}
.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 16px;
}
.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-popup-up-icon::after,
.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-popup-down-icon::after,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-popup-up-icon::after,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-popup-down-icon::after {
  margin: 0 8px 0 0;
}

.e-bigger .e-tab-clone-element:not(.e-separator) {
  height: 42px;
  margin: 0;
  min-height: 42px;
  min-width: auto;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element:not(.e-separator) {
    margin: 0;
  }
}
.e-bigger .e-tab-clone-element:not(.e-separator).e-itop, .e-bigger .e-tab-clone-element:not(.e-separator).e-ibottom {
  height: 72px;
  min-height: 72px;
}
.e-bigger .e-tab-clone-element .e-tab-wrap {
  height: 42px;
  padding: 0 16px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element .e-tab-wrap {
    padding: 0 15px;
  }
}
.e-bigger .e-tab-clone-element .e-text-wrap {
  height: 42px;
}
.e-bigger .e-tab-clone-element .e-tab-text {
  font-size: 16px;
  -ms-flex-item-align: auto;
      align-self: auto;
}
.e-bigger .e-tab-clone-element .e-icons.e-close-icon {
  cursor: pointer;
  margin: 2px 0 0 8px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element .e-icons.e-close-icon {
    margin: 0 0 0 8px;
  }
}
.e-bigger .e-tab-clone-element .e-icons.e-close-icon,
.e-bigger .e-tab-clone-element .e-close-icon::before {
  font-size: 12px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element .e-icons.e-close-icon,
  .e-bigger .e-tab-clone-element .e-close-icon::before {
    font-size: 12px;
  }
}
.e-bigger .e-tab-clone-element .e-icons.e-tab-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
  line-height: 24px;
}
.e-bigger .e-tab-clone-element .e-tab-icon,
.e-bigger .e-tab-clone-element .e-tab-icon::before {
  font-size: 16px;
}
.e-bigger .e-tab-clone-element.e-icon .e-tab-wrap {
  padding: 0 20px;
}
.e-bigger .e-tab-clone-element .e-icon-right {
  margin: 0 0 0 12px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element .e-icon-right {
    margin: 0;
  }
}
.e-bigger .e-tab-clone-element .e-icon-left + .e-tab-text {
  margin: 0 0 0 8px;
}
@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element .e-icon-left + .e-tab-text {
    margin: 0 0 0 10px;
  }
}
.e-bigger .e-tab-clone-element.e-itop, .e-bigger .e-tab-clone-element.e-ibottom {
  height: 72px;
}
.e-bigger .e-tab-clone-element.e-itop .e-tab-wrap, .e-bigger .e-tab-clone-element.e-ibottom .e-tab-wrap {
  height: 72px;
}
.e-bigger .e-tab-clone-element.e-itop .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab-clone-element.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-bigger .e-tab-clone-element.e-itop.e-active .e-tab-wrap, .e-bigger .e-tab-clone-element.e-ibottom.e-active .e-tab-wrap {
  height: 72px;
}
.e-bigger .e-tab-clone-element.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab-clone-element.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}
.e-bigger .e-tab-clone-element.e-itop .e-close-icon, .e-bigger .e-tab-clone-element.e-ibottom .e-close-icon {
  right: 20px;
}
.e-bigger .e-tab-clone-element.e-itop .e-tab-text {
  margin: 8px 0 0;
}
.e-bigger .e-tab-clone-element.e-ibottom .e-tab-text {
  margin: 0 0 10px;
}

/* stylelint-disable property-no-vendor-prefix */
/*! TreeView's bootstrap5 theme wise override definitions and variables */
/* stylelint-disable */
/*! TreeView icons */
.e-treeview .e-list-item div.e-icons:not(.e-icons-spinner).e-icon-expandable::before,
.e-treeview .e-list-item div.e-icons:not(.e-icons-spinner).e-icon-collapsible::before {
  content: "\e75c";
}
.e-treeview .e-sibling::before {
  content: "";
}
.e-treeview .e-popup .e-icons::before {
  content: "\e76a";
}
.e-treeview.e-drag-item .e-icons.e-drop-in::before {
  content: "\e768";
}
.e-treeview.e-drag-item .e-icons.e-drop-out::before {
  content: "\e839";
}
.e-treeview.e-drag-item .e-icons.e-drop-next::before {
  content: "\e736";
}
.e-treeview.e-drag-item .e-icons.e-no-drop::before {
  content: "\e839";
}

/* stylelint-disable property-no-vendor-prefix */
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.e-treeview {
  display: block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}
.e-treeview.e-virtualization {
  overflow: auto;
}
.e-treeview.e-virtualization .e-virtual-mask {
  display: block;
  margin-bottom: 20px;
}
.e-treeview.e-virtualization .e-ul {
  overflow: unset;
}
.e-treeview > .e-ul {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
.e-treeview.e-text-wrap .e-list-text {
  white-space: normal;
  word-break: break-word;
}
.e-treeview.e-text-wrap.e-ie-wrap .e-list-text {
  word-break: break-all;
}
.e-treeview.e-text-wrap .e-editing .e-list-text,
.e-treeview.e-text-wrap .e-editing .e-list-text .e-input-group {
  max-width: calc(100% - 2px);
}
.e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-text {
  max-width: calc(100% - 21px);
}
.e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-text, .e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-img + .e-list-text {
  max-width: calc(100% - 54px);
}
.e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-img + .e-list-text {
  max-width: calc(100% - 82px);
}
.e-treeview.e-text-wrap .e-list-icon + .e-list-text,
.e-treeview.e-text-wrap .e-list-img + .e-list-text {
  max-width: calc(100% - 32px);
}
.e-treeview.e-text-wrap .e-list-icon + .e-list-img + .e-list-text {
  max-width: calc(100% - 60px);
}
.e-treeview .e-ul {
  margin: 0;
  padding: 0 0 0 12px;
}
.e-treeview .e-node-collapsed .e-list-item .e-fullrow,
.e-treeview .e-display-none {
  display: none;
}
.e-treeview .e-list-item {
  list-style: none;
  padding: 2px 0;
}
.e-treeview .e-list-item .e-ul {
  margin: 2px 0 -2px;
  padding: 0 0 0 20px;
}
.e-treeview .e-list-item.e-disable > .e-text-content,
.e-treeview .e-list-item.e-disable > .e-fullrow {
  -ms-touch-action: none;
  opacity: 0.5;
  pointer-events: none;
  touch-action: none;
}
.e-treeview .e-list-item div.e-icons.interaction {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  border-radius: 15px;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.e-treeview .e-list-item .e-icons.e-icon-collapsible {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.e-treeview .e-list-item .e-icons.e-icons-spinner::before {
  content: none;
}
.e-treeview .e-icons .e-spinner-pane {
  position: relative;
}
.e-treeview .e-icons .e-treeview-spinner {
  position: absolute;
}
.e-treeview .e-icons-spinner {
  position: relative;
}
.e-treeview .e-text-content {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid;
  cursor: pointer;
  margin: 0;
  padding: 0 0 0 20px;
}
.e-treeview .e-text-content + .e-sibling {
  margin-top: 1px;
}
.e-treeview .e-fullrow {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  height: 36px;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.e-treeview .e-checkbox-wrapper {
  margin: 0 0 0 12px;
  pointer-events: all;
  position: relative;
}
.e-treeview .e-checkbox-wrapper + .e-list-icon, .e-treeview .e-checkbox-wrapper + .e-list-img {
  margin: 0 0 0 8px;
}
.e-treeview .e-checkbox-wrapper + .e-list-text {
  padding: 0 8px;
}
.e-treeview .e-checkbox-wrapper .e-ripple-container {
  bottom: -7px;
  height: 32px;
  left: -7px;
  right: -7px;
  top: -7px;
  width: 32px;
}
.e-treeview .e-list-text {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  line-height: 34px;
  margin: 0;
  min-height: 34px;
  padding: 0 8px;
  text-decoration: none;
  vertical-align: middle;
}
.e-treeview .e-list-text .e-input-group {
  height: 30px;
  margin-bottom: 2px;
  min-width: 150px;
  vertical-align: bottom;
}
.e-treeview .e-list-text .e-input-group .e-input {
  height: 28px;
}
.e-treeview .e-navigable-text {
  padding: 0 8px;
}
.e-treeview .e-list-icon,
.e-treeview .e-list-img {
  display: inline-block;
  height: 20px;
  margin: 0 0 0 8px;
  vertical-align: middle;
  width: 20px;
}
.e-treeview .e-list-icon + .e-list-icon, .e-treeview .e-list-icon + .e-list-img,
.e-treeview .e-list-img + .e-list-icon,
.e-treeview .e-list-img + .e-list-img {
  margin: 0 0 0 8px;
}
.e-treeview .e-list-icon + .e-list-text,
.e-treeview .e-list-img + .e-list-text {
  padding: 0 8px;
}
.e-treeview .e-list-icon + .e-navigable-text,
.e-treeview .e-list-img + .e-navigable-text {
  padding: 0 8px;
}
.e-treeview .e-icon-collapsible,
.e-treeview .e-icon-expandable {
  display: inline-block;
  height: 20px;
  margin: 0 4px 0 -25px;
  vertical-align: middle;
  width: 20px;
}
.e-treeview .e-icon-collapsible::before,
.e-treeview .e-icon-expandable::before {
  display: inline-block;
  padding: 0px;
}
.e-treeview .e-load {
  -webkit-animation: rotation 0.5s infinite linear;
  animation: rotation 0.5s infinite linear;
}
.e-treeview .e-sibling {
  width: 144px;
}
.e-treeview .e-sibling::before {
  left: 0;
  height: 2px;
  top: -1.5px;
}
.e-treeview .e-sibling,
.e-treeview .e-sibling::before {
  position: absolute;
  z-index: 2;
}
.e-treeview .e-popup {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  font-weight: normal;
  position: absolute;
  z-index: 99999;
}
.e-treeview .e-popup .e-content {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  padding: 4px;
}
.e-treeview .e-popup .e-icons {
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  height: 26px;
  line-height: 18px;
  padding: 4px;
  width: 26px;
}
.e-treeview .e-popup .e-downtail::before, .e-treeview .e-popup .e-downtail::after {
  border: 10px solid transparent;
  content: "";
  height: 0;
  left: 8px;
  position: absolute;
  width: 0;
}
.e-treeview .e-popup .e-downtail::after {
  bottom: -18px;
}
.e-treeview.e-fullrow-wrap .e-text-content {
  pointer-events: none;
  position: relative;
}
.e-treeview.e-fullrow-wrap .e-icon-collapsible,
.e-treeview.e-fullrow-wrap .e-icon-expandable,
.e-treeview.e-fullrow-wrap .e-input,
.e-treeview.e-fullrow-wrap .e-list-url {
  pointer-events: auto;
}
.e-treeview .e-navigable .e-text-content {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.e-treeview .e-navigable .e-list-url {
  padding: 0;
  width: 100%;
}
.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {
  padding: 0 0 0 8px;
}
.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 8px 0 0;
}
.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon + .e-list-img {
  margin: 0 8px 0 0;
}
.e-treeview .e-navigable .e-anchor-wrap {
  padding: 0 0 0 12px;
}
.e-treeview .e-navigable .e-nav-wrapper {
  padding: 0;
}
.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-text .e-nav-wrapper:not(:has(.e-list-icon)) {
  padding: 0;
}
.e-treeview .e-navigable .e-list-icon,
.e-treeview .e-navigable .e-list-img {
  margin: 0 8px 0 0;
}
.e-treeview.e-drag-item {
  overflow: visible;
  z-index: 10000;
}
.e-treeview.e-drag-item .e-text-content {
  float: left;
}
.e-treeview.e-drag-item .e-text-content .e-list-text {
  padding: 0 12px;
}
.e-treeview.e-drag-item .e-icon-collapsible,
.e-treeview.e-drag-item .e-icon-expandable {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  padding-left: 12px;
}
.e-treeview.e-drag-item .e-icon-collapsible::before,
.e-treeview.e-drag-item .e-icon-expandable::before {
  font-size: 20px;
}
.e-treeview.e-drag-item .e-drop-count {
  border: 1px solid;
  border-radius: 15px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  font-size: 12px;
  line-height: normal;
  min-width: 12px;
  padding: 1px 3px 2px;
  margin-left: -12px;
  position: absolute;
  text-align: center;
  top: -10px;
}
.e-treeview.e-dragging .e-text-content,
.e-treeview.e-dragging .e-fullrow {
  cursor: default;
}
.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {
  padding: 0 8px 0 0;
}
.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 0 0 8px;
}
.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon + .e-list-img {
  margin: 0 0 0 8px;
}
.e-treeview.e-rtl .e-navigable .e-anchor-wrap {
  padding: 0 12px 0 0;
}
.e-treeview.e-rtl .e-navigable .e-nav-wrapper {
  padding: 0;
}
.e-treeview.e-rtl .e-navigable .e-list-icon,
.e-treeview.e-rtl .e-navigable .e-list-img,
.e-treeview.e-rtl .e-navigable .e-list-icon + .e-list-img {
  margin: 0 0 0 8px;
}
.e-treeview.e-rtl .e-ul {
  padding: 0 12px 0 0;
}
.e-treeview.e-rtl .e-list-item .e-ul {
  padding: 0 20px 0 0;
}
.e-treeview.e-rtl .e-text-content {
  padding: 0 20px 0 0;
}
.e-treeview.e-rtl .e-checkbox-wrapper {
  margin: 0 12px 0 0;
}
.e-treeview.e-rtl .e-checkbox-wrapper + .e-list-icon, .e-treeview.e-rtl .e-checkbox-wrapper + .e-list-img {
  margin: 0 8px 0 0;
}
.e-treeview.e-rtl .e-list-icon,
.e-treeview.e-rtl .e-list-img {
  margin: 0 8px 0 0;
}
.e-treeview.e-rtl .e-list-icon + .e-list-icon, .e-treeview.e-rtl .e-list-icon + .e-list-img,
.e-treeview.e-rtl .e-list-img + .e-list-icon,
.e-treeview.e-rtl .e-list-img + .e-list-img {
  margin: 0 8px 0 0;
}
.e-treeview.e-rtl .e-icon-collapsible,
.e-treeview.e-rtl .e-icon-expandable {
  margin: 0 -20px 0 0;
}
.e-treeview.e-rtl .e-sibling::before {
  right: 0;
}
.e-treeview.e-rtl.e-drag-item .e-icons.e-drop-next {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.e-treeview.e-rtl.e-drag-item .e-text-content {
  float: right;
}
.e-treeview.e-rtl.e-drag-item .e-drop-count {
  margin-right: -12px;
}
.e-treeview.e-rtl div.e-icons {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.e-treeview.e-disabled .e-fullrow,
.e-treeview.e-disabled .e-icons,
.e-treeview.e-disabled .e-text-content,
.e-treeview.e-disabled .e-list-img,
.e-treeview.e-disabled .e-list-icon {
  cursor: auto;
}
.e-treeview.e-disabled .e-list-url {
  cursor: default;
  pointer-events: none;
}
.e-treeview.e-interaction.e-fullrow-wrap .e-text-content {
  pointer-events: auto;
}

/* stylelint-enable property-no-vendor-prefix */
/* stylelint-disable property-no-vendor-prefix */
.e-treeview {
  -webkit-tap-highlight-color: transparent;
  /* stylelint-enable property-no-vendor-prefix */
}
.e-treeview.e-disabled {
  opacity: 0.5;
}
.e-treeview .e-text-content,
.e-treeview .e-fullrow {
  border-color: transparent;
}
.e-treeview .e-list-text {
  color: #212529;
  font-size: 14px;
}
.e-treeview .e-list-icon,
.e-treeview .e-list-img {
  font-size: 20px;
}
.e-treeview .e-icon-collapsible,
.e-treeview .e-icon-expandable {
  color: #6c757d;
}
.e-treeview .e-icon-collapsible::before,
.e-treeview .e-icon-expandable::before {
  font-size: 20px;
}
.e-treeview .e-list-item.e-hover, .e-treeview .e-list-item.e-node-focus {
  background: transparent;
}
.e-treeview .e-list-item.e-hover > .e-fullrow, .e-treeview .e-list-item.e-node-focus > .e-fullrow {
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.e-treeview .e-list-item.e-hover > .e-text-content, .e-treeview .e-list-item.e-node-focus > .e-text-content {
  color: #212529;
}
.e-treeview .e-list-item.e-hover > .e-text-content .e-list-text, .e-treeview .e-list-item.e-node-focus > .e-text-content .e-list-text {
  color: #212529;
}
.e-treeview .e-list-item.e-hover > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-hover > .e-text-content .e-icon-expandable, .e-treeview .e-list-item.e-node-focus > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-node-focus > .e-text-content .e-icon-expandable {
  color: #343a40;
}
.e-treeview .e-list-item.e-active {
  background: transparent;
}
.e-treeview .e-list-item.e-active > .e-fullrow {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.e-treeview .e-list-item.e-active.e-animation-active > .e-fullrow {
  background-color: transparent;
  border-color: transparent;
}
.e-treeview .e-list-item.e-active.e-animation-active > .e-text-content {
  color: #212529;
}
.e-treeview .e-list-item.e-active.e-animation-active > .e-text-content .e-list-text {
  color: #212529;
}
.e-treeview .e-list-item.e-active > .e-text-content {
  color: #fff;
}
.e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
  color: #fff;
}
.e-treeview .e-list-item.e-active > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-active > .e-text-content .e-icon-expandable {
  color: #fff;
}
.e-treeview .e-list-item.e-active > .e-text-content .e-check {
  border-color: #fff;
}
.e-treeview .e-list-item.e-active > .e-text-content .e-stop {
  border-color: #fff;
}
.e-treeview .e-list-item.e-active.e-hover > .e-fullrow, .e-treeview .e-list-item.e-active.e-node-focus > .e-fullrow {
  background-color: #0262ef;
  border-color: #0d6efd;
}
.e-treeview .e-list-item.e-active.e-hover > .e-text-content, .e-treeview .e-list-item.e-active.e-node-focus > .e-text-content {
  color: #fff;
}
.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-list-text, .e-treeview .e-list-item.e-active.e-node-focus > .e-text-content .e-list-text {
  color: #fff;
}
.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-icon-expandable, .e-treeview .e-list-item.e-active.e-node-focus > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-active.e-node-focus > .e-text-content .e-icon-expandable {
  color: #fff;
}
.e-treeview .e-list-item.e-editing.e-active > .e-fullrow, .e-treeview .e-list-item.e-editing.e-hover > .e-fullrow, .e-treeview .e-list-item.e-editing.e-node-focus > .e-fullrow {
  background-color: transparent;
  border-color: transparent;
}
.e-treeview .e-list-item.e-editing.e-active > .e-text-content, .e-treeview .e-list-item.e-editing.e-hover > .e-text-content, .e-treeview .e-list-item.e-editing.e-node-focus > .e-text-content {
  color: #212529;
}
.e-treeview .e-list-item.e-editing.e-active > .e-text-content .e-list-text, .e-treeview .e-list-item.e-editing.e-hover > .e-text-content .e-list-text, .e-treeview .e-list-item.e-editing.e-node-focus > .e-text-content .e-list-text {
  color: #212529;
}
.e-treeview .e-list-item.e-editing.e-active > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-editing.e-active > .e-text-content .e-icon-expandable, .e-treeview .e-list-item.e-editing.e-hover > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-editing.e-hover > .e-text-content .e-icon-expandable, .e-treeview .e-list-item.e-editing.e-node-focus > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-editing.e-node-focus > .e-text-content .e-icon-expandable {
  color: #6c757d;
}
.e-treeview .e-list-item.e-disable > .e-text-content,
.e-treeview .e-list-item.e-disable > .e-fullrow {
  color: #adb5bd;
}
.e-treeview .e-list-item.e-disable > .e-text-content .e-list-text,
.e-treeview .e-list-item.e-disable > .e-fullrow .e-list-text {
  color: #adb5bd;
}
.e-treeview .e-list-item.e-disable > .e-text-content > .e-icon-collapsible,
.e-treeview .e-list-item.e-disable > .e-text-content > .e-icon-expandable {
  color: #adb5bd;
}
.e-treeview .e-sibling {
  border-top: 2px solid #0d6efd;
}
.e-treeview .e-sibling::before {
  background: #0d6efd;
}
.e-treeview .e-popup .e-content {
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.e-treeview .e-popup.e-select .e-icons {
  border-color: #e9ecef;
}
.e-treeview .e-popup .e-downtail::before {
  border-top-color: #e9ecef;
}
.e-treeview .e-popup .e-downtail::after {
  border-top-color: #e9ecef;
}
.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-hover > .e-text-content, .e-treeview:not(.e-fullrow-wrap) .e-list-item.e-node-focus > .e-text-content {
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active > .e-text-content {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active.e-hover > .e-text-content, .e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active.e-node-focus > .e-text-content {
  background-color: #0262ef;
  border-color: #0d6efd;
}
.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-editing.e-active > .e-text-content, .e-treeview:not(.e-fullrow-wrap) .e-list-item.e-editing.e-hover > .e-text-content, .e-treeview:not(.e-fullrow-wrap) .e-list-item.e-editing.e-node-focus > .e-text-content {
  background-color: transparent;
  border-color: transparent;
}
.e-treeview.e-fullrow-wrap .e-text-content {
  border-color: transparent;
}
.e-treeview.e-drag-item {
  background-color: #dee2e6;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.e-treeview.e-drag-item .e-icon-collapsible::before,
.e-treeview.e-drag-item .e-icon-expandable::before {
  font-size: 20px;
}
.e-treeview.e-drag-item .e-list-text {
  color: #343a40;
}
.e-treeview.e-drag-item .e-icons {
  color: #343a40;
}
.e-treeview.e-drag-item .e-drop-count {
  background-color: #0d6efd;
  border-color: #fff;
  color: #fff;
}
.e-treeview.e-drag-item.e-rtl .e-sibling {
  border: 1px solid #0d6efd;
}

.e-bigger .e-treeview .e-ul,
.e-treeview.e-bigger .e-ul {
  padding: 0 0 0 16px;
}
.e-bigger .e-treeview .e-list-item .e-ul,
.e-treeview.e-bigger .e-list-item .e-ul {
  padding: 0 0 0 20px;
}
.e-bigger .e-treeview .e-list-item .e-text-content,
.e-treeview.e-bigger .e-list-item .e-text-content {
  padding: 0 0 0 24px;
}
.e-bigger .e-treeview .e-list-item .e-list-icon,
.e-bigger .e-treeview .e-list-item .e-list-img,
.e-treeview.e-bigger .e-list-item .e-list-icon,
.e-treeview.e-bigger .e-list-item .e-list-img {
  height: 24px;
  width: 24px;
}
.e-bigger .e-treeview .e-list-item .e-small.e-css.e-checkbox-wrapper .e-frame,
.e-treeview.e-bigger .e-list-item .e-small.e-css.e-checkbox-wrapper .e-frame {
  height: 20px;
  line-height: 17px;
  width: 20px;
}
.e-bigger .e-treeview .e-list-item .e-small.e-css.e-checkbox-wrapper .e-check, .e-bigger .e-treeview .e-list-item .e-small.e-css.e-checkbox-wrapper .e-stop,
.e-treeview.e-bigger .e-list-item .e-small.e-css.e-checkbox-wrapper .e-check,
.e-treeview.e-bigger .e-list-item .e-small.e-css.e-checkbox-wrapper .e-stop {
  font-size: 12px;
}
.e-bigger .e-treeview .e-list-item .e-small.e-css.e-checkbox-wrapper .e-stop,
.e-treeview.e-bigger .e-list-item .e-small.e-css.e-checkbox-wrapper .e-stop {
  line-height: 17px;
}
.e-bigger .e-treeview .e-list-item .e-checkbox-wrapper + .e-list-text,
.e-treeview.e-bigger .e-list-item .e-checkbox-wrapper + .e-list-text {
  padding: 0 12px;
}
.e-bigger .e-treeview .e-icon-collapsible,
.e-bigger .e-treeview .e-icon-expandable,
.e-treeview.e-bigger .e-icon-collapsible,
.e-treeview.e-bigger .e-icon-expandable {
  height: 24px;
  width: 24px;
  margin: 0 0 0 -24px;
}
.e-bigger .e-treeview.e-drag-item .e-drop-count,
.e-treeview.e-bigger.e-drag-item .e-drop-count {
  padding: 3px 5px 4px;
}
.e-bigger .e-treeview .e-navigable .e-list-text,
.e-treeview.e-bigger .e-navigable .e-list-text {
  padding: 0;
  width: 100%;
}
.e-bigger .e-treeview .e-navigable .e-checkbox-wrapper + .e-list-text,
.e-treeview.e-bigger .e-navigable .e-checkbox-wrapper + .e-list-text {
  padding: 0;
}
.e-bigger .e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-bigger .e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img,
.e-treeview.e-bigger .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview.e-bigger .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 12px 0 8px;
}
.e-bigger .e-treeview .e-navigable .e-anchor-wrap,
.e-treeview.e-bigger .e-navigable .e-anchor-wrap {
  padding: 0 0 0 8px;
}
.e-bigger .e-treeview .e-navigable .e-list-icon,
.e-bigger .e-treeview .e-navigable .e-list-img,
.e-bigger .e-treeview .e-navigable .e-list-icon + .e-list-img,
.e-treeview.e-bigger .e-navigable .e-list-icon,
.e-treeview.e-bigger .e-navigable .e-list-img,
.e-treeview.e-bigger .e-navigable .e-list-icon + .e-list-img {
  margin: 0 8px 0 0;
}
.e-bigger .e-treeview .e-fullrow,
.e-treeview.e-bigger .e-fullrow {
  height: 42px;
}
.e-bigger .e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-checkbox-wrapper + .e-list-text {
  max-width: calc(100% - 29px);
}
.e-bigger .e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-text, .e-bigger .e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-img + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-checkbox-wrapper + .e-list-img + .e-list-text {
  max-width: calc(100% - 65px);
}
.e-bigger .e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-img + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-img + .e-list-text {
  max-width: calc(100% - 101px);
}
.e-bigger .e-treeview.e-text-wrap .e-list-icon + .e-list-text,
.e-bigger .e-treeview.e-text-wrap .e-list-img + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-list-icon + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-list-img + .e-list-text {
  max-width: calc(100% - 35px);
}
.e-bigger .e-treeview.e-text-wrap .e-list-icon + .e-list-img + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-list-icon + .e-list-img + .e-list-text {
  max-width: calc(100% - 71px);
}
.e-bigger .e-treeview .e-list-text,
.e-treeview.e-bigger .e-list-text {
  line-height: 40px;
  min-height: 40px;
}
.e-bigger .e-treeview .e-list-text .e-input-group,
.e-treeview.e-bigger .e-list-text .e-input-group {
  height: 40px;
}
.e-bigger .e-treeview .e-list-text .e-input-group .e-input,
.e-treeview.e-bigger .e-list-text .e-input-group .e-input {
  height: 36px;
}
.e-bigger .e-treeview .e-checkbox-wrapper,
.e-treeview.e-bigger .e-checkbox-wrapper {
  margin: 0 0 0 12px;
}
.e-bigger .e-treeview .e-checkbox-wrapper + .e-list-icon, .e-bigger .e-treeview .e-checkbox-wrapper + .e-list-img,
.e-treeview.e-bigger .e-checkbox-wrapper + .e-list-icon,
.e-treeview.e-bigger .e-checkbox-wrapper + .e-list-img {
  margin: 0 0 0 12px;
}
.e-bigger .e-treeview .e-list-icon,
.e-bigger .e-treeview .e-list-img,
.e-treeview.e-bigger .e-list-icon,
.e-treeview.e-bigger .e-list-img {
  margin: 0 0 0 12px;
}
.e-bigger .e-treeview .e-list-icon + .e-list-text,
.e-bigger .e-treeview .e-list-img + .e-list-text,
.e-treeview.e-bigger .e-list-icon + .e-list-text,
.e-treeview.e-bigger .e-list-img + .e-list-text {
  padding: 0 12px;
}
.e-bigger .e-treeview .e-list-icon + .e-list-icon, .e-bigger .e-treeview .e-list-icon + .e-list-img,
.e-bigger .e-treeview .e-list-img + .e-list-icon,
.e-bigger .e-treeview .e-list-img + .e-list-img,
.e-treeview.e-bigger .e-list-icon + .e-list-icon,
.e-treeview.e-bigger .e-list-icon + .e-list-img,
.e-treeview.e-bigger .e-list-img + .e-list-icon,
.e-treeview.e-bigger .e-list-img + .e-list-img {
  margin: 0 0 0 12px;
}
.e-bigger .e-treeview .e-icon-collapsible::before,
.e-bigger .e-treeview .e-icon-expandable::before,
.e-treeview.e-bigger .e-icon-collapsible::before,
.e-treeview.e-bigger .e-icon-expandable::before {
  padding: 0px;
}
.e-bigger .e-treeview.e-drag-item .e-text-content,
.e-treeview.e-bigger.e-drag-item .e-text-content {
  padding-left: 24px;
}
.e-bigger .e-treeview.e-drag-item .e-icon-collapsible,
.e-bigger .e-treeview.e-drag-item .e-icon-expandable,
.e-treeview.e-bigger.e-drag-item .e-icon-collapsible,
.e-treeview.e-bigger.e-drag-item .e-icon-expandable {
  font-size: 24px;
}
.e-bigger .e-treeview.e-drag-item .e-icon-collapsible::before,
.e-bigger .e-treeview.e-drag-item .e-icon-expandable::before,
.e-treeview.e-bigger.e-drag-item .e-icon-collapsible::before,
.e-treeview.e-bigger.e-drag-item .e-icon-expandable::before {
  font-size: 24px;
}
.e-bigger .e-treeview.e-rtl .e-ul,
.e-treeview.e-bigger.e-rtl .e-ul {
  padding: 0 16px 0 0;
}
.e-bigger .e-treeview.e-rtl .e-list-item .e-ul,
.e-treeview.e-bigger.e-rtl .e-list-item .e-ul {
  padding: 0 20px 0 0;
}
.e-bigger .e-treeview.e-rtl .e-list-item .e-text-content,
.e-treeview.e-bigger.e-rtl .e-list-item .e-text-content {
  padding: 0 24px 0 0;
}
.e-bigger .e-treeview.e-rtl .e-icon-collapsible,
.e-bigger .e-treeview.e-rtl .e-icon-expandable,
.e-treeview.e-bigger.e-rtl .e-icon-collapsible,
.e-treeview.e-bigger.e-rtl .e-icon-expandable {
  margin: 0 -24px 0 0;
}
.e-bigger .e-treeview.e-rtl.e-drag-item .e-text-content,
.e-treeview.e-bigger.e-rtl.e-drag-item .e-text-content {
  padding-left: 0;
  padding-right: 24px;
}
.e-bigger .e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img,
.e-treeview.e-bigger.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 6px 0 10px;
}
.e-bigger .e-treeview.e-rtl .e-navigable .e-anchor-wrap,
.e-treeview.e-bigger.e-rtl .e-navigable .e-anchor-wrap {
  padding: 0 8px 0 0;
}
.e-bigger .e-treeview.e-rtl .e-navigable .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-navigable .e-list-img,
.e-bigger .e-treeview.e-rtl .e-navigable .e-list-icon + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-navigable .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-navigable .e-list-img,
.e-treeview.e-bigger.e-rtl .e-navigable .e-list-icon + .e-list-img {
  margin: 0 0 0 8px;
}
.e-bigger .e-treeview.e-rtl .e-checkbox-wrapper,
.e-treeview.e-bigger.e-rtl .e-checkbox-wrapper {
  margin: 0 12px 0 0;
}
.e-bigger .e-treeview.e-rtl .e-checkbox-wrapper + .e-list-icon, .e-bigger .e-treeview.e-rtl .e-checkbox-wrapper + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-checkbox-wrapper + .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-checkbox-wrapper + .e-list-img {
  margin: 0 12px 0 0;
}
.e-bigger .e-treeview.e-rtl .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-list-img,
.e-treeview.e-bigger.e-rtl .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-list-img {
  margin: 0 12px 0 0;
}
.e-bigger .e-treeview.e-rtl .e-list-icon + .e-list-icon, .e-bigger .e-treeview.e-rtl .e-list-icon + .e-list-img,
.e-bigger .e-treeview.e-rtl .e-list-img + .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-list-img + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-list-icon + .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-list-icon + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-list-img + .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-list-img + .e-list-img {
  margin: 0 12px 0 0;
}

.e-bigger .e-treeview .e-list-text,
.e-treeview.e-bigger .e-list-text {
  font-size: 16px;
}
.e-bigger .e-treeview .e-icon-collapsible::before,
.e-bigger .e-treeview .e-icon-expandable::before,
.e-treeview.e-bigger .e-icon-collapsible::before,
.e-treeview.e-bigger .e-icon-expandable::before {
  font-size: 24px;
}
/* stylelint-disable-line no-empty-source */
/* stylelint-disable-line no-empty-source */
ejs-sidebar {
  display: none;
}

.e-sidebar-display {
  display: block;
}

.e-sidebar {
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-tap-highlight-color: transparent;
  background: #f8f9fa;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  -webkit-transition: none;
  transition: none;
  vertical-align: middle;
  visibility: hidden;
  will-change: transform;
}
.e-sidebar.e-right.e-open, .e-sidebar.e-left.e-open {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  visibility: visible;
}
.e-sidebar.e-right.e-close, .e-sidebar.e-left.e-close {
  -webkit-box-shadow: none;
          box-shadow: none;
  visibility: hidden;
}
.e-sidebar.e-right.e-close {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.e-sidebar.e-left.e-close {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.e-sidebar.e-left.e-transition.e-close, .e-sidebar.e-right.e-transition.e-close, .e-sidebar.e-right.e-close {
  -webkit-transition: visibility 500ms, -webkit-transform 0.5s ease;
  transition: visibility 500ms, -webkit-transform 0.5s ease;
  transition: transform 0.5s ease, visibility 500ms;
  transition: transform 0.5s ease, visibility 500ms, -webkit-transform 0.5s ease;
}
.e-sidebar.e-right {
  border-left: 0 solid transparent;
  left: auto;
  right: 0;
  top: 0;
}
.e-sidebar.e-left {
  border-right: 0 solid transparent;
  left: 0;
  right: auto;
  top: 0;
}
.e-sidebar.e-left.e-close.e-dock, .e-sidebar.e-right.e-close.e-dock {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: width 0.5s ease, visibility 500ms;
  transition: width 0.5s ease, visibility 500ms;
  visibility: visible;
}
.e-sidebar.e-left.e-open.e-dock.e-blazor-dock, .e-sidebar.e-right.e-open.e-dock.e-blazor-dock {
  -webkit-transition: width 0.5s ease, visibility 500ms;
  transition: width 0.5s ease, visibility 500ms;
}
.e-sidebar.e-left.e-open.e-disable-animation, .e-sidebar.e-right.e-open.e-disable-animation, .e-sidebar.e-right.e-close.e-disable-animation, .e-sidebar.e-left.e-close.e-disable-animation {
  -webkit-transition: none;
  transition: none;
}
.e-sidebar.e-visibility {
  visibility: hidden;
}
.e-sidebar.e-over {
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
}

.e-sidebarcontainer.e-sidebar.e-over, .e-sidebarcontainer.e-sidebar.e-auto, .e-sidebarcontainer.e-sidebar.e-push, .e-sidebarcontainer.e-sidebar.e-slide + div {
  -webkit-transition: margin 0.5s ease, -webkit-transform 0.5s ease;
  transition: margin 0.5s ease, -webkit-transform 0.5s ease;
  transition: margin 0.5s ease, transform 0.5s ease;
  transition: margin 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
}
.e-sidebarcontainer.e-left.e-open.e-auto + div {
  margin-right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  margin-left: var(--sidebar-width);
}
.e-sidebarcontainer.e-left.e-close.e-auto + div {
  margin-right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  margin-left: 0;
}
.e-sidebarcontainer.e-right.e-open.e-auto + div {
  margin-left: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  margin-right: var(--sidebar-width);
}
.e-sidebarcontainer.e-right.e-close.e-auto + div {
  margin-right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  margin-left: 0;
}
.e-sidebarcontainer.e-left.e-open.e-push + div {
  margin-right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  margin-left: var(--sidebar-width);
}
.e-sidebarcontainer.e-left.e-close.e-push + div {
  margin-right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  margin-left: 0;
}
.e-sidebarcontainer.e-right.e-open.e-push + div {
  margin-left: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  margin-right: var(--sidebar-width);
}
.e-sidebarcontainer.e-right.e-close.e-push + div {
  margin-right: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  margin-left: 0;
}
.e-sidebarcontainer.e-left.e-open.e-slide + div {
  margin-left: 0;
  -webkit-transform: translate(var(--sidebar-width));
          transform: translate(var(--sidebar-width));
}
.e-sidebarcontainer.e-left.e-close.e-slide + div {
  margin-left: 0;
  -webkit-transform: translate(0);
          transform: translate(0);
}
.e-sidebarcontainer.e-right.e-open.e-slide + div {
  margin-right: 0;
  margin-left: 0;
  -webkit-transform: translate(calc(-1 * var(--sidebar-width)));
          transform: translate(calc(-1 * var(--sidebar-width)));
}
.e-sidebarcontainer.e-right.e-close.e-slide + div {
  margin-right: 0;
  margin-left: 0;
  -webkit-transform: translate(0);
          transform: translate(0);
}

.e-sidebar-absolute {
  position: absolute;
}

.e-sidebar-context {
  overflow-x: hidden;
  position: relative;
}

.e-backdrop,
.e-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  pointer-events: auto;
  top: 0;
  z-index: 999;
}

.e-backdrop {
  width: auto;
}

.e-sidebar-overlay {
  position: fixed;
  width: 100%;
}

.e-content-animation {
  transition: margin 0.5s ease, -webkit-transform 0.5s ease, transform 0.5s ease;
}
.e-content-animation.e-overlay {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.e-disable-interaction {
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-sidebar-overflow {
  overflow-x: hidden;
}

.e-content-placeholder.e-sidebar.e-placeholder-sidebar {
  left: 0;
  position: fixed;
  right: auto;
  top: 0;
  visibility: visible;
}

.e-menu-wrapper ul.e-vertical .e-menu-item .e-caret::before,
.e-menu-container ul.e-vertical .e-menu-item .e-caret::before {
  content: "\e748";
}
.e-menu-wrapper ul .e-menu-item .e-caret::before,
.e-menu-container ul .e-menu-item .e-caret::before {
  content: "\e729";
}

.e-menu-wrapper .e-ul .e-menu-item .e-caret::before,
.e-menu-container .e-ul .e-menu-item .e-caret::before {
  content: "\e748";
}

.e-menu-wrapper.e-hamburger .e-menu-header .e-menu-icon::before,
.e-menu-container.e-hamburger .e-menu-header .e-menu-icon::before {
  content: "\e799";
}
.e-menu-wrapper.e-hamburger.e-close-icon .e-menu-header .e-menu-icon::before,
.e-menu-container.e-hamburger.e-close-icon .e-menu-header .e-menu-icon::before {
  content: "\e7e7";
}
.e-menu-wrapper.e-hamburger .e-vertical .e-menu-item .e-caret::before, .e-menu-wrapper.e-hamburger .e-menu-item .e-caret::before,
.e-menu-container.e-hamburger .e-vertical .e-menu-item .e-caret::before,
.e-menu-container.e-hamburger .e-menu-item .e-caret::before {
  content: "\e729";
}
.e-menu-wrapper.e-rtl.e-hamburger ul.e-ul .e-caret::before,
.e-menu-container.e-rtl.e-hamburger ul.e-ul .e-caret::before {
  content: "\e729";
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-nav-left-arrow::before, .e-menu-wrapper .e-menu-hscroll.e-hscroll .e-nav-right-arrow::before,
.e-menu-container .e-menu-hscroll.e-hscroll .e-nav-left-arrow::before,
.e-menu-container .e-menu-hscroll.e-hscroll .e-nav-right-arrow::before {
  content: "\e748";
}
.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-nav-up-arrow::before, .e-menu-wrapper .e-menu-vscroll.e-vscroll .e-nav-down-arrow::before,
.e-menu-container .e-menu-vscroll.e-vscroll .e-nav-up-arrow::before,
.e-menu-container .e-menu-vscroll.e-vscroll .e-nav-down-arrow::before {
  content: "\e729";
}

.e-rtl.e-menu-wrapper ul.e-vertical .e-caret::before, .e-rtl.e-menu-wrapper ul.e-ul .e-caret::before,
.e-rtl.e-menu-container ul.e-vertical .e-caret::before,
.e-rtl.e-menu-container ul.e-ul .e-caret::before,
.e-bigger .e-rtl.e-menu-wrapper ul.e-vertical .e-caret::before,
.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-caret::before,
.e-bigger.e-rtl.e-menu-wrapper ul.e-vertical .e-caret::before,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-caret::before,
.e-bigger .e-rtl.e-menu-container ul.e-vertical .e-caret::before,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-caret::before,
.e-bigger.e-rtl.e-menu-container ul.e-vertical .e-caret::before,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-caret::before {
  content: "\e765";
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-ul .e-caret::before,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-ul .e-caret::before,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-ul .e-caret::before,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-ul .e-caret::before {
  content: "\e729";
}

.e-menu-wrapper,
.e-menu-container {
  border: none;
  border-radius: 4px;
  display: inline-block;
  line-height: 0;
}
.e-menu-wrapper .e-menu-vscroll,
.e-menu-container .e-menu-vscroll {
  height: inherit;
}
.e-menu-wrapper ul,
.e-menu-container ul {
  font-weight: 400;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 14px;
  padding: 0;
  text-align: left;
  white-space: nowrap;
}
.e-menu-wrapper ul.e-ul, .e-menu-wrapper ul.e-ul *,
.e-menu-container ul.e-ul,
.e-menu-container ul.e-ul * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.e-menu-wrapper ul.e-ul:focus, .e-menu-wrapper ul.e-ul *:focus,
.e-menu-container ul.e-ul:focus,
.e-menu-container ul.e-ul *:focus {
  outline: none;
}
.e-menu-wrapper ul.e-vertical,
.e-menu-container ul.e-vertical {
  min-width: 120px;
}
.e-menu-wrapper ul.e-vertical .e-menu-item,
.e-menu-container ul.e-vertical .e-menu-item {
  display: list-item;
}
.e-menu-wrapper ul.e-vertical .e-separator,
.e-menu-container ul.e-vertical .e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
}
.e-menu-wrapper ul.e-menu,
.e-menu-container ul.e-menu {
  display: inline-block;
}
.e-menu-wrapper ul.e-menu:not(.e-vertical),
.e-menu-container ul.e-menu:not(.e-vertical) {
  padding: 0;
}
.e-menu-wrapper ul.e-menu:not(.e-vertical) .e-separator,
.e-menu-container ul.e-menu:not(.e-vertical) .e-separator {
  border-right-style: solid;
  border-right-width: 1px;
  padding: 0;
}
.e-menu-wrapper ul.e-menu .e-menu-item .e-menu-icon,
.e-menu-container ul.e-menu .e-menu-item .e-menu-icon {
  width: 1em;
}
.e-menu-wrapper ul .e-menu-item,
.e-menu-container ul .e-menu-item {
  cursor: pointer;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  vertical-align: top;
  white-space: nowrap;
}
.e-menu-wrapper ul .e-menu-item.e-menu-hide,
.e-menu-container ul .e-menu-item.e-menu-hide {
  display: none;
}
.e-menu-wrapper ul .e-menu-item.e-menu-header,
.e-menu-container ul .e-menu-item.e-menu-header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.e-menu-wrapper ul .e-menu-item .e-menu-url,
.e-menu-container ul .e-menu-item .e-menu-url {
  text-decoration: none;
}
.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-menu-container ul .e-menu-item .e-menu-icon {
  display: inline-block;
  vertical-align: middle;
}
.e-menu-wrapper ul .e-menu-item.e-separator,
.e-menu-container ul .e-menu-item.e-separator {
  cursor: auto;
  line-height: normal;
  pointer-events: none;
}
.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-menu-container ul .e-menu-item .e-menu-icon {
  font-size: 18px;
  height: auto;
  line-height: 32px;
  margin-right: 8px;
  text-align: center;
  margin-bottom: 4px;
}
.e-menu-wrapper ul .e-menu-item .e-caret,
.e-menu-container ul .e-menu-item .e-caret {
  font-size: 14px;
  height: auto;
  line-height: 35px;
  position: absolute;
  right: 8px;
  top: 0;
  width: auto;
}
.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-menu-container ul .e-menu-item.e-menu-caret-icon {
  padding-right: 26px;
}
.e-menu-wrapper ul .e-menu-item.e-disabled,
.e-menu-container ul .e-menu-item.e-disabled {
  cursor: auto;
  opacity: 0.65;
  pointer-events: none;
}

.e-menu-wrapper .e-ul,
.e-menu-container .e-ul {
  font-size: 14px;
  padding: 0;
  min-width: 120px;
}
.e-menu-wrapper .e-ul .e-menu-item,
.e-menu-container .e-ul .e-menu-item {
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  display: list-item;
}
.e-menu-wrapper .e-ul .e-menu-item .e-menu-url,
.e-menu-container .e-ul .e-menu-item .e-menu-url {
  display: inline-block;
  min-width: 120px;
}
.e-menu-wrapper .e-ul .e-menu-item .e-menu-icon,
.e-menu-container .e-ul .e-menu-item .e-menu-icon {
  font-size: 18px;
  line-height: 30px;
  margin-right: 8px;
  width: 1em;
}
.e-menu-wrapper .e-ul .e-menu-item .e-caret,
.e-menu-container .e-ul .e-menu-item .e-caret {
  line-height: 30px;
  margin-left: 16px;
  margin-right: 0;
  position: absolute;
  right: 7px;
}
.e-menu-wrapper .e-ul .e-menu-item.e-menu-caret-icon,
.e-menu-container .e-ul .e-menu-item.e-menu-caret-icon {
  padding-right: 36px;
}
.e-menu-wrapper .e-ul .e-menu-item.e-separator,
.e-menu-container .e-ul .e-menu-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
  margin: 4px 0;
}
.e-menu-wrapper .e-ul .e-menu-item .e-caret,
.e-menu-container .e-ul .e-menu-item .e-caret {
  font-size: 14px;
}

.e-menu-wrapper.e-menu-icon-right .e-menu-header .e-menu-icon,
.e-menu-container.e-menu-icon-right .e-menu-header .e-menu-icon {
  float: right;
}
.e-menu-wrapper.e-menu-icon-right .e-menu-header .e-menu-title,
.e-menu-container.e-menu-icon-right .e-menu-header .e-menu-title {
  padding: 0 16px;
}
.e-menu-wrapper .e-menu-header,
.e-menu-container .e-menu-header {
  display: none;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  font-weight: 400;
  height: 32px;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
}
.e-menu-wrapper .e-menu-header .e-menu-title, .e-menu-wrapper .e-menu-header .e-menu-icon,
.e-menu-container .e-menu-header .e-menu-title,
.e-menu-container .e-menu-header .e-menu-icon {
  display: inline-block;
  line-height: inherit;
}
.e-menu-wrapper .e-menu-header .e-menu-icon,
.e-menu-container .e-menu-header .e-menu-icon {
  cursor: pointer;
  float: left;
  outline: none;
  padding: 0 16px;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device),
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) {
  padding: 0 28px;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav {
  width: 28px;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus, .e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover {
  border: 0;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-scroll-right-nav,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-scroll-right-nav {
  border-left: none;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-scroll-left-nav,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-scroll-left-nav {
  border-right: none;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-hscroll-bar,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-hscroll-bar {
  overflow-y: hidden;
}
.e-menu-wrapper .e-scroll-nav .e-nav-arrow,
.e-menu-container .e-scroll-nav .e-nav-arrow {
  font-size: 16px;
}
.e-menu-wrapper .e-scroll-nav .e-nav-arrow.e-nav-left-arrow, .e-menu-wrapper .e-scroll-nav .e-nav-arrow.e-nav-up-arrow,
.e-menu-container .e-scroll-nav .e-nav-arrow.e-nav-left-arrow,
.e-menu-container .e-scroll-nav .e-nav-arrow.e-nav-up-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.e-menu-wrapper.e-rtl .e-scroll-nav .e-nav-arrow.e-nav-left-arrow,
.e-menu-container.e-rtl .e-scroll-nav .e-nav-arrow.e-nav-left-arrow {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.e-menu-wrapper.e-rtl .e-scroll-nav .e-nav-arrow.e-nav-right-arrow,
.e-menu-container.e-rtl .e-scroll-nav .e-nav-arrow.e-nav-right-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.e-menu-wrapper.e-popup.e-menu-popup,
.e-menu-container.e-popup.e-menu-popup {
  border: 1px solid #dee2e6;
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
  overflow: hidden;
  position: absolute;
}
.e-menu-wrapper .e-menu-vscroll.e-vscroll:not(.e-scroll-device),
.e-menu-container .e-menu-vscroll.e-vscroll:not(.e-scroll-device) {
  padding: 28px 0;
}
.e-menu-wrapper .e-menu-vscroll.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-down-nav,
.e-menu-container .e-menu-vscroll.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-down-nav {
  -webkit-transform: none;
          transform: none;
  width: 100%;
}
.e-menu-wrapper .e-menu-vscroll.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-down-nav .e-nav-down-arrow,
.e-menu-container .e-menu-vscroll.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-down-nav .e-nav-down-arrow {
  -webkit-transform: none;
          transform: none;
}
.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-vscroll-bar,
.e-menu-container .e-menu-vscroll.e-vscroll .e-vscroll-bar {
  height: 100%;
  width: inherit;
}
.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-vscroll-bar .e-vscroll-content,
.e-menu-container .e-menu-vscroll.e-vscroll .e-vscroll-bar .e-vscroll-content {
  width: inherit;
}
.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-menu-container .e-menu-vscroll.e-vscroll .e-scroll-nav {
  height: 28px;
}
.e-menu-wrapper.e-scrollable,
.e-menu-container.e-scrollable {
  display: block;
}
.e-menu-wrapper.e-scrollable .e-menu,
.e-menu-container.e-scrollable .e-menu {
  display: block;
  overflow: auto;
}
.e-menu-wrapper.e-contextmenu-container,
.e-menu-container.e-contextmenu-container {
  height: 1px;
}
.e-menu-wrapper.e-contextmenu-container .e-menu-vscroll ul.e-ul,
.e-menu-container.e-contextmenu-container .e-menu-vscroll ul.e-ul {
  width: inherit;
}
.e-menu-wrapper.e-contextmenu-container .e-menu-vscroll,
.e-menu-container.e-contextmenu-container .e-menu-vscroll {
  border: 1px solid #dee2e6;
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
}

.e-menu-container:not(.e-hamburger):not(.e-rtl) .e-menu.e-vertical .e-blankicon {
  padding-left: 34px;
}

.e-rtl.e-menu-wrapper ul .e-menu-item,
.e-rtl.e-menu-container ul .e-menu-item {
  text-align: right;
}
.e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-rtl.e-menu-container ul .e-menu-item .e-menu-icon {
  margin-right: 0;
}
.e-rtl.e-menu-wrapper ul .e-menu-item .e-caret,
.e-rtl.e-menu-container ul .e-menu-item .e-caret {
  margin-left: 0;
  margin-right: 16px;
  right: auto;
}
.e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-rtl.e-menu-container ul .e-menu-item .e-menu-icon {
  margin-left: 8px;
}
.e-rtl.e-menu-wrapper ul .e-menu-item .e-caret,
.e-rtl.e-menu-container ul .e-menu-item .e-caret {
  left: 8px;
}
.e-rtl.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-rtl.e-menu-container ul .e-menu-item.e-menu-caret-icon {
  padding-left: 26px;
  padding-right: 12px;
}
.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-rtl.e-menu-container ul.e-ul .e-menu-item .e-menu-icon {
  margin-left: 8px;
}
.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-rtl.e-menu-container ul.e-ul .e-menu-item .e-caret {
  left: 7px;
}
.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-menu-caret-icon {
  padding-left: 36px;
  padding-right: 12px;
}
.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon,
.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon {
  padding-left: 14px;
}
.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

.e-rtl.e-menu-container:not(.e-hamburger) .e-menu.e-vertical .e-blankicon {
  padding-right: 34px;
}

.e-menu-wrapper.e-hamburger,
.e-menu-container.e-hamburger {
  border: 0;
  display: block;
  position: relative;
}
.e-menu-wrapper.e-hamburger .e-menu-header:not(.e-vertical),
.e-menu-container.e-hamburger .e-menu-header:not(.e-vertical) {
  border: none;
  display: block;
}
.e-menu-wrapper.e-hamburger .e-popup.e-menu-popup,
.e-menu-container.e-hamburger .e-popup.e-menu-popup {
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  display: block;
  position: relative;
  width: 100%;
}
.e-menu-wrapper.e-hamburger ul.e-menu,
.e-menu-container.e-hamburger ul.e-menu {
  border: none;
  overflow-y: auto;
  width: 100%;
  /* stylelint-disable */
  /* stylelint-enable */
}
.e-menu-wrapper.e-hamburger ul.e-menu.e-menu-parent.e-hide-menu,
.e-menu-container.e-hamburger ul.e-menu.e-menu-parent.e-hide-menu {
  display: none;
}
.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item {
  text-indent: 12px;
}
.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-blankicon {
  text-indent: 38px;
}
.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-menu-icon,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item .e-menu-icon {
  display: inline;
  text-indent: 0;
}
.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-menu-hide,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-menu-hide {
  display: none;
}
.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item[aria-expanded=true] .e-caret,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item[aria-expanded=true] .e-caret {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  text-indent: 0;
}
.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item[aria-expanded=false] .e-caret,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item[aria-expanded=false] .e-caret {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  text-indent: 0;
}
.e-menu-wrapper.e-hamburger ul.e-menu:not(.e-vertical),
.e-menu-container.e-hamburger ul.e-menu:not(.e-vertical) {
  border-top: 0;
  display: block;
  padding: 0;
  position: absolute;
}
.e-menu-wrapper.e-hamburger ul.e-ul,
.e-menu-container.e-hamburger ul.e-ul {
  font-size: 14px;
  padding: 0;
}
.e-menu-wrapper.e-hamburger ul.e-ul .e-menu-item,
.e-menu-container.e-hamburger ul.e-ul .e-menu-item {
  line-height: 32px;
  text-indent: inherit;
}
.e-menu-wrapper.e-hamburger ul.e-ul .e-menu-item.e-blankicon,
.e-menu-container.e-hamburger ul.e-ul .e-menu-item.e-blankicon {
  padding: 0;
  text-indent: inherit;
}
.e-menu-wrapper.e-hamburger ul.e-ul .e-menu-item .e-caret,
.e-menu-container.e-hamburger ul.e-ul .e-menu-item .e-caret {
  font-size: 14px;
  right: 8px;
}
.e-menu-wrapper.e-hamburger ul .e-menu-item,
.e-menu-container.e-hamburger ul .e-menu-item {
  display: list-item;
  height: auto;
  padding: 0;
}
.e-menu-wrapper.e-hamburger ul .e-menu-item.e-menu-caret-icon, .e-menu-wrapper.e-hamburger ul .e-menu-item.e-blankicon,
.e-menu-container.e-hamburger ul .e-menu-item.e-menu-caret-icon,
.e-menu-container.e-hamburger ul .e-menu-item.e-blankicon {
  padding: 0;
}
.e-menu-wrapper.e-hamburger ul .e-menu-item .e-menu-url,
.e-menu-container.e-hamburger ul .e-menu-item .e-menu-url {
  display: inline-block;
  min-width: 120px;
  text-indent: 0;
  width: 100%;
}
.e-menu-wrapper.e-hamburger ul .e-menu-item.e-separator,
.e-menu-container.e-hamburger ul .e-menu-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
}

.e-rtl.e-menu-wrapper.e-hamburger ul .e-menu-item .e-menu-caret-icon,
.e-rtl.e-menu-container.e-hamburger ul .e-menu-item .e-menu-caret-icon {
  padding-left: 0;
  padding-right: 0;
}
.e-rtl.e-menu-wrapper.e-hamburger ul .e-menu-item .e-menu-icon,
.e-rtl.e-menu-container.e-hamburger ul .e-menu-item .e-menu-icon {
  margin-left: 0;
  text-indent: inherit;
}
.e-rtl.e-menu-wrapper.e-hamburger ul .e-menu-item .e-caret,
.e-rtl.e-menu-container.e-hamburger ul .e-menu-item .e-caret {
  left: 8px;
  right: auto;
}

.e-menu-container .e-vscroll ul {
  position: relative;
}

.e-menu-wrapper,
.e-menu-container {
  background: #f8f9fa;
}
.e-menu-wrapper .e-menu,
.e-menu-container .e-menu {
  background: inherit;
  color: #212529;
}
.e-menu-wrapper .e-menu.e-menu:not(.e-vertical) .e-separator,
.e-menu-container .e-menu.e-menu:not(.e-vertical) .e-separator {
  border-right-color: #dee2e6;
}
.e-menu-wrapper .e-menu .e-menu-item.e-menu-header,
.e-menu-container .e-menu .e-menu-item.e-menu-header {
  border-bottom-color: #dee2e6;
}
.e-menu-wrapper .e-menu .e-menu-item.e-menu-header,
.e-menu-container .e-menu .e-menu-item.e-menu-header {
  border-bottom-color: #dee2e6;
}
.e-menu-wrapper .e-menu .e-menu-item .e-menu-url,
.e-menu-container .e-menu .e-menu-item .e-menu-url {
  color: #212529;
}
.e-menu-wrapper .e-menu .e-menu-item.e-focused,
.e-menu-container .e-menu .e-menu-item.e-focused {
  color: #212529;
  outline: 0 solid #dee2e6;
  outline-offset: 0;
  background: #dee2e6;
}
.e-menu-wrapper .e-menu .e-menu-item.e-selected, .e-menu-wrapper .e-menu .e-menu-item.e-active-item,
.e-menu-container .e-menu .e-menu-item.e-selected,
.e-menu-container .e-menu .e-menu-item.e-active-item {
  color: #212529;
  outline: 0 solid #dee2e6;
  outline-offset: 0;
  background: #dee2e6;
}
.e-menu-wrapper .e-menu .e-menu-item.e-selected .e-caret, .e-menu-wrapper .e-menu .e-menu-item.e-active-item .e-caret,
.e-menu-container .e-menu .e-menu-item.e-selected .e-caret,
.e-menu-container .e-menu .e-menu-item.e-active-item .e-caret {
  color: #6c757d;
}
.e-menu-wrapper .e-menu .e-menu-item.e-selected .e-menu-icon, .e-menu-wrapper .e-menu .e-menu-item.e-active-item .e-menu-icon,
.e-menu-container .e-menu .e-menu-item.e-selected .e-menu-icon,
.e-menu-container .e-menu .e-menu-item.e-active-item .e-menu-icon {
  color: #6c757d;
}
.e-menu-wrapper .e-menu .e-menu-item.e-separator,
.e-menu-container .e-menu .e-menu-item.e-separator {
  border-bottom-color: #dee2e6;
}
.e-menu-wrapper .e-menu .e-disabled,
.e-menu-container .e-menu .e-disabled {
  color: rgba(33, 37, 41, 0.65);
  opacity: 0.5;
}
.e-menu-wrapper .e-menu .e-disabled .e-menu-icon,
.e-menu-container .e-menu .e-disabled .e-menu-icon {
  color: rgba(108, 117, 125, 0.65);
}
.e-menu-wrapper .e-menu .e-disabled .e-caret,
.e-menu-container .e-menu .e-disabled .e-caret {
  color: rgba(108, 117, 125, 0.65);
}
.e-menu-wrapper .e-menu .e-disabled .e-menu-url,
.e-menu-container .e-menu .e-disabled .e-menu-url {
  color: rgba(33, 37, 41, 0.65);
}

.e-menu-wrapper .e-ul,
.e-menu-container .e-ul {
  background: inherit;
  color: #212529;
}
.e-menu-wrapper .e-ul .e-menu-item .e-menu-url,
.e-menu-container .e-ul .e-menu-item .e-menu-url {
  color: #212529;
}
.e-menu-wrapper .e-ul .e-menu-item.e-focused,
.e-menu-container .e-ul .e-menu-item.e-focused {
  background-color: #e9ecef;
  color: #212529;
  outline: 0 solid #dee2e6;
  outline-offset: 0;
}
.e-menu-wrapper .e-ul .e-menu-item.e-selected, .e-menu-wrapper .e-ul .e-menu-item.e-active-item,
.e-menu-container .e-ul .e-menu-item.e-selected,
.e-menu-container .e-ul .e-menu-item.e-active-item {
  background-color: #0d6efd;
  color: #fff;
  outline: 0 solid #0d6efd;
  outline-offset: 0;
}
.e-menu-wrapper .e-ul .e-menu-item.e-separator,
.e-menu-container .e-ul .e-menu-item.e-separator {
  border-bottom-color: #dee2e6;
}
.e-menu-wrapper.e-menu-popup,
.e-menu-container.e-menu-popup {
  background: #fff;
}
.e-menu-wrapper.e-menu-popup.e-sfcontextmenu .e-menu-vscroll,
.e-menu-container.e-menu-popup.e-sfcontextmenu .e-menu-vscroll {
  background: inherit;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav {
  background: #f8f9fa;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav .e-nav-arrow.e-icons,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav .e-nav-arrow.e-icons {
  color: #6c757d;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:hover,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav:hover {
  background: #dee2e6;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:focus,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav:focus {
  background: #dee2e6;
}
.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:active,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav:active {
  background: #dee2e6;
}
.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-menu-container.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav {
  background: #fff;
  border-color: #dee2e6;
}
.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav .e-icons,
.e-menu-container.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav .e-icons {
  color: #212529;
}
.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:hover, .e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:focus,
.e-menu-container.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:hover,
.e-menu-container.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:focus {
  background: #e9ecef;
}
.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:active,
.e-menu-container.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:active {
  background: #0d6efd;
}
.e-menu-wrapper.e-inherit,
.e-menu-container.e-inherit {
  border: 0;
}
.e-menu-wrapper.e-inherit .e-menu, .e-menu-wrapper.e-inherit .e-menu .e-menu-item .e-menu-icon, .e-menu-wrapper.e-inherit .e-menu .e-menu-item .e-caret,
.e-menu-container.e-inherit .e-menu,
.e-menu-container.e-inherit .e-menu .e-menu-item .e-menu-icon,
.e-menu-container.e-inherit .e-menu .e-menu-item .e-caret {
  color: inherit;
}
.e-menu-wrapper.e-inherit .e-menu,
.e-menu-container.e-inherit .e-menu {
  background: inherit;
}
.e-menu-wrapper.e-inherit .e-menu .e-menu-item.e-selected, .e-menu-wrapper.e-inherit .e-menu .e-menu-item.e-active-item, .e-menu-wrapper.e-inherit .e-menu .e-menu-item.e-focused,
.e-menu-container.e-inherit .e-menu .e-menu-item.e-selected,
.e-menu-container.e-inherit .e-menu .e-menu-item.e-active-item,
.e-menu-container.e-inherit .e-menu .e-menu-item.e-focused {
  background: rgba(0, 0, 0, 0.056);
  color: inherit;
}

.e-menu-wrapper.e-hamburger .e-menu-header,
.e-menu-container.e-hamburger .e-menu-header {
  color: #212529;
}
.e-menu-wrapper.e-hamburger .e-menu-header .e-menu-icon,
.e-menu-container.e-hamburger .e-menu-header .e-menu-icon {
  color: #212529;
}
.e-menu-wrapper.e-hamburger ul,
.e-menu-container.e-hamburger ul {
  color: #212529;
}

.e-bigger .e-menu-wrapper ul,
.e-bigger.e-menu-wrapper ul,
.e-bigger .e-menu-container ul,
.e-bigger.e-menu-container ul {
  font-size: 16px;
}
.e-bigger .e-menu-wrapper ul.e-menu .e-menu-item,
.e-bigger.e-menu-wrapper ul.e-menu .e-menu-item,
.e-bigger .e-menu-container ul.e-menu .e-menu-item,
.e-bigger.e-menu-container ul.e-menu .e-menu-item {
  line-height: 38px;
  padding: 0 16px;
  height: 38px;
}
.e-bigger .e-menu-wrapper ul.e-menu .e-menu-item .e-menu-icon,
.e-bigger.e-menu-wrapper ul.e-menu .e-menu-item .e-menu-icon,
.e-bigger .e-menu-container ul.e-menu .e-menu-item .e-menu-icon,
.e-bigger.e-menu-container ul.e-menu .e-menu-item .e-menu-icon {
  font-size: 22px;
  line-height: 38px;
  margin-right: 12px;
}
.e-bigger .e-menu-wrapper ul.e-menu .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper ul.e-menu .e-menu-item .e-caret,
.e-bigger .e-menu-container ul.e-menu .e-menu-item .e-caret,
.e-bigger.e-menu-container ul.e-menu .e-menu-item .e-caret {
  font-size: 16px;
  line-height: 40px;
  right: 12px;
}
.e-bigger .e-menu-wrapper ul.e-menu .e-menu-item.e-separator,
.e-bigger.e-menu-wrapper ul.e-menu .e-menu-item.e-separator,
.e-bigger .e-menu-container ul.e-menu .e-menu-item.e-separator,
.e-bigger.e-menu-container ul.e-menu .e-menu-item.e-separator {
  padding: 0;
}
.e-bigger .e-menu-wrapper ul.e-menu:not(.e-vertical),
.e-bigger.e-menu-wrapper ul.e-menu:not(.e-vertical),
.e-bigger .e-menu-container ul.e-menu:not(.e-vertical),
.e-bigger.e-menu-container ul.e-menu:not(.e-vertical) {
  padding: 0;
}
.e-bigger .e-menu-wrapper ul.e-menu.e-vertical .e-menu-item.e-separator,
.e-bigger.e-menu-wrapper ul.e-menu.e-vertical .e-menu-item.e-separator,
.e-bigger .e-menu-container ul.e-menu.e-vertical .e-menu-item.e-separator,
.e-bigger.e-menu-container ul.e-menu.e-vertical .e-menu-item.e-separator {
  height: auto;
  line-height: normal;
}
.e-bigger .e-menu-wrapper ul.e-ul,
.e-bigger.e-menu-wrapper ul.e-ul,
.e-bigger .e-menu-container ul.e-ul,
.e-bigger.e-menu-container ul.e-ul {
  font-size: 16px;
  padding: 4px 0;
  white-space: nowrap;
  min-width: 112px;
}
.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item,
.e-bigger .e-menu-container ul.e-ul .e-menu-item,
.e-bigger.e-menu-container ul.e-ul .e-menu-item {
  height: 36px;
  line-height: 36px;
  padding: 0 12px;
}
.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger .e-menu-container ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-container ul.e-ul .e-menu-item .e-menu-icon {
  font-size: 22px;
  line-height: 36px;
}
.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger .e-menu-container ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-container ul.e-ul .e-menu-item .e-caret {
  line-height: 36px;
}
.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item.e-separator,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item.e-separator,
.e-bigger .e-menu-container ul.e-ul .e-menu-item.e-separator,
.e-bigger.e-menu-container ul.e-ul .e-menu-item.e-separator {
  height: auto;
  line-height: normal;
}
.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger .e-menu-container ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-container ul.e-ul .e-menu-item .e-caret {
  font-size: 16px;
  right: 7px;
}
.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger .e-menu-container ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-menu-container ul.e-ul .e-menu-item.e-menu-caret-icon {
  padding-right: 36px;
}
.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger .e-menu-container ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-container ul.e-ul .e-menu-item .e-menu-icon {
  margin-right: 8px;
}

.e-bigger .e-menu-container:not(.e-hamburger):not(.e-rtl) .e-menu.e-vertical .e-blankicon,
.e-bigger.e-menu-container:not(.e-hamburger):not(.e-rtl) .e-menu.e-vertical .e-blankicon {
  padding-left: 41px;
}

.e-bigger .e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device),
.e-bigger.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device),
.e-bigger .e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device),
.e-bigger.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) {
  padding: 0 40px;
}
.e-bigger .e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav,
.e-bigger.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav,
.e-bigger .e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav,
.e-bigger.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav {
  width: 40px;
}
.e-bigger .e-menu-wrapper .e-menu-vscroll.e-vscroll:not(.e-scroll-device),
.e-bigger.e-menu-wrapper .e-menu-vscroll.e-vscroll:not(.e-scroll-device),
.e-bigger .e-menu-container .e-menu-vscroll.e-vscroll:not(.e-scroll-device),
.e-bigger.e-menu-container .e-menu-vscroll.e-vscroll:not(.e-scroll-device) {
  padding: 32px 0;
}
.e-bigger .e-menu-wrapper .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-bigger.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-bigger .e-menu-container .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-bigger.e-menu-container .e-menu-vscroll.e-vscroll .e-scroll-nav {
  height: 32px;
}
.e-bigger .e-menu-wrapper.e-menu-popup,
.e-bigger.e-menu-wrapper.e-menu-popup,
.e-bigger .e-menu-container.e-menu-popup,
.e-bigger.e-menu-container.e-menu-popup {
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
}
.e-bigger .e-menu-wrapper .e-scroll-nav .e-icons,
.e-bigger.e-menu-wrapper .e-scroll-nav .e-icons,
.e-bigger .e-menu-container .e-scroll-nav .e-icons,
.e-bigger.e-menu-container .e-scroll-nav .e-icons {
  font-size: 18px;
}

.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item,
.e-bigger .e-rtl.e-menu-container ul .e-menu-item,
.e-bigger.e-rtl.e-menu-container ul .e-menu-item {
  text-align: right;
}
.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger .e-rtl.e-menu-container ul .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-container ul .e-menu-item .e-menu-icon {
  margin-left: 12px;
  margin-right: 0;
}
.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item .e-caret,
.e-bigger .e-rtl.e-menu-container ul .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-container ul .e-menu-item .e-caret {
  left: 12px;
  margin-left: 0;
  right: auto;
}
.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-bigger .e-rtl.e-menu-container ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-container ul .e-menu-item.e-menu-caret-icon {
  padding-left: 32px;
  padding-right: 12px;
}
.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-menu-item .e-menu-icon {
  margin-left: 8px;
}
.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-menu-item .e-caret {
  left: 7px;
}
.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-menu-caret-icon {
  padding-left: 36px;
  padding-right: 12px;
}
.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon {
  padding-left: 14px;
}
.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

.e-bigger .e-rtl.e-menu-container:not(.e-hamburger) .e-menu.e-vertical .e-blankicon,
.e-bigger.e-rtl.e-menu-container:not(.e-hamburger) .e-menu.e-vertical .e-blankicon {
  padding-right: 41px;
}

.e-bigger .e-menu-wrapper.e-hamburger ul .e-ul,
.e-bigger.e-menu-wrapper.e-hamburger ul .e-ul,
.e-bigger .e-menu-container.e-hamburger ul .e-ul,
.e-bigger.e-menu-container.e-hamburger ul .e-ul {
  max-width: 100%;
}
.e-bigger .e-menu-wrapper.e-hamburger ul .e-ul .e-menu-item,
.e-bigger.e-menu-wrapper.e-hamburger ul .e-ul .e-menu-item,
.e-bigger .e-menu-container.e-hamburger ul .e-ul .e-menu-item,
.e-bigger.e-menu-container.e-hamburger ul .e-ul .e-menu-item {
  height: auto;
  line-height: 38px;
  padding: 0;
}

.e-bigger .e-menu-wrapper.e-hamburger .e-menu-header,
.e-bigger.e-menu-wrapper.e-hamburger .e-menu-header,
.e-bigger .e-menu-container.e-hamburger .e-menu-header,
.e-bigger.e-menu-container.e-hamburger .e-menu-header {
  font-size: 22px;
  height: 38px;
  line-height: 38px;
}
.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-menu-item,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-menu-item {
  text-indent: 15px;
}
.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-blankicon {
  text-indent: 49px;
}
.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-ul,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-ul,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-ul,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-ul {
  font-size: 16px;
}
.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item {
  text-indent: inherit;
}
.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item.e-menu-caret-icon {
  padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
}
.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item .e-caret,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item .e-caret {
  font-size: 16px;
  right: 12px;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item {
  padding: 0;
  text-indent: 15px;
}
.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-blankicon {
  text-indent: 49px;
}
.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-menu-icon,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item .e-menu-icon {
  margin-left: 0;
  text-indent: inherit;
}
.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-menu-caret-icon,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-menu-caret-icon {
  padding-left: 0;
  padding-right: 0;
}
.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-caret,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item .e-caret {
  left: 8px;
  right: auto;
}
.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item {
  text-indent: inherit;
}

.e-bigger .e-menu-wrapper:not(.e-hamburger) ul.e-ul .e-menu-item:not(.e-separator),
.e-bigger .e-menu-container:not(.e-hamburger) ul.e-ul .e-menu-item:not(.e-separator) {
  height: 36px;
}

.e-bigger .e-menu-wrapper:not(.e-hamburger) ul .e-menu-item.e-menu-caret-icon,
.e-bigger .e-menu-container:not(.e-hamburger) ul .e-menu-item.e-menu-caret-icon {
  padding-right: 32px;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-menu-item {
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
  height: auto !important; /* stylelint-disable-line declaration-no-important */
}

.e-breadcrumb .e-breadcrumb-collapsed::before,
.e-breadcrumb .e-breadcrumb-menu::before {
  content: "\e71c";
}
.e-breadcrumb .e-home::before {
  content: "\e87b";
}
.e-breadcrumb .e-home {
  height: 18px;
}

.e-bigger .e-breadcrumb .e-home,
.e-bigger.e-breadcrumb .e-home {
  height: 21px;
}

.e-breadcrumb {
  display: block;
}
.e-breadcrumb.e-breadcrumb-wrap-mode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.e-breadcrumb .e-breadcrumb-first-ol {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding-right: 0;
}
.e-breadcrumb .e-breadcrumb-wrapped-ol {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  padding-left: 0;
}
.e-breadcrumb.e-breadcrumb-scroll-mode {
  line-height: 30px;
  overflow: auto;
}
.e-breadcrumb ol {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: normal;
  margin: 0;
  padding: 0;
  padding-left: 0;
  padding-right: 0;
}
.e-breadcrumb li {
  list-style-type: none;
  white-space: nowrap;
}
.e-breadcrumb .e-breadcrumb-item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  padding: 0;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:focus {
  padding: 1px;
}
.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:focus {
  padding: 2px 4px;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-anchor-wrap {
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: inherit;
}
.e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text {
  cursor: pointer;
  text-decoration: none;
  text-decoration: underline;
}
.e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text:hover {
  text-decoration: underline;
}
.e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text:focus {
  text-decoration: underline;
}
.e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text:active {
  text-decoration: underline;
}
.e-breadcrumb .e-breadcrumb-item span.e-breadcrumb-text {
  cursor: default;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon {
  font-size: 14px;
  padding-right: 8px;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon:hover {
  text-decoration: none;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon::before {
  display: inline-block;
  text-decoration: none;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon::before {
  vertical-align: middle;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon.e-home::before {
  vertical-align: sub;
}
.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-icon {
  padding: 0;
}
.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text {
  padding: 3px 5px;
}
.e-breadcrumb .e-breadcrumb-item.e-disabled {
  opacity: 0.6;
  pointer-events: none;
}
.e-breadcrumb .e-breadcrumb-separator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  padding: 0 8px;
}
.e-breadcrumb .e-breadcrumb-separator + .e-breadcrumb-separator {
  margin-left: -8px;
  padding-left: 0;
}
.e-breadcrumb.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator {
  margin-left: 0;
  margin-right: -8px;
  padding-left: 8px;
  padding-right: 0;
}
.e-breadcrumb .e-icon-item + .e-breadcrumb-separator {
  padding-left: 5px;
}
.e-breadcrumb .e-breadcrumb-collapsed,
.e-breadcrumb .e-breadcrumb-menu {
  cursor: pointer;
  font-size: 16px;
  padding: 4px 4px;
  vertical-align: bottom;
  margin-top: 2px;
}
.e-breadcrumb .e-breadcrumb-menu {
  display: inline-block;
}
.e-breadcrumb .e-breadcrumb-item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.e-breadcrumb.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb .e-icon-right .e-breadcrumb-icon, .e-breadcrumb.e-rtl .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}
.e-breadcrumb.e-rtl.e-icon-right .e-breadcrumb-icon, .e-breadcrumb.e-rtl .e-icon-right .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}
.e-breadcrumb.e-disabled {
  opacity: 1;
}
.e-breadcrumb.e-disabled .e-breadcrumb-item,
.e-breadcrumb.e-disabled .e-breadcrumb-separator,
.e-breadcrumb.e-disabled .e-breadcrumb-collapsed,
.e-breadcrumb.e-disabled .e-breadcrumb-menu {
  pointer-events: none;
}

.e-breadcrumb-popup {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  -webkit-box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.175);
  position: absolute;
}
.e-breadcrumb-popup ul {
  margin: 0;
  padding: 0;
}
.e-breadcrumb-popup .e-breadcrumb-item {
  list-style-type: none;
  white-space: nowrap;
}
.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-text {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
  width: 100%;
}
.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-text .e-anchor-wrap {
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: inherit;
  width: inherit;
}
.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-icon {
  font-size: 14px;
  padding-right: 8px;
}
.e-breadcrumb-popup .e-breadcrumb-item.e-icon-item .e-breadcrumb-icon {
  padding: 0;
}
.e-breadcrumb-popup .e-breadcrumb-item a.e-breadcrumb-text {
  text-decoration: none;
}
.e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon, .e-breadcrumb-popup.e-rtl .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}
.e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.e-breadcrumb-popup.e-rtl.e-icon-right .e-breadcrumb-icon, .e-breadcrumb-popup.e-rtl .e-icon-right .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  color: #0d6efd;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:hover, .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:focus {
  color: #0257d5;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:active {
  color: #024dbc;
}
.e-breadcrumb .e-breadcrumb-item.e-icon-item:hover {
  background: #e9ecef;
  color: #6c757d;
}
.e-breadcrumb .e-breadcrumb-item.e-icon-item:active {
  background: #ced4da;
  color: #6c757d;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:focus {
  border: 1px solid #0257d5;
}
.e-breadcrumb .e-breadcrumb-item[data-active-item] .e-breadcrumb-text {
  color: #6c757d;
  font-weight: 400;
}
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon {
  color: #6c757d;
}
.e-breadcrumb .e-breadcrumb-separator {
  color: #6c757d;
}
.e-breadcrumb .e-breadcrumb-collapsed,
.e-breadcrumb .e-breadcrumb-menu {
  color: #6c757d;
}
.e-breadcrumb .e-breadcrumb-collapsed:hover,
.e-breadcrumb .e-breadcrumb-menu:hover {
  background: #e9ecef;
  color: #6c757d;
}
.e-breadcrumb .e-breadcrumb-collapsed:active,
.e-breadcrumb .e-breadcrumb-menu:active {
  background: #ced4da;
  color: #6c757d;
}
.e-breadcrumb .e-breadcrumb-collapsed:focus,
.e-breadcrumb .e-breadcrumb-menu:focus {
  background: #e9ecef;
  color: #6c757d;
}
.e-breadcrumb.e-disabled .e-breadcrumb-item .e-breadcrumb-text, .e-breadcrumb .e-disabled.e-breadcrumb-item .e-breadcrumb-text, .e-breadcrumb.e-disabled .e-breadcrumb-separator {
  color: #adb5bd;
}
.e-breadcrumb.e-disabled .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon, .e-breadcrumb .e-disabled.e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon, .e-breadcrumb.e-disabled .e-breadcrumb-separator .e-breadcrumb-icon {
  color: #adb5bd;
  color: #adb5bd;
}

.e-breadcrumb-popup {
  background: #fff;
}
.e-breadcrumb-popup .e-breadcrumb-text {
  color: #6c757d;
}
.e-breadcrumb-popup .e-breadcrumb-text:hover {
  background: #e9ecef;
  color: #212529;
}
.e-breadcrumb-popup .e-breadcrumb-text:active {
  background: #e9ecef;
  color: #212529;
}
.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-text:focus {
  background: #e9ecef;
  color: #212529;
}

.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  padding: 0;
}
.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:focus,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:focus {
  padding: 1px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:focus,
.e-bigger .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:focus {
  padding: 1px 3px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text,
.e-bigger .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text {
  padding: 2px 4px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  font-size: 16px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon {
  font-size: 16px;
  padding-right: 8px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text .e-breadcrumb-icon {
  padding: 0;
}
.e-bigger.e-breadcrumb .e-breadcrumb-separator,
.e-bigger .e-breadcrumb .e-breadcrumb-separator {
  font-size: 16px;
  padding: 0 8px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-separator + .e-breadcrumb-separator,
.e-bigger .e-breadcrumb .e-breadcrumb-separator + .e-breadcrumb-separator {
  padding-left: 0;
}
.e-bigger.e-breadcrumb.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator,
.e-bigger .e-breadcrumb.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator {
  padding-left: 8px;
}
.e-bigger.e-breadcrumb .e-breadcrumb-collapsed,
.e-bigger.e-breadcrumb .e-breadcrumb-menu,
.e-bigger .e-breadcrumb .e-breadcrumb-collapsed,
.e-bigger .e-breadcrumb .e-breadcrumb-menu {
  font-size: 18px;
  padding: 5px 5px;
}
.e-bigger.e-breadcrumb.e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger.e-breadcrumb .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon, .e-bigger.e-breadcrumb.e-rtl .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb.e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb.e-rtl .e-breadcrumb-text .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}
.e-bigger.e-breadcrumb.e-rtl .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb.e-rtl .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}

.e-bigger.e-breadcrumb-popup .e-breadcrumb-text,
.e-bigger .e-breadcrumb-popup .e-breadcrumb-text {
  font-size: 16px;
  height: 36px;
  line-height: 36px;
}
.e-bigger.e-breadcrumb-popup .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup .e-breadcrumb-text .e-breadcrumb-icon {
  font-size: 16px;
  padding-right: 8px;
}
.e-bigger.e-breadcrumb-popup .e-icon-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup .e-icon-item .e-breadcrumb-text .e-breadcrumb-icon {
  padding: 0;
}
.e-bigger.e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-bigger.e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon, .e-bigger.e-breadcrumb-popup.e-rtl .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-rtl .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}
.e-bigger.e-breadcrumb-popup.e-rtl.e-icon-right .e-breadcrumb-icon, .e-bigger.e-breadcrumb-popup.e-rtl .e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-rtl.e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-rtl .e-icon-right .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}

.e-appbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 48px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px;
  overflow: hidden;
  font-size: 14px;
}
.e-appbar > div:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
}
.e-appbar.e-sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.e-appbar.e-prominent {
  height: 94px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.e-appbar.e-prominent > div:first-child {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.e-appbar.e-dense {
  height: 40px;
}
.e-appbar.e-horizontal-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.e-appbar.e-horizontal-bottom.e-sticky {
  position: fixed;
  top: auto;
  max-width: inherit;
  left: auto;
  right: auto;
}
.e-appbar .e-appbar-separator {
  height: 24px;
}
.e-appbar .e-appbar-spacer {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.e-appbar .e-btn,
.e-appbar .e-css.e-btn {
  -o-border-image: unset;
     border-image: unset;
}

.e-inherit.e-menu-wrapper.e-scrollable .e-menu,
.e-inherit.e-menu-container.e-scrollable .e-menu {
  overflow: hidden;
}

.e-appbar {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-appbar.e-light {
  background: #f8f9fa;
  color: #212529;
  border: none;
  border-color: #f8f9fa;
}
.e-appbar.e-light .e-appbar-separator {
  border-left: 1px solid #212529;
}
.e-appbar.e-light .e-btn.e-inherit:hover, .e-appbar.e-light .e-btn.e-inherit:focus, .e-appbar.e-light .e-btn.e-inherit:active, .e-appbar.e-light .e-btn.e-inherit.e-active,
.e-appbar.e-light .e-css.e-btn.e-inherit:hover,
.e-appbar.e-light .e-css.e-btn.e-inherit:focus,
.e-appbar.e-light .e-css.e-btn.e-inherit:active,
.e-appbar.e-light .e-css.e-btn.e-inherit.e-active,
.e-appbar.e-light .e-dropdown-btn.e-inherit:hover,
.e-appbar.e-light .e-dropdown-btn.e-inherit:focus,
.e-appbar.e-light .e-dropdown-btn.e-inherit:active,
.e-appbar.e-light .e-dropdown-btn.e-inherit.e-active,
.e-appbar.e-light .e-dropdown-btn.e-btn.e-inherit:hover,
.e-appbar.e-light .e-dropdown-btn.e-btn.e-inherit:focus,
.e-appbar.e-light .e-dropdown-btn.e-btn.e-inherit:active,
.e-appbar.e-light .e-dropdown-btn.e-btn.e-inherit.e-active {
  background: rgba(0, 0, 0, 0.056);
}
.e-appbar.e-light .e-menu-wrapper.e-inherit,
.e-appbar.e-light .e-menu-container.e-inherit {
  background: #f8f9fa;
  color: #212529;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #f8f9fa;
}
.e-appbar.e-light .e-menu-wrapper.e-inherit .e-menu .e-menu-item.e-selected, .e-appbar.e-light .e-menu-wrapper.e-inherit .e-menu .e-menu-item.e-focused,
.e-appbar.e-light .e-menu-container.e-inherit .e-menu .e-menu-item.e-selected,
.e-appbar.e-light .e-menu-container.e-inherit .e-menu .e-menu-item.e-focused {
  background: rgba(0, 0, 0, 0.056);
}
.e-appbar.e-dark {
  background: #212529;
  color: #fff;
  border-color: #212529;
}
.e-appbar.e-dark .e-appbar-separator {
  border-left: 1px solid #fff;
}
.e-appbar.e-dark .e-btn.e-inherit:hover, .e-appbar.e-dark .e-btn.e-inherit:focus, .e-appbar.e-dark .e-btn.e-inherit:active, .e-appbar.e-dark .e-btn.e-inherit.e-active,
.e-appbar.e-dark .e-css.e-btn.e-inherit:hover,
.e-appbar.e-dark .e-css.e-btn.e-inherit:focus,
.e-appbar.e-dark .e-css.e-btn.e-inherit:active,
.e-appbar.e-dark .e-css.e-btn.e-inherit.e-active,
.e-appbar.e-dark .e-dropdown-btn.e-inherit:hover,
.e-appbar.e-dark .e-dropdown-btn.e-inherit:focus,
.e-appbar.e-dark .e-dropdown-btn.e-inherit:active,
.e-appbar.e-dark .e-dropdown-btn.e-inherit.e-active,
.e-appbar.e-dark .e-dropdown-btn.e-btn.e-inherit:hover,
.e-appbar.e-dark .e-dropdown-btn.e-btn.e-inherit:focus,
.e-appbar.e-dark .e-dropdown-btn.e-btn.e-inherit:active,
.e-appbar.e-dark .e-dropdown-btn.e-btn.e-inherit.e-active {
  background: rgba(255, 255, 255, 0.08);
}
.e-appbar.e-dark .e-menu-wrapper.e-inherit,
.e-appbar.e-dark .e-menu-container.e-inherit {
  background: #212529;
  color: #fff;
  border-color: #212529;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-appbar.e-dark .e-menu-wrapper.e-inherit .e-menu .e-menu-item.e-selected, .e-appbar.e-dark .e-menu-wrapper.e-inherit .e-menu .e-menu-item.e-focused,
.e-appbar.e-dark .e-menu-container.e-inherit .e-menu .e-menu-item.e-selected,
.e-appbar.e-dark .e-menu-container.e-inherit .e-menu .e-menu-item.e-focused {
  background: rgba(255, 255, 255, 0.08);
}
.e-appbar.e-primary {
  background: #0d6efd;
  color: #fff;
  border-color: #0d6efd;
}
.e-appbar.e-primary .e-appbar-separator {
  border-left: 1px solid #fff;
}
.e-appbar.e-primary .e-menu-wrapper.e-inherit,
.e-appbar.e-primary .e-menu-container.e-inherit {
  background: #0d6efd;
  color: #fff;
  border-color: #0d6efd;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-appbar.e-inherit {
  background: inherit;
  color: inherit;
  border-color: inherit;
  border: 1px solid;
}
.e-appbar.e-inherit .e-appbar-separator {
  border-left: 1px solid;
}
.e-appbar.e-inherit .e-menu-wrapper.e-inherit,
.e-appbar.e-inherit .e-menu-container.e-inherit {
  background: inherit;
  color: inherit;
  border-color: inherit;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-appbar.e-horizontal-bottom {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.e-light.e-inherit.e-input-group, .e-light.e-inherit.e-input-group.e-control-wrapper, .e-light.e-inherit.e-float-input, .e-light.e-inherit.e-float-input.e-input-group, .e-light.e-inherit.e-float-input.e-control-wrapper, .e-light.e-inherit.e-float-input.e-input-group.e-control-wrapper, .e-light.e-inherit.e-input-group:not(.e-disabled) .e-input-group-icon:hover, .e-light.e-inherit.e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
  background: #f8f9fa;
  color: #212529;
}

.e-dark.e-inherit.e-input-group, .e-dark.e-inherit.e-input-group.e-control-wrapper, .e-dark.e-inherit.e-float-input, .e-dark.e-inherit.e-float-input.e-input-group, .e-dark.e-inherit.e-float-input.e-control-wrapper, .e-dark.e-inherit.e-float-input.e-input-group.e-control-wrapper, .e-dark.e-inherit.e-input-group:not(.e-disabled) .e-input-group-icon:hover, .e-dark.e-inherit.e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
  background: #212529;
  color: #fff;
}

.e-primary.e-inherit.e-input-group, .e-primary.e-inherit.e-input-group.e-control-wrapper, .e-primary.e-inherit.e-float-input, .e-primary.e-inherit.e-float-input.e-input-group, .e-primary.e-inherit.e-float-input.e-control-wrapper, .e-primary.e-inherit.e-float-input.e-input-group.e-control-wrapper, .e-primary.e-inherit.e-input-group:not(.e-disabled) .e-input-group-icon:hover, .e-primary.e-inherit.e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
  background: #0d6efd;
  color: #fff;
}

.e-inherit.e-input-group,
.e-inherit.e-input-group.e-control-wrapper,
.e-inherit.e-float-input,
.e-inherit.e-float-input.e-input-group,
.e-inherit.e-float-input.e-control-wrapper,
.e-inherit.e-float-input.e-input-group.e-control-wrapper,
.e-inherit.e-input-group:not(.e-disabled) .e-input-group-icon:hover,
.e-inherit.e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
  color: inherit;
  background: rgba(0, 0, 0, 0.056);
}

.e-inherit.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
.e-inherit.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
.e-inherit.e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error),
.e-inherit.e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.e-inherit.e-input-group.e-control-wrapper .e-searchinput::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.8;
}

.e-inherit.e-input-group.e-control-wrapper .e-searchinput::-moz-placeholder {
  color: inherit;
  opacity: 0.8;
}

.e-inherit.e-input-group.e-control-wrapper .e-searchinput:-ms-input-placeholder {
  color: inherit;
  opacity: 0.8;
}

.e-inherit.e-input-group.e-control-wrapper .e-searchinput::-ms-input-placeholder {
  color: inherit;
  opacity: 0.8;
}

.e-inherit.e-input-group.e-control-wrapper .e-searchinput::placeholder {
  color: inherit;
  opacity: 0.8;
}

.e-inherit.e-input-group .e-input-group-icon,
.e-inherit.e-input-group.e-control-wrapper .e-input-group-icon {
  border: none;
  color: inherit;
}

.e-inherit.e-input-group .e-clear-icon,
.e-inherit.e-input-group.e-control-wrapper .e-clear-icon {
  color: inherit;
}

.e-bigger .e-appbar,
.e-appbar.e-bigger {
  height: 56px;
  font-size: 16px;
}
.e-bigger .e-appbar.e-dense,
.e-appbar.e-bigger.e-dense {
  height: 48px;
}
.e-bigger .e-appbar.e-prominent,
.e-appbar.e-bigger.e-prominent {
  height: 112px;
}

.e-step-indicator::before {
  content: "\e8db";
}

.e-stepper {
  position: relative;
}
.e-stepper .e-step-selected .e-text,
.e-stepper .e-step-selected .e-label {
  font-weight: 600;
}
.e-stepper.e-linear .e-step-container {
  pointer-events: none;
}
.e-stepper.e-linear .e-previous:not(.e-step-disabled),
.e-stepper.e-linear .e-next:not(.e-step-disabled),
.e-stepper.e-linear .e-step-selected:not(.e-step-disabled),
.e-stepper.e-linear .e-previous:not(.e-step-disabled) + li {
  pointer-events: auto;
}
.e-stepper.e-stepper-readonly,
.e-stepper .e-step-disabled {
  cursor: default;
  pointer-events: none;
}
.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) .e-icons.e-step-indicator {
  font-size: 32px;
  border-radius: 9999px;
}
.e-stepper.e-step-type-indicator .e-step-content, .e-stepper.e-step-type-label .e-step-content {
  padding: 0 8px;
}
.e-stepper .e-step-container:has(.e-tooltip-wrap.e-stepper-tooltip), .e-stepper.e-vertical.e-label-after .e-step-item.e-step-label:has(.e-tooltip-wrap.e-stepper-tooltip), .e-stepper.e-vertical.e-label-before .e-step-item.e-step-label:has(.e-tooltip-wrap.e-stepper-tooltip) {
  position: relative;
}
.e-stepper.e-horizontal .e-step-item.e-step-label:has(.e-tooltip-wrap.e-stepper-tooltip) {
  position: unset;
}
.e-stepper .e-tooltip-wrap.e-stepper-tooltip {
  display: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 50%;
  top: -8px;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
}
.e-stepper .e-tooltip-wrap.e-stepper-tooltip.e-show-tooltip {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.e-stepper .e-tooltip-wrap.e-stepper-tooltip .e-tip-content {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.e-stepper .e-tooltip-wrap.e-stepper-tooltip .e-arrow-tip.e-tip-bottom {
  left: 50%;
  top: 100%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.e-stepper .e-tooltip-wrap.e-stepper-tooltip .e-arrow-tip-inner.e-tip-bottom {
  top: -6px;
}
.e-stepper .e-step-item.e-step-label .e-tooltip-wrap.e-stepper-tooltip {
  left: unset;
  top: 0;
  -webkit-transform: translate(0%, -135%);
          transform: translate(0%, -135%);
}
.e-stepper.e-step-type-indicator .e-tooltip-wrap.e-stepper-tooltip {
  -webkit-transform: translate(-50%, -115%);
          transform: translate(-50%, -115%);
}
.e-stepper .e-stepper-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.e-stepper .e-stepper-steps .e-step-container,
.e-stepper .e-stepper-steps .e-step,
.e-stepper .e-stepper-steps .e-step-valid.e-step-text.e-step-text-only .e-step-text-container,
.e-stepper .e-stepper-steps .e-step-error.e-step-text.e-step-text-only .e-step-text-container,
.e-stepper .e-stepper-steps .e-step-label-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.e-stepper .e-step-container {
  max-width: var(--max-width);
  list-style-type: none;
  cursor: pointer;
  text-align: center;
}
.e-stepper .e-step-container .e-step-label-container,
.e-stepper .e-step-container .e-step-text-container {
  max-width: clamp(100%, 10em, 100%);
  white-space: nowrap;
  font-size: 14px;
}
.e-stepper .e-step-container .e-step-label-optional {
  font-size: 12px;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
.e-stepper .e-step-container .e-step-optional-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.e-stepper .e-step-container .e-step-content {
  font-size: 16px;
  line-height: 0;
}
.e-stepper .e-step-container .e-indicator {
  font-size: 16px;
}
.e-stepper .e-step-container:not(.e-step-text-only) > .e-step-text-container {
  padding: 0 8px;
}
.e-stepper .e-step-container.e-step-text-only .e-step-text-container, .e-stepper .e-step-container.e-step-label-only .e-step-label-container {
  padding: 0 6px;
  padding-block: 5px;
}
.e-stepper .e-step-container.e-step-text-only.e-step-error .e-step-text-container, .e-stepper .e-step-container.e-step-text-only.e-step-valid .e-step-text-container, .e-stepper .e-step-container.e-step-label-only.e-step-error .e-step-label-container, .e-stepper .e-step-container.e-step-label-only.e-step-valid .e-step-label-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.e-stepper .e-step-container.e-step-text-only.e-step-error .e-step-text-container .e-step-validation-icon, .e-stepper .e-step-container.e-step-text-only.e-step-valid .e-step-text-container .e-step-validation-icon, .e-stepper .e-step-container.e-step-label-only.e-step-error .e-step-label-container .e-step-validation-icon, .e-stepper .e-step-container.e-step-label-only.e-step-valid .e-step-label-container .e-step-validation-icon {
  position: relative;
  top: 0.25em;
}
.e-stepper .e-step-container .e-step,
.e-stepper .e-step-container .e-indicator {
  border-radius: 9999px;
  min-width: 32px;
  min-height: 32px;
}
.e-stepper .e-step-notstarted:not(.e-step-disabled) .e-step,
.e-stepper .e-step-notstarted:not(.e-step-disabled) .e-indicator {
  border: 2px solid;
}
.e-stepper.e-horizontal {
  display: block;
}
.e-stepper.e-horizontal .e-stepper-steps {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.e-stepper.e-horizontal.e-label-before:has(.e-step-item.e-step-label:not(.e-step-text)) .e-stepper-progressbar, .e-stepper.e-horizontal.e-label-after:has(.e-step-item.e-step-label:not(.e-step-text)) .e-stepper-progressbar {
  margin-left: var(--progress-left-position);
  top: var(--progress-top-position);
  width: var(--progress-bar-width);
}
.e-stepper.e-horizontal.e-label-before.e-rtl:has(.e-step-item.e-step-label:not(.e-step-text)) .e-stepper-progressbar, .e-stepper.e-horizontal.e-label-after.e-rtl:has(.e-step-item.e-step-label:not(.e-step-text)) .e-stepper-progressbar {
  margin-right: var(--progress-left-position);
  margin-left: unset;
}
.e-stepper.e-horizontal .e-step-item.e-step-label {
  gap: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.e-stepper.e-horizontal .e-step-item.e-step-label .e-step-label-container {
  position: relative;
}
.e-stepper.e-horizontal .e-step-item.e-step-label .e-step-label-container.e-label-before {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.e-stepper.e-horizontal.e-label-start .e-step-text-container {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.e-stepper.e-horizontal .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
  position: absolute;
  top: 2.5em;
  margin-left: 2.5em;
}
.e-stepper.e-horizontal.e-label-start .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional, .e-stepper.e-horizontal.e-rtl .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
  margin-right: 2.5em;
  margin-left: unset;
}
.e-stepper.e-horizontal.e-label-start.e-rtl .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
  margin-right: unset;
  margin-left: 2.5em;
}
.e-stepper.e-horizontal.e-label-before .e-stepper-steps:has(.e-step-label-optional) {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.e-stepper.e-horizontal.e-label-before .e-stepper-steps .e-step-label-container.e-label-before:has(.e-step-label-optional) {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5em;
}
.e-stepper.e-horizontal .e-text,
.e-stepper.e-horizontal .e-label,
.e-stepper.e-horizontal .e-step-label-optional {
  text-overflow: ellipsis;
  overflow: hidden;
}
.e-stepper.e-horizontal .e-step-text.e-step-text-only:has(.e-step-label-optional),
.e-stepper.e-horizontal .e-step-label.e-step-label-only:has(.e-step-label-optional),
.e-stepper.e-horizontal .e-step-item:not(.e-step-text):not(.e-step-label):has(.e-step-label-optional),
.e-stepper.e-horizontal .e-step-container:not(.e-step-item):not(.e-step-text):not(.e-step-label):has(.e-step-label-optional),
.e-stepper.e-horizontal .e-step-label-container.e-label-after:has(.e-step-label-optional), .e-stepper.e-horizontal.e-step-type-indicator .e-step-container {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.e-stepper.e-horizontal .e-step-item:not(.e-step-label):not(.e-step-text) .e-step-label-optional {
  margin-top: 0.5em;
}
.e-stepper.e-horizontal.e-label-after .e-step-item.e-step-label:not(.e-step-text) .e-step-label-optional {
  margin-top: 0.2em;
}
.e-stepper.e-vertical {
  min-height: inherit;
  height: inherit;
  display: inline-block;
}
.e-stepper.e-vertical .e-step-label.e-step-label-only,
.e-stepper.e-vertical .e-step-template {
  position: relative;
}
.e-stepper.e-vertical:not(.e-label-top):not(.e-label-bottom) .e-step-label-optional {
  position: absolute;
}
.e-stepper.e-vertical:not(.e-label-bottom):not(.e-label-top) .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
  margin-top: 3em;
  margin-left: 4em;
}
.e-stepper.e-vertical.e-rtl:not(.e-label-bottom):not(.e-label-top) .e-step-item.e-step-text:not(.e-step-label) .e-step-label-optional {
  margin-left: unset;
  margin-right: 4em;
}
.e-stepper.e-vertical .e-stepper-steps {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: inherit;
  height: inherit;
  float: left;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  margin: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.e-stepper.e-vertical.e-rtl .e-stepper-steps {
  float: right;
}
.e-stepper.e-vertical .e-stepper-steps:has(.e-step-item:not(.e-step-text):not(.e-step-label)),
.e-stepper.e-vertical .e-stepper-steps:has(.e-step-text.e-step-text-only),
.e-stepper.e-vertical .e-stepper-steps:has(.e-step-label.e-step-label-only), .e-stepper.e-vertical.e-label-after .e-stepper-steps:has(.e-step-label.e-step-text:not(.e-step-item)), .e-stepper.e-vertical.e-step-type-indicator .e-stepper-steps {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.e-stepper.e-vertical .e-step-item.e-step-text:not(.e-step-label) .e-step-text-container,
.e-stepper.e-vertical .e-step-item.e-step-label:not(.e-step-text) .e-step-label-container.e-label-after {
  margin-left: 10px;
}
.e-stepper.e-vertical.e-rtl .e-step-item.e-step-text:not(.e-step-label) .e-step-text-container,
.e-stepper.e-vertical.e-rtl .e-step-item.e-step-label:not(.e-step-text) .e-step-label-container.e-label-after {
  margin-left: unset;
  margin-right: 10px;
}
.e-stepper.e-vertical .e-step-item:not(.e-step-label):not(.e-step-text):has(.e-step-label-optional), .e-stepper.e-vertical.e-label-before .e-step-label-container.e-label-before:has(.e-step-label-optional),
.e-stepper.e-vertical .e-step-text-only.e-step-text:has(.e-step-label-optional),
.e-stepper.e-vertical .e-step-label-only.e-step-label:has(.e-step-label-optional) {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.e-stepper.e-vertical .e-step-item:not(.e-step-label):not(.e-step-text):has(.e-step-label-optional) .e-step-label-optional, .e-stepper.e-vertical.e-label-before .e-step-label-container.e-label-before:has(.e-step-label-optional) .e-step-label-optional,
.e-stepper.e-vertical .e-step-text-only.e-step-text:has(.e-step-label-optional) .e-step-label-optional,
.e-stepper.e-vertical .e-step-label-only.e-step-label:has(.e-step-label-optional) .e-step-label-optional {
  position: unset;
}
.e-stepper.e-vertical .e-step-item:not(.e-step-label):not(.e-step-text):has(.e-step-label-optional), .e-stepper.e-vertical.e-label-before .e-step-label-container.e-label-before:has(.e-step-label-optional) {
  gap: 0.5em;
}
.e-stepper.e-vertical.e-label-before .e-step-item.e-step-label .e-step-label-container.e-label-after:has(.e-step-label-optional), .e-stepper.e-vertical.e-label-after .e-step-item.e-step-label .e-step-label-container.e-label-after:has(.e-step-label-optional) {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.e-stepper.e-vertical.e-label-before .e-step-item.e-step-label .e-step-label-container.e-label-after:has(.e-step-label-optional) .e-label, .e-stepper.e-vertical.e-label-after .e-step-item.e-step-label .e-step-label-container.e-label-after:has(.e-step-label-optional) .e-label {
  line-height: 2em;
}
.e-stepper.e-vertical.e-label-before .e-step-item.e-step-label .e-step-label-optional, .e-stepper.e-vertical.e-label-after .e-step-item.e-step-label .e-step-label-optional {
  position: unset;
}
.e-stepper.e-vertical .e-step-container.e-step-label.e-step-text:not(.e-step-item) .e-step-label-container.e-label-after {
  position: absolute;
}
.e-stepper.e-vertical .e-step-container.e-step-label.e-step-text:not(.e-step-item) .e-step-label-container.e-label-after .e-step-label-optional {
  left: 0;
}
.e-stepper.e-vertical .e-step-container .e-step-label-container.e-label-after .e-step-label-optional {
  font-size: 12px;
}
.e-stepper.e-vertical .e-step-container .e-step-label-container.e-label-before .e-label {
  width: var(--label-width);
}
.e-stepper.e-vertical .e-step-container:not(.e-step-text-only) .e-step-text-container {
  border: none;
  border-radius: unset;
  padding: 6px 0;
}
.e-stepper.e-vertical .e-step-container.e-step-text-only .e-step-text-container, .e-stepper.e-vertical .e-step-container.e-step-label-only .e-step-label-container {
  position: unset;
}
.e-stepper.e-vertical.e-label-top .e-step-label-optional {
  padding-bottom: 6px;
}
.e-stepper.e-vertical.e-label-top .e-stepper-steps, .e-stepper.e-vertical.e-label-bottom .e-stepper-steps {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.e-stepper.e-vertical.e-label-top .e-stepper-steps .e-step-container, .e-stepper.e-vertical.e-label-bottom .e-stepper-steps .e-step-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.e-stepper.e-vertical.e-label-top .e-stepper-steps .e-step-container .e-step-text-container, .e-stepper.e-vertical.e-label-bottom .e-stepper-steps .e-step-container .e-step-text-container {
  margin-left: 0;
}
.e-stepper.e-vertical.e-label-top .e-step-container .e-step-label-optional,
.e-stepper.e-vertical.e-label-top .e-step-container .e-step-text-container {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.e-stepper .e-stepper-progressbar {
  height: 1.6px;
  position: absolute;
  top: var(--progress-top-position);
  width: 100%;
}
.e-stepper .e-stepper-progressbar > .e-progressbar-value {
  height: 100%;
  width: var(--progress-value);
  -webkit-transition-property: width;
  transition-property: width;
  -webkit-transition-duration: var(--duration);
          transition-duration: var(--duration);
  -webkit-transition-delay: var(--delay);
          transition-delay: var(--delay);
}
.e-stepper.e-vertical .e-stepper-progressbar {
  min-height: inherit;
  width: 1.6px;
  top: 0;
  left: var(--progress-position);
}
.e-stepper.e-vertical .e-stepper-progressbar > .e-progressbar-value {
  width: 100%;
  height: var(--progress-value);
  -webkit-transition-property: height;
  transition-property: height;
}
.e-stepper.e-vertical.e-rtl .e-stepper-progressbar {
  right: var(--progress-position);
  left: unset;
}
.e-stepper:not(.e-steps-focus):not(.e-step-type-label):not(.e-step-type-indicator) .e-step-container .e-indicator,
.e-stepper .e-step-focus .e-indicator {
  z-index: 0;
}
.e-stepper .e-step-template.e-step-focus {
  outline: 2px solid;
}

.e-tooltip-wrap.e-stepper-tooltip.e-step-inprogress-tip .e-arrow-tip.e-tip-bottom {
  top: 90% !important; /* stylelint-disable-line declaration-no-important */
}

.e-small.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) .e-icons.e-step-indicator,
.e-small .e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) .e-icons.e-step-indicator {
  font-size: 28px;
}
.e-small.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text).e-step-notstarted .e-icons.e-step-indicator,
.e-small .e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text).e-step-notstarted .e-icons.e-step-indicator {
  width: 28px;
  height: 28px;
}
.e-small.e-stepper .e-step-container .e-step,
.e-small.e-stepper .e-step-container .e-indicator,
.e-small .e-stepper .e-step-container .e-step,
.e-small .e-stepper .e-step-container .e-indicator {
  min-width: 28px;
  min-height: 28px;
}
.e-small.e-stepper .e-step-container .e-indicator,
.e-small .e-stepper .e-step-container .e-indicator {
  font-size: 14px;
}
.e-small.e-stepper .e-step-container .e-step-text-container,
.e-small.e-stepper .e-step-container .e-step-label-container,
.e-small .e-stepper .e-step-container .e-step-text-container,
.e-small .e-stepper .e-step-container .e-step-label-container {
  font-size: 12px;
}
.e-small.e-stepper .e-step-container .e-step-content,
.e-small .e-stepper .e-step-container .e-step-content {
  font-size: 14px;
}
.e-small.e-stepper .e-step-container .e-step-label-optional,
.e-small .e-stepper .e-step-container .e-step-label-optional {
  font-size: 10px;
}
.e-small.e-stepper.e-vertical .e-step-label-container.e-label-after .e-step-label-optional,
.e-small .e-stepper.e-vertical .e-step-label-container.e-label-after .e-step-label-optional {
  font-size: 10px;
}

.e-stepper .e-step-notstarted:not(.e-step-disabled) .e-step,
.e-stepper .e-step-notstarted:not(.e-step-disabled) .e-indicator,
.e-stepper .e-step-notstarted .e-icons.e-step-indicator {
  border-color: #dee2e6;
}
.e-stepper .e-step-label-optional {
  color: #6c757d;
}
.e-stepper .e-step,
.e-stepper .e-indicator {
  color: #212529;
  background: #fff;
}
.e-stepper .e-step-indicator {
  color: transparent;
}
.e-stepper .e-step-completed .e-step,
.e-stepper .e-step-inprogress .e-step,
.e-stepper .e-step-completed .e-indicator,
.e-stepper .e-step-inprogress .e-indicator,
.e-stepper .e-step-completed .e-step-indicator,
.e-stepper .e-step-inprogress .e-step-indicator {
  background: #0d6efd;
  color: #fff;
}
.e-stepper .e-step-selected .e-indicator {
  background: #0d6efd;
}
.e-stepper .e-step-valid .e-indicator {
  background: #198754;
  color: #fff;
}
.e-stepper .e-step-error .e-indicator {
  background: #dc3545;
  color: #fff;
}
.e-stepper .e-step-error .e-text,
.e-stepper .e-step-error .e-label {
  color: #dc3545;
}
.e-stepper .e-step-valid:not(.e-step-notstarted) .e-indicator:hover,
.e-stepper .e-step-error .e-indicator:hover {
  color: #212529;
}
.e-stepper .e-step-valid.e-step-text-only .e-step-validation-icon,
.e-stepper .e-step-valid.e-step-label-only .e-step-validation-icon {
  color: #198754;
}
.e-stepper .e-step-error.e-step-text-only .e-step-validation-icon,
.e-stepper .e-step-error.e-step-label-only .e-step-validation-icon {
  color: #dc3545;
}
.e-stepper .e-text,
.e-stepper .e-label {
  color: #212529;
}
.e-stepper .e-step-selected:not(.e-step-error) .e-text,
.e-stepper .e-step-selected:not(.e-step-error) .e-label,
.e-stepper .e-step-completed:not(.e-step-error) .e-text,
.e-stepper .e-step-completed:not(.e-step-error) .e-label {
  color: #0d6efd;
}
.e-stepper .e-step-label:not(.e-step-item) .e-step-label-container {
  background-color: #fff;
}
.e-stepper .e-step-text-container {
  background-color: #fff;
  border-color: transparent;
}
.e-stepper .e-step-disabled .e-indicator,
.e-stepper .e-step-disabled .e-step {
  background: #dee2e6;
  color: #adb5bd;
}
.e-stepper .e-step-disabled .e-text,
.e-stepper .e-step-disabled .e-label,
.e-stepper .e-step-disabled .e-step-validation-icon {
  color: #adb5bd;
}
.e-stepper.e-vertical .e-step-text-only.e-step-text .e-step-label-optional, .e-stepper.e-vertical .e-step-label-only.e-step-label .e-step-label-optional, .e-stepper.e-vertical .e-step-item:not(.e-step-text):not(.e-step-label) .e-step-label-optional, .e-stepper.e-vertical.e-label-top .e-step-label-optional, .e-stepper.e-vertical.e-label-bottom .e-step-label-optional {
  background-color: #fff;
}
.e-stepper .e-stepper-progressbar {
  background-color: #dee2e6;
}
.e-stepper .e-stepper-progressbar > .e-progressbar-value {
  background-color: #0d6efd;
}
.e-stepper:not(.e-steps-focus) .e-step-selected .e-step {
  -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #0d6efd, 0 0 0 8px #fff;
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #0d6efd, 0 0 0 8px #fff;
}
.e-stepper .e-step-focus .e-step,
.e-stepper .e-step-label-only.e-step-focus .e-label,
.e-stepper .e-step-text-only.e-step-focus .e-text,
.e-stepper .e-step-template.e-step-focus, .e-stepper:not(.e-steps-focus) :focus-visible .e-step, .e-stepper:not(.e-steps-focus) .e-step-text-only:focus-visible .e-text, .e-stepper:not(.e-steps-focus) .e-step-label-only:focus-visible .e-label {
  -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #212529, 0 0 0 8px #fff;
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #212529, 0 0 0 8px #fff;
}
.e-stepper:not(.e-steps-focus) .e-step-valid.e-step-selected .e-step,
.e-stepper .e-step-valid.e-step-focus .e-step,
.e-stepper .e-step-valid.e-step-label-only.e-step-focus .e-label,
.e-stepper .e-step-valid.e-step-text-only.e-step-focus .e-text,
.e-stepper .e-step-valid.e-step-template.e-step-focus {
  -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #198754, 0 0 0 8px #fff;
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #198754, 0 0 0 8px #fff;
}
.e-stepper:not(.e-steps-focus) .e-step-error.e-step-selected .e-step,
.e-stepper .e-step-error.e-step-focus .e-step,
.e-stepper .e-step-error.e-step-label-only.e-step-focus .e-label,
.e-stepper .e-step-error.e-step-text-only.e-step-focus .e-text,
.e-stepper .e-step-error.e-step-template.e-step-focus {
  -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #dc3545, 0 0 0 8px #fff;
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #dc3545, 0 0 0 8px #fff;
}
.e-stepper .e-step-container:not(.e-step-focus).e-step-notstarted .e-step,
.e-stepper .e-step-container:not(.e-step-focus).e-step-completed .e-step, .e-stepper.e-steps-focus .e-step-container:not(.e-step-focus).e-step-selected .e-step {
  -webkit-box-shadow: 0 0 0 8px #fff;
          box-shadow: 0 0 0 8px #fff;
}
.e-stepper .e-step-notstarted:not(.e-step-disabled) .e-step:hover,
.e-stepper .e-step-notstarted:not(.e-step-disabled) .e-indicator:hover {
  border-color: #dee2e6;
}
.e-stepper .e-step-notstarted:not(.e-step-disabled) .e-step:active,
.e-stepper .e-step-notstarted:not(.e-step-disabled) .e-indicator:active {
  border-color: #dee2e6;
}
.e-stepper:not(.e-step-type-label) .e-indicator:hover,
.e-stepper:not(.e-step-type-label) .e-step:hover {
  color: #fff;
  background: #0b5ed7;
}
.e-stepper:not(.e-step-type-label) .e-step-notstarted .e-indicator:hover, .e-stepper:not(.e-step-type-label) .e-step-notstarted .e-step:hover {
  color: #212529;
}
.e-stepper:not(.e-step-type-label) .e-step-selected .e-indicator:active,
.e-stepper:not(.e-step-type-label) .e-step-selected .e-step:active {
  background: #0a58ca;
}
.e-stepper:not(.e-step-type-label) .e-step-valid.e-step-selected .e-indicator:active,
.e-stepper:not(.e-step-type-label) .e-step-valid.e-step-selected .e-step:active {
  background: #198754;
}
.e-stepper:not(.e-step-type-label) .e-step-error.e-step-selected .e-indicator:active,
.e-stepper:not(.e-step-type-label) .e-step-error.e-step-selected .e-step:active {
  background: #dc3545;
}
.e-stepper:not(.e-step-type-label) .e-step-notstarted .e-indicator:hover, .e-stepper:not(.e-step-type-label) .e-step-notstarted .e-step:hover {
  background: #dee2e6;
}
.e-stepper:not(.e-step-type-label) .e-step-notstarted .e-indicator:active, .e-stepper:not(.e-step-type-label) .e-step-notstarted .e-step:active {
  background: #ced4da;
}
.e-stepper:not(.e-step-type-label) .e-step-valid .e-indicator:hover,
.e-stepper:not(.e-step-type-label) .e-step-valid .e-step:hover {
  color: #fff;
  background: #198754;
}
.e-stepper:not(.e-step-type-label) .e-step-error .e-indicator:hover,
.e-stepper:not(.e-step-type-label) .e-step-error .e-step:hover {
  color: #fff;
  background: #dc3545;
}

.e-bigger.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) .e-icons.e-step-indicator,
.e-bigger .e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) .e-icons.e-step-indicator {
  font-size: 40px;
}
.e-bigger.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text).e-step-notstarted .e-icons.e-step-indicator,
.e-bigger .e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text).e-step-notstarted .e-icons.e-step-indicator {
  width: 40px;
  height: 40px;
}
.e-bigger.e-stepper .e-step-container .e-step,
.e-bigger.e-stepper .e-step-container .e-indicator,
.e-bigger .e-stepper .e-step-container .e-step,
.e-bigger .e-stepper .e-step-container .e-indicator {
  min-width: 40px;
  min-height: 40px;
}
.e-bigger.e-stepper .e-step-container .e-indicator,
.e-bigger .e-stepper .e-step-container .e-indicator {
  font-size: 18px;
}
.e-bigger.e-stepper .e-step-container .e-step-text-container,
.e-bigger.e-stepper .e-step-container .e-step-label-container,
.e-bigger .e-stepper .e-step-container .e-step-text-container,
.e-bigger .e-stepper .e-step-container .e-step-label-container {
  font-size: 16px;
}
.e-bigger.e-stepper .e-step-container .e-step-content,
.e-bigger .e-stepper .e-step-container .e-step-content {
  font-size: 18px;
}
.e-bigger.e-stepper .e-step-container .e-step-label-optional,
.e-bigger .e-stepper .e-step-container .e-step-label-optional {
  font-size: 14px;
}
.e-bigger.e-stepper.e-vertical .e-step-label-container.e-label-after .e-step-label-optional,
.e-bigger .e-stepper.e-vertical .e-step-label-container.e-label-after .e-step-label-optional {
  font-size: 14px;
}

.e-bigger.e-small.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) .e-icons.e-step-indicator,
.e-bigger.e-small .e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) .e-icons.e-step-indicator,
.e-bigger .e-small.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) .e-icons.e-step-indicator,
.e-small .e-bigger.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text) .e-icons.e-step-indicator {
  font-size: 36px;
}
.e-bigger.e-small.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text).e-step-notstarted .e-icons.e-step-indicator,
.e-bigger.e-small .e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text).e-step-notstarted .e-icons.e-step-indicator,
.e-bigger .e-small.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text).e-step-notstarted .e-icons.e-step-indicator,
.e-small .e-bigger.e-stepper.e-step-type-indicator .e-step-container:not(.e-step-item.e-step-label.e-step-text).e-step-notstarted .e-icons.e-step-indicator {
  width: 36px;
  height: 36px;
}
.e-bigger.e-small.e-stepper .e-step-container .e-step,
.e-bigger.e-small.e-stepper .e-step-container .e-indicator,
.e-bigger.e-small .e-stepper .e-step-container .e-step,
.e-bigger.e-small .e-stepper .e-step-container .e-indicator,
.e-bigger .e-small.e-stepper .e-step-container .e-step,
.e-bigger .e-small.e-stepper .e-step-container .e-indicator,
.e-small .e-bigger.e-stepper .e-step-container .e-step,
.e-small .e-bigger.e-stepper .e-step-container .e-indicator {
  min-width: 36px;
  min-height: 36px;
}
.e-bigger.e-small.e-stepper .e-step-container .e-indicator,
.e-bigger.e-small .e-stepper .e-step-container .e-indicator,
.e-bigger .e-small.e-stepper .e-step-container .e-indicator,
.e-small .e-bigger.e-stepper .e-step-container .e-indicator {
  font-size: 16px;
}
.e-bigger.e-small.e-stepper .e-step-container .e-step-text-container,
.e-bigger.e-small.e-stepper .e-step-container .e-step-label-container,
.e-bigger.e-small .e-stepper .e-step-container .e-step-text-container,
.e-bigger.e-small .e-stepper .e-step-container .e-step-label-container,
.e-bigger .e-small.e-stepper .e-step-container .e-step-text-container,
.e-bigger .e-small.e-stepper .e-step-container .e-step-label-container,
.e-small .e-bigger.e-stepper .e-step-container .e-step-text-container,
.e-small .e-bigger.e-stepper .e-step-container .e-step-label-container {
  font-size: 14px;
}
.e-bigger.e-small.e-stepper .e-step-container .e-step-content,
.e-bigger.e-small .e-stepper .e-step-container .e-step-content,
.e-bigger .e-small.e-stepper .e-step-container .e-step-content,
.e-small .e-bigger.e-stepper .e-step-container .e-step-content {
  font-size: 16px;
}
.e-bigger.e-small.e-stepper .e-step-container .e-step-label-optional,
.e-bigger.e-small .e-stepper .e-step-container .e-step-label-optional,
.e-bigger .e-small.e-stepper .e-step-container .e-step-label-optional,
.e-small .e-bigger.e-stepper .e-step-container .e-step-label-optional {
  font-size: 12px;
}
.e-bigger.e-small.e-stepper.e-vertical .e-step-label-container.e-label-after .e-step-label-optional,
.e-bigger.e-small .e-stepper.e-vertical .e-step-label-container.e-label-after .e-step-label-optional,
.e-bigger .e-small.e-stepper.e-vertical .e-step-label-container.e-label-after .e-step-label-optional,
.e-small .e-bigger.e-stepper.e-vertical .e-step-label-container.e-label-after .e-step-label-optional {
  font-size: 12px;
}