.e-badge {
  background: #f8f9fa;
  border-color: transparent;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 2px transparent;
          box-shadow: 0 0 0 2px transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #343a40;
  display: inline-block;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 3px 4px;
  height: 20px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  vertical-align: middle;
}
.e-badge:hover {
  text-decoration: none;
}
.e-badge.e-badge-pill {
  border-radius: 10px;
  padding: 3px 11px 3px 11px;
}
.e-badge.e-badge-ghost {
  line-height: 1;
  padding: 3px 10px 3px 10px;
}
.e-badge.e-badge-notification {
  border-radius: 10px;
  height: 18px;
  line-height: 1;
  min-width: 32px;
  padding: 3px 10px;
  width: auto;
  font-size: 12px;
  position: absolute;
  top: -10px;
  left: 100%;
}
.e-badge.e-badge-notification.e-badge-ghost {
  line-height: 1;
}
.e-badge.e-badge-circle {
  border-radius: 10px;
  height: 20px;
  line-height: 1;
  min-width: 0;
  padding: 4px 3px 4px 3px;
  width: 20px;
}
.e-badge.e-badge-circle.e-badge-ghost {
  line-height: 1;
  padding: 3px;
}
.e-badge.e-badge-overlap {
  position: absolute;
  top: -10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.e-badge.e-badge-dot {
  border-radius: 10px;
  height: 10px;
  line-height: 1;
  min-width: 0;
  padding: 0;
  width: 10px;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0;
  overflow: visible;
  position: absolute;
  top: -3px;
  left: 100%;
}
.e-badge.e-badge-bottom.e-badge-dot {
  position: absolute;
  top: auto;
  bottom: 3px;
}
.e-badge.e-badge-bottom.e-badge-notification {
  position: absolute;
  top: auto;
  bottom: -3px;
}
button .e-badge {
  line-height: 1;
  position: relative;
  top: -2px;
}
button .e-badge.e-badge-circle {
  height: 18px;
  line-height: 1;
  width: 18px;
}
button .e-badge.e-badge-circle.e-badge-ghost {
  line-height: 1;
}

.e-badge.e-badge-primary:not(.e-badge-ghost):not([href]), .e-badge.e-badge-primary[href]:not(.e-badge-ghost) {
  background-color: #0d6efd;
  color: #fff;
}
.e-badge.e-badge-success:not(.e-badge-ghost):not([href]), .e-badge.e-badge-success[href]:not(.e-badge-ghost) {
  background-color: #d1e7dd;
  color: #115c39;
}
.e-badge.e-badge-danger:not(.e-badge-ghost):not([href]), .e-badge.e-badge-danger[href]:not(.e-badge-ghost) {
  background-color: #f8d7da;
  color: #bd2130;
}
.e-badge.e-badge-warning:not(.e-badge-ghost):not([href]), .e-badge.e-badge-warning[href]:not(.e-badge-ghost) {
  background-color: #fff3cd;
  color: #876500;
}
.e-badge.e-badge-info:not(.e-badge-ghost):not([href]), .e-badge.e-badge-info[href]:not(.e-badge-ghost) {
  background-color: #cff4fc;
  color: #066477;
}
.e-badge.e-badge-light:not(.e-badge-ghost):not([href]), .e-badge.e-badge-light[href]:not(.e-badge-ghost) {
  background-color: #e9ecef;
  color: #343a40;
}
.e-badge.e-badge-dark:not(.e-badge-ghost):not([href]), .e-badge.e-badge-dark[href]:not(.e-badge-ghost) {
  background-color: #495057;
  color: #fff;
}
.e-badge.e-badge-primary[href]:not(.e-badge-ghost):hover {
  background-color: #0257d5;
}
.e-badge.e-badge-secondary[href]:not(.e-badge-ghost):hover {
  background-color: #0257d5;
}
.e-badge.e-badge-success[href]:not(.e-badge-ghost):hover {
  background-color: #0257d5;
}
.e-badge.e-badge-danger[href]:not(.e-badge-ghost):hover {
  background-color: #0257d5;
}
.e-badge.e-badge-warning[href]:not(.e-badge-ghost):hover {
  background-color: #0257d5;
}
.e-badge.e-badge-info[href]:not(.e-badge-ghost):hover {
  background-color: #0257d5;
}
.e-badge.e-badge-light[href]:not(.e-badge-ghost):hover {
  background-color: #0257d5;
}
.e-badge.e-badge-dark[href]:not(.e-badge-ghost):hover {
  background-color: #0257d5;
}
.e-badge.e-badge-ghost.e-badge-primary {
  background-color: transparent;
  border: 1px solid #0d6efd;
  color: #0d6efd;
}
.e-badge.e-badge-ghost.e-badge-secondary {
  background-color: transparent;
  border: 1px solid #343a40;
  color: #343a40;
  border: 1px solid #f8f9fa;
  color: #f8f9fa;
}
.e-badge.e-badge-ghost.e-badge-success {
  background-color: transparent;
  border: 1px solid #115c39;
  color: #115c39;
  border: 1px solid #d1e7dd;
  color: #d1e7dd;
}
.e-badge.e-badge-ghost.e-badge-danger {
  background-color: transparent;
  border: 1px solid #bd2130;
  color: #bd2130;
  border: 1px solid #f8d7da;
  color: #f8d7da;
}
.e-badge.e-badge-ghost.e-badge-warning {
  background-color: transparent;
  border: 1px solid #876500;
  color: #876500;
}
.e-badge.e-badge-ghost.e-badge-info {
  background-color: transparent;
  border: 1px solid #066477;
  color: #066477;
}
.e-badge.e-badge-ghost.e-badge-light {
  background-color: transparent;
  border: 1px solid #343a40;
  color: #343a40;
  border: 1px solid #e9ecef;
  color: #e9ecef;
}
.e-badge.e-badge-ghost.e-badge-dark {
  background-color: transparent;
  border: 1px solid #495057;
  color: #495057;
}

.e-bigger .e-badge,
.e-badge.e-bigger {
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  height: 22px;
  line-height: 1;
  padding: 4px 3px;
}
.e-bigger .e-badge.e-badge-pill,
.e-badge.e-bigger.e-badge-pill {
  border-radius: 10px;
  padding: 3px 8px;
}
.e-bigger .e-badge.e-badge-ghost,
.e-badge.e-bigger.e-badge-ghost {
  padding: 3px;
}
.e-bigger .e-badge.e-badge-ghost.e-badge-circle,
.e-badge.e-bigger.e-badge-ghost.e-badge-circle {
  padding: 3px;
}
.e-bigger .e-badge.e-badge-ghost.e-badge-notification,
.e-badge.e-bigger.e-badge-ghost.e-badge-notification {
  padding: 3px 10px;
}
.e-bigger .e-badge.e-badge-notification,
.e-badge.e-bigger.e-badge-notification {
  border-radius: 10px;
  min-width: 30px;
  padding: 3px 8px;
}
.e-bigger .e-badge.e-badge-circle,
.e-badge.e-bigger.e-badge-circle {
  border-radius: 10px;
  min-width: 0;
  padding: 4px 3px;
  width: 22px;
}
.e-bigger .e-badge.e-badge-dot,
.e-badge.e-bigger.e-badge-dot {
  border-radius: 10px;
  height: 12px;
  width: 12px;
}

.e-toast .e-toast-close-icon::before {
  content: "\e7e7";
  font-family: "e-icons";
}
.e-toast .e-toast-success-icon::before {
  content: "\e727";
  font-family: "e-icons";
}
.e-toast .e-toast-error-icon::before {
  content: "\e878";
  font-family: "e-icons";
}
.e-toast .e-toast-info-icon::before {
  content: "\e800";
  font-family: "e-icons";
}
.e-toast .e-toast-warning-icon::before {
  content: "\e88b";
  font-family: "e-icons";
}

/* stylelint-disable property-no-vendor-prefix */
.e-toast-container {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.e-toast-container.e-toast-top-left {
  left: 10px;
  top: 10px;
}
.e-toast-container.e-toast-bottom-left {
  bottom: 10px;
  left: 10px;
}
.e-toast-container.e-toast-top-right {
  right: 10px;
  top: 10px;
}
.e-toast-container.e-toast-bottom-right {
  bottom: 10px;
  right: 10px;
}
.e-toast-container.e-toast-bottom-center {
  bottom: 10px;
  pointer-events: none;
  right: 0;
  width: 100%;
}
.e-toast-container.e-toast-bottom-center .e-toast {
  margin: 0 auto 10px;
  pointer-events: auto;
}
.e-toast-container.e-toast-top-center {
  pointer-events: none;
  right: 0;
  top: 10px;
  width: 100%;
}
.e-toast-container.e-toast-top-center .e-toast {
  margin: 0 auto 10px;
  pointer-events: auto;
}
.e-toast-container.e-toast-full-width {
  left: 0;
  right: 0;
  width: 100%;
}
.e-toast-container.e-toast-full-width .e-toast {
  margin: 0 auto 10px;
  width: 96%;
}
.e-toast-container.e-rtl .e-toast .e-toast-actions {
  text-align: left;
}
.e-toast-container.e-rtl .e-toast .e-toast-actions > * {
  margin-left: initial;
  margin-right: 10px;
}
.e-toast-container.e-rtl .e-toast .e-toast-close-icon {
  margin-left: initial;
  margin-right: auto;
}
.e-toast-container.e-rtl .e-toast .e-toast-icon {
  margin-left: 14px;
  margin-right: initial;
}
.e-toast-container.e-rtl .e-toast .e-toast-progress {
  left: auto;
  right: 0;
}
.e-toast-container .e-toast {
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  margin: 0 0 10px;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.e-toast-container .e-toast .e-toast-icon,
.e-toast-container .e-toast .e-toast-message {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.e-toast-container .e-toast > * {
  word-break: break-word;
  word-wrap: break-word;
}
.e-toast-container .e-toast .e-toast-message {
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  width: inherit;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title,
.e-toast-container .e-toast .e-toast-message .e-toast-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title:first-child,
.e-toast-container .e-toast .e-toast-message .e-toast-content:first-child {
  padding: 0;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title:last-child,
.e-toast-container .e-toast .e-toast-message .e-toast-content:last-child {
  padding-bottom: 0;
  padding-bottom: 12px;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title > *,
.e-toast-container .e-toast .e-toast-message .e-toast-content > * {
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #ced4da;
}
.e-toast-container .e-toast .e-toast-message .e-toast-content {
  padding: 12px;
  word-break: break-word;
  word-wrap: break-word;
}
.e-toast-container .e-toast .e-toast-message .e-toast-content + .e-toast-actions {
  padding-top: 0;
  padding: 16px 0;
}
.e-toast-container .e-toast .e-toast-message .e-toast-actions {
  margin: 1px;
  border-top: 1px solid #ced4da;
  margin-left: 12px;
  margin-right: 12px;
  padding: 12px 0;
  text-align: right;
}
.e-toast-container .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: 10px;
}
.e-toast-container .e-toast.e-toast-header-icon .e-toast-message .e-toast-title:first-child,
.e-toast-container .e-toast.e-toast-header-icon .e-toast-message .e-toast-content:first-child {
  padding-left: 48px;
}
.e-toast-container .e-toast.e-toast-header-close-icon .e-toast-message .e-toast-title:first-child,
.e-toast-container .e-toast.e-toast-header-close-icon .e-toast-message .e-toast-content:first-child {
  padding-right: 30px;
}
.e-toast-container .e-toast .e-toast-close-icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  height: 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: auto;
  width: 20px;
  margin-top: 13px;
  position: absolute;
  right: 8px;
}
.e-toast-container .e-toast .e-toast-icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  height: 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 14px;
  width: 20px;
  left: 16px;
  margin-top: 13px;
  position: absolute;
}
.e-toast-container .e-toast .e-toast-progress {
  bottom: 0;
  height: 4px;
  left: 0;
  position: absolute;
}

.e-content-placeholder.e-toast.e-placeholder-toast {
  background-size: 400px 100px;
  min-height: 100px;
}

.e-blazor-toast-hidden {
  visibility: hidden;
}

.e-toast-container .e-toast .e-toast-close-icon.blazor-toast-close-icon {
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  bottom: 6px;
  height: 20px;
  position: relative;
  width: 22px;
}

.e-toast-container .e-toast {
  background-color: #fff;
  border: 1px solid #dee2e6;
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
  color: #343a40;
}
.e-toast-container .e-toast .e-toast-close-icon {
  color: #6c757d;
}
.e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:focus, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:active {
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}
.e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:focus, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:hover {
  background-color: transparent;
  color: #343a40;
}
.e-toast-container .e-toast .e-toast-close-icon.e-icons:hover,
.e-toast-container .e-toast .e-toast-close-icon.e-icons:focus {
  background-color: transparent;
  color: #343a40;
}
.e-toast-container .e-toast.e-toast-success {
  background-color: #d1e7dd;
  color: #198754;
}
.e-toast-container .e-toast.e-toast-success .e-toast-message .e-toast-title {
  color: #198754;
  border-color: #198754;
}
.e-toast-container .e-toast.e-toast-success .e-toast-message .e-toast-content {
  color: #198754;
}
.e-toast-container .e-toast.e-toast-success .e-toast-icon {
  color: #198754;
}
.e-toast-container .e-toast.e-toast-success .e-toast-close-icon {
  color: #198754;
}
.e-toast-container .e-toast.e-toast-success:hover {
  background-color: #d1e7dd;
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
  color: #198754;
}
.e-toast-container .e-toast.e-toast-info {
  background-color: #cff4fc;
  color: #066477;
}
.e-toast-container .e-toast.e-toast-info .e-toast-message .e-toast-title {
  color: #066477;
  border-color: #066477;
}
.e-toast-container .e-toast.e-toast-info .e-toast-message .e-toast-content {
  color: #066477;
}
.e-toast-container .e-toast.e-toast-info .e-toast-icon {
  color: #066477;
}
.e-toast-container .e-toast.e-toast-info .e-toast-close-icon {
  color: #066477;
}
.e-toast-container .e-toast.e-toast-info:hover {
  background-color: #cff4fc;
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
  color: #066477;
}
.e-toast-container .e-toast.e-toast-warning {
  background-color: #fff3cd;
  color: #876500;
}
.e-toast-container .e-toast.e-toast-warning .e-toast-message .e-toast-title {
  color: #876500;
  border-color: #876500;
}
.e-toast-container .e-toast.e-toast-warning .e-toast-message .e-toast-content {
  color: #876500;
}
.e-toast-container .e-toast.e-toast-warning .e-toast-icon {
  color: #876500;
}
.e-toast-container .e-toast.e-toast-warning .e-toast-close-icon {
  color: #876500;
}
.e-toast-container .e-toast.e-toast-warning:hover {
  background-color: #fff3cd;
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
  color: #876500;
}
.e-toast-container .e-toast.e-toast-danger {
  background-color: #f8d7da;
  color: #dc3545;
}
.e-toast-container .e-toast.e-toast-danger .e-toast-message .e-toast-title {
  color: #dc3545;
  border-color: #dc3545;
}
.e-toast-container .e-toast.e-toast-danger .e-toast-message .e-toast-content {
  color: #dc3545;
}
.e-toast-container .e-toast.e-toast-danger .e-toast-icon {
  color: #dc3545;
}
.e-toast-container .e-toast.e-toast-danger .e-toast-close-icon {
  color: #dc3545;
}
.e-toast-container .e-toast.e-toast-danger:hover {
  background-color: #f8d7da;
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
  color: #dc3545;
}
.e-toast-container .e-toast:hover {
  background-color: #fff;
  -webkit-box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
}
.e-toast-container .e-toast .e-toast-icon {
  color: #6c757d;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title {
  color: #212529;
}
.e-toast-container .e-toast .e-toast-message .e-toast-content {
  color: #343a40;
}
.e-toast-container .e-toast .e-toast-progress {
  background-color: #0d6efd;
}

.e-bigger .e-toast-container.e-toast-top-left,
.e-toast-container.e-bigger.e-toast-top-left {
  left: 10px;
  top: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-left,
.e-toast-container.e-bigger.e-toast-bottom-left {
  bottom: 10px;
  left: 10px;
}
.e-bigger .e-toast-container.e-toast-top-right,
.e-toast-container.e-bigger.e-toast-top-right {
  right: 10px;
  top: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-right,
.e-toast-container.e-bigger.e-toast-bottom-right {
  bottom: 10px;
  right: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-center,
.e-toast-container.e-bigger.e-toast-bottom-center {
  bottom: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-center .e-toast,
.e-toast-container.e-bigger.e-toast-bottom-center .e-toast {
  margin: 0 auto 10px;
}
.e-bigger .e-toast-container.e-toast-top-center,
.e-toast-container.e-bigger.e-toast-top-center {
  top: 10px;
}
.e-bigger .e-toast-container.e-toast-top-center .e-toast,
.e-toast-container.e-bigger.e-toast-top-center .e-toast {
  margin: 0 auto 10px;
}
.e-bigger .e-toast-container.e-toast-full-width,
.e-toast-container.e-bigger.e-toast-full-width {
  left: 0;
  right: 0;
}
.e-bigger .e-toast-container.e-toast-full-width .e-toast,
.e-toast-container.e-bigger.e-toast-full-width .e-toast {
  margin: 0 auto 10px;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-message .e-toast-actions,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-message .e-toast-actions {
  text-align: left;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-message .e-toast-actions > *,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: initial;
  margin-right: 10px;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-close-icon,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-close-icon {
  margin-left: initial;
  margin-right: auto;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-icon,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-icon {
  margin-left: 16px;
  margin-right: initial;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-progress,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-progress {
  left: auto;
  right: 0;
}
.e-bigger .e-toast-container .e-toast,
.e-toast-container.e-bigger .e-toast {
  border-radius: 4px;
  font-size: 14px;
  margin: 0 0 10px;
  min-height: 48px;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content {
  padding: 16px;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content:first-child,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content:first-child {
  padding: 0;
  padding-bottom: 8px;
  padding-left: 48px;
  padding-right: 12px;
  padding-top: 8px;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content:last-child,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content:last-child {
  padding-bottom: 0;
  padding-bottom: 16px;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content + .e-toast-actions,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content + .e-toast-actions {
  padding: 0;
  padding: 16px 0;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content + .e-toast-actions,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content + .e-toast-actions {
  padding: 16px 0;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-actions,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-actions {
  padding: 16px 0;
  text-align: right;
  border-top: 1px solid #ced4da;
  margin-left: 16px;
  margin-right: 16px;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-actions > *,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: 10px;
}
.e-bigger .e-toast-container .e-toast .e-toast-close-icon,
.e-toast-container.e-bigger .e-toast .e-toast-close-icon {
  font-size: 14px;
  height: 24px;
  width: 24px;
  margin-top: 13px;
  position: absolute;
  right: 12px;
}
.e-bigger .e-toast-container .e-toast .e-toast-icon,
.e-toast-container.e-bigger .e-toast .e-toast-icon {
  font-size: 18px;
  height: 24px;
  margin-right: 16px;
  width: 24px;
  left: 18px;
  margin-top: 13px;
  position: absolute;
}
.e-bigger .e-toast-container .e-toast .e-toast-progress,
.e-toast-container.e-bigger .e-toast .e-toast-progress {
  height: 4px;
}

.e-bigger .e-content-placeholder.e-toast.e-placeholder-toast,
.e-bigger.e-content-placeholder.e-toast.e-placeholder-toast {
  background-size: 400px 100px;
  min-height: 100px;
}

.e-message .e-msg-icon::before,
.e-message .e-msg-close-icon::before {
  font-family: "e-icons";
}
.e-message .e-msg-icon::before, .e-message.e-info .e-msg-icon::before {
  content: "\e800";
}
.e-message .e-msg-close-icon::before {
  content: "\e7e7";
}
.e-message.e-success .e-msg-icon::before {
  content: "\e72b";
}
.e-message.e-error .e-msg-icon::before {
  content: "\e878";
}
.e-message.e-warning .e-msg-icon::before {
  content: "\e88b";
}

.e-message {
  border: 1px solid;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  font-style: normal;
  padding: 11px 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.e-message.e-hidden {
  display: none;
}
.e-message .e-msg-icon,
.e-message .e-msg-close-icon {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 16px;
}
.e-message .e-msg-icon {
  font-size: 16px;
  height: 16px;
  margin: 3px 4px;
}
.e-message .e-msg-close-icon {
  border-width: 0;
  cursor: pointer;
  font-size: 16px;
  margin: 1px 2px 1px auto;
  padding: 2px;
  height: 20px;
}
.e-message .e-msg-content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  padding: 0 4px;
  vertical-align: top;
}
.e-message.e-content-right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.e-message.e-content-center .e-msg-content-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.e-message.e-content-right .e-msg-close-icon, .e-message.e-content-center .e-msg-close-icon {
  margin: 3px 4px;
}
.e-message.e-rtl .e-msg-close-icon {
  margin: 1px auto 1px 2px;
}

.e-message {
  background: #e2e3e5;
  border-color: #d3d6d8;
  color: #41464b;
}
.e-message .e-msg-icon {
  color: #212529;
}
.e-message .e-msg-close-icon {
  background: transparent;
  color: #6c757d;
}
.e-message .e-msg-close-icon:hover, .e-message .e-msg-close-icon:focus {
  background-color: rgba(108, 117, 125, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
  border-radius: 50%;
}
.e-message.e-success {
  background-color: #d1e7dd;
  border-color: #badbcc;
  color: #0f5132;
}
.e-message.e-success .e-msg-icon {
  color: #115c39;
}
.e-message.e-success .e-msg-close-icon {
  color: #6c757d;
}
.e-message.e-success .e-msg-close-icon:hover, .e-message.e-success .e-msg-close-icon:focus {
  background-color: rgba(108, 117, 125, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
}
.e-message.e-warning {
  background-color: #fff3cd;
  border-color: #ffecb5;
  color: #664d03;
}
.e-message.e-warning .e-msg-icon {
  color: #866500;
}
.e-message.e-warning .e-msg-close-icon {
  color: #6c757d;
}
.e-message.e-warning .e-msg-close-icon:hover, .e-message.e-warning .e-msg-close-icon:focus {
  background-color: rgba(108, 117, 125, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
}
.e-message.e-info {
  background-color: #cff4fc;
  border-color: #b5effb;
  color: #055160;
}
.e-message.e-info .e-msg-icon {
  color: #066477;
}
.e-message.e-info .e-msg-close-icon {
  color: #6c757d;
}
.e-message.e-info .e-msg-close-icon:hover, .e-message.e-info .e-msg-close-icon:focus {
  background-color: rgba(108, 117, 125, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
}
.e-message.e-error {
  background-color: #f8d7da;
  border-color: #f5c2c7;
  color: #842029;
}
.e-message.e-error .e-msg-icon {
  color: #842029 !important; /* stylelint-disable-line declaration-no-important */
}
.e-message.e-error .e-msg-close-icon {
  color: #6c757d;
}
.e-message.e-error .e-msg-close-icon:hover, .e-message.e-error .e-msg-close-icon:focus {
  background-color: rgba(108, 117, 125, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
}
.e-message.e-outlined {
  background: transparent;
  border-color: #9ca3af;
  color: #212529;
}
.e-message.e-outlined .e-msg-icon {
  color: #212529;
}
.e-message.e-outlined .e-msg-close-icon {
  color: #6c757d;
}
.e-message.e-outlined .e-msg-close-icon:hover, .e-message.e-outlined .e-msg-close-icon:focus {
  background-color: rgba(108, 117, 125, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
}
.e-message.e-outlined.e-success {
  border-color: #198754;
  color: #115c39;
}
.e-message.e-outlined.e-success .e-msg-icon {
  color: #115c39;
}
.e-message.e-outlined.e-success .e-msg-close-icon {
  color: #6c757d;
}
.e-message.e-outlined.e-success .e-msg-close-icon:hover, .e-message.e-outlined.e-success .e-msg-close-icon:focus {
  background-color: rgba(108, 117, 125, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
}
.e-message.e-outlined.e-warning {
  border-color: #866500;
  color: #866500;
}
.e-message.e-outlined.e-warning .e-msg-icon {
  color: #876500;
}
.e-message.e-outlined.e-warning .e-msg-close-icon {
  color: #6c757d;
}
.e-message.e-outlined.e-warning .e-msg-close-icon:hover, .e-message.e-outlined.e-warning .e-msg-close-icon:focus {
  background-color: rgba(108, 117, 125, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
}
.e-message.e-outlined.e-info {
  border-color: #066477;
  color: #066477;
}
.e-message.e-outlined.e-info .e-msg-icon {
  color: #066477;
}
.e-message.e-outlined.e-info .e-msg-close-icon {
  color: #6c757d;
}
.e-message.e-outlined.e-info .e-msg-close-icon:hover, .e-message.e-outlined.e-info .e-msg-close-icon:focus {
  background-color: rgba(108, 117, 125, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
}
.e-message.e-outlined.e-error {
  border-color: #dc3545;
  color: #dc3545;
}
.e-message.e-outlined.e-error .e-msg-icon {
  color: #dc3545;
}
.e-message.e-outlined.e-error .e-msg-close-icon {
  color: #6c757d;
}
.e-message.e-outlined.e-error .e-msg-close-icon:hover, .e-message.e-outlined.e-error .e-msg-close-icon:focus {
  background-color: rgba(108, 117, 125, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.12);
}
.e-message.e-filled {
  background: #6c757d;
  border-color: #6c757d;
  color: #fff;
}
.e-message.e-filled .e-msg-icon {
  color: #fff;
}
.e-message.e-filled .e-msg-close-icon {
  color: #fff;
}
.e-message.e-filled .e-msg-close-icon:hover, .e-message.e-filled .e-msg-close-icon:focus {
  background-color: rgba(255, 255, 255, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
          box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
}
.e-message.e-filled.e-success {
  background-color: #198754;
  border-color: #198754;
  color: #fff;
}
.e-message.e-filled.e-success .e-msg-icon {
  color: #fff;
}
.e-message.e-filled.e-success .e-msg-close-icon {
  color: #fff;
}
.e-message.e-filled.e-success .e-msg-close-icon:hover, .e-message.e-filled.e-success .e-msg-close-icon:focus {
  background-color: rgba(255, 255, 255, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
          box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
}
.e-message.e-filled.e-warning {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #212529;
}
.e-message.e-filled.e-warning .e-msg-icon {
  color: #212529;
}
.e-message.e-filled.e-warning .e-msg-close-icon {
  color: #212529;
}
.e-message.e-filled.e-warning .e-msg-close-icon:hover, .e-message.e-filled.e-warning .e-msg-close-icon:focus {
  background-color: rgba(33, 37, 41, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(33, 37, 41, 0.12);
          box-shadow: 0 0 0 2px rgba(33, 37, 41, 0.12);
}
.e-message.e-filled.e-info {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  color: #212529;
}
.e-message.e-filled.e-info .e-msg-icon {
  color: #212529;
}
.e-message.e-filled.e-info .e-msg-close-icon {
  color: #212529;
}
.e-message.e-filled.e-info .e-msg-close-icon:hover, .e-message.e-filled.e-info .e-msg-close-icon:focus {
  background-color: rgba(33, 37, 41, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(33, 37, 41, 0.12);
          box-shadow: 0 0 0 2px rgba(33, 37, 41, 0.12);
}
.e-message.e-filled.e-error {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.e-message.e-filled.e-error .e-msg-icon {
  color: #fff !important; /* stylelint-disable-line declaration-no-important */
}
.e-message.e-filled.e-error .e-msg-close-icon {
  color: #fff;
}
.e-message.e-filled.e-error .e-msg-close-icon:hover, .e-message.e-filled.e-error .e-msg-close-icon:focus {
  background-color: rgba(255, 255, 255, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
          box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.12);
}

.e-bigger .e-message,
.e-message.e-bigger {
  padding: 16px 9px;
}
.e-bigger .e-message .e-msg-icon,
.e-bigger .e-message .e-msg-close-icon,
.e-message.e-bigger .e-msg-icon,
.e-message.e-bigger .e-msg-close-icon {
  line-height: 18px;
}
.e-bigger .e-message .e-msg-icon,
.e-message.e-bigger .e-msg-icon {
  font-size: 18px;
  height: 18px;
  margin: 3px 6px;
}
.e-bigger .e-message .e-msg-close-icon,
.e-message.e-bigger .e-msg-close-icon {
  font-size: 18px;
  margin: 0 3px 0 auto;
  padding: 3px;
  height: 24px;
}
.e-bigger .e-message .e-msg-content,
.e-message.e-bigger .e-msg-content {
  line-height: 24px;
  font-size: 16px;
  padding: 0 6px;
}
.e-bigger .e-message.e-content-right .e-msg-close-icon, .e-bigger .e-message.e-content-center .e-msg-close-icon,
.e-message.e-bigger.e-content-right .e-msg-close-icon,
.e-message.e-bigger.e-content-center .e-msg-close-icon {
  margin: 3px 6px;
}
.e-bigger .e-message.e-rtl .e-msg-close-icon,
.e-message.e-bigger.e-rtl .e-msg-close-icon {
  margin: 0 auto 0 3px;
}

.e-bigger .e-message .e-msg-close-icon:hover, .e-bigger .e-message .e-msg-close-icon:focus,
.e-message.e-bigger .e-msg-close-icon:hover,
.e-message.e-bigger .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
}
.e-bigger .e-message.e-success .e-msg-close-icon:hover, .e-bigger .e-message.e-success .e-msg-close-icon:focus,
.e-message.e-bigger.e-success .e-msg-close-icon:hover,
.e-message.e-bigger.e-success .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
}
.e-bigger .e-message.e-warning .e-msg-close-icon:hover, .e-bigger .e-message.e-warning .e-msg-close-icon:focus,
.e-message.e-bigger.e-warning .e-msg-close-icon:hover,
.e-message.e-bigger.e-warning .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
}
.e-bigger .e-message.e-info .e-msg-close-icon:hover, .e-bigger .e-message.e-info .e-msg-close-icon:focus,
.e-message.e-bigger.e-info .e-msg-close-icon:hover,
.e-message.e-bigger.e-info .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
}
.e-bigger .e-message.e-error .e-msg-close-icon:hover, .e-bigger .e-message.e-error .e-msg-close-icon:focus,
.e-message.e-bigger.e-error .e-msg-close-icon:hover,
.e-message.e-bigger.e-error .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
}
.e-bigger .e-message.e-outlined .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
}
.e-bigger .e-message.e-outlined.e-success .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-success .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-success .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-success .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
}
.e-bigger .e-message.e-outlined.e-warning .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-warning .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-warning .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-warning .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
}
.e-bigger .e-message.e-outlined.e-info .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-info .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-info .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-info .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
}
.e-bigger .e-message.e-outlined.e-error .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-error .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-error .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-error .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
}
.e-bigger .e-message.e-filled .e-msg-close-icon:hover, .e-bigger .e-message.e-filled .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
          box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
}
.e-bigger .e-message.e-filled.e-success .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-success .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-success .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-success .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
          box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
}
.e-bigger .e-message.e-filled.e-warning .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-warning .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-warning .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-warning .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(33, 37, 41, 0.12);
          box-shadow: 0 0 0 4px rgba(33, 37, 41, 0.12);
}
.e-bigger .e-message.e-filled.e-info .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-info .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-info .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-info .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(33, 37, 41, 0.12);
          box-shadow: 0 0 0 4px rgba(33, 37, 41, 0.12);
}
.e-bigger .e-message.e-filled.e-error .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-error .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-error .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-error .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
          box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.12);
}

.e-skeleton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border: none;
}
.e-skeleton.e-skeleton-text, .e-skeleton.e-skeleton-square, .e-skeleton.e-skeleton-rectangle {
  border-radius: 4px;
}

.e-skeleton.e-skeleton-circle {
  border-radius: 50%;
}

.e-skeleton.e-visible-none {
  display: none;
}

.e-skeleton.e-shimmer-wave::after {
  -webkit-animation: e-shimmer-wave 2s infinite;
          animation: e-shimmer-wave 2s infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: calc(200% + 200px);
}

.e-skeleton.e-shimmer-fade {
  -webkit-animation: e-shimmer-fade 2s infinite;
          animation: e-shimmer-fade 2s infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

.e-skeleton.e-shimmer-pulse {
  -webkit-animation: e-shimmer-pulse 2s infinite;
          animation: e-shimmer-pulse 2s infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

.e-rtl .e-shimmer-wave::after,
.e-rtl.e-shimmer-wave::after {
  -webkit-animation: e-shimmer-wave-rtl 2s infinite;
          animation: e-shimmer-wave-rtl 2s infinite;
}

@-webkit-keyframes e-shimmer-wave {
  to {
    -webkit-transform: translateX(calc(50% + 100px));
            transform: translateX(calc(50% + 100px));
  }
}

@keyframes e-shimmer-wave {
  to {
    -webkit-transform: translateX(calc(50% + 100px));
            transform: translateX(calc(50% + 100px));
  }
}
@-webkit-keyframes e-shimmer-wave-rtl {
  from {
    -webkit-transform: translateX(calc(50% + 100px));
            transform: translateX(calc(50% + 100px));
  }
}
@keyframes e-shimmer-wave-rtl {
  from {
    -webkit-transform: translateX(calc(50% + 100px));
            transform: translateX(calc(50% + 100px));
  }
}
@-webkit-keyframes e-shimmer-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes e-shimmer-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes e-shimmer-pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.975);
            transform: scale(0.975);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes e-shimmer-pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.975);
            transform: scale(0.975);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.e-skeleton.e-shimmer-wave::after {
  background-image: linear-gradient(90deg, transparent calc(50% - 100px), #e6e1e5 50%, transparent calc(50% + 100px));
}

.e-skeleton.e-skeleton-text, .e-skeleton.e-skeleton-square, .e-skeleton.e-skeleton-rectangle, .e-skeleton.e-skeleton-circle {
  background-color: #e9ecef;
}